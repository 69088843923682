import { Autocomplete, Card, Stack, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';

import { FilterParams } from '../filter-params.interface';

export type LocationTriple = Pick<FilterParams, 'country' | 'state' | 'city'>;

interface LocationFilterProps {
  value: LocationTriple;
  onChange: (newValue: LocationTriple) => void;
  onDiscard: () => void;
}

export const LocationFilter = ({ value, onChange, onDiscard }: LocationFilterProps) => {
  const { country, state, city } = value;
  const emptyLabel = '-- Any --';

  const { updateGeoBounds } = useFilters();

  // Params for fetching state options
  const fetchStateOptionsParams = new URLSearchParams();
  if (country) fetchStateOptionsParams.set('country', country);
  const fetchStateOptionsUrl = `/profiles/filter-options/states?${fetchStateOptionsParams}`;

  // Params for fetching city options
  const fetchCityOptionsParams = new URLSearchParams();
  if (country) fetchCityOptionsParams.set('country', country);
  if (state) fetchCityOptionsParams.set('state', state);
  const fetchCityOptionsUrl = `/profiles/filter-options/cities?${fetchCityOptionsParams}`;

  // Setup country options
  const [countryNames, countryNamesStatus] = useAsync<string[]>('/profiles/filter-options/countries');
  const countryAutocompleteOptions = useMemo(
    () => [{ value: '', label: emptyLabel }].concat(countryNames?.map((value) => ({ value, label: value })) || []),
    [countryNames],
  );

  // Setup state options
  const [stateNames, stateNamesStatus] = useAsync<string[]>(fetchStateOptionsUrl);
  const stateAutocompleteOptions = useMemo(
    () => [{ value: '', label: emptyLabel }].concat(stateNames?.map((value) => ({ value, label: value })) || []),
    [stateNames],
  );

  // Setup city options
  const [cityNames, cityNamesStatus] = useAsync<string[]>(fetchCityOptionsUrl);
  const cityAutocompleteOptions = useMemo(
    () => [{ value: '', label: emptyLabel }].concat(cityNames?.map((value) => ({ value, label: value })) || []),
    [cityNames],
  );

  type AutocompleteValue = { value: string; label: string } | null;

  // Invalidate state and city selection when country changes
  const onInternalCountryChange = (event: unknown, value: AutocompleteValue) => {
    const newCountry = value?.value || '';
    if (newCountry === country) return;
    updateGeoBounds('');
    onChange({ country: newCountry, state: '', city: '' });
  };
  // Invalidate city selection when the state changes
  const onInternalStateChange = (event: unknown, value: AutocompleteValue) => {
    const newState = value?.value || '';
    if (newState === state) return;
    updateGeoBounds('');
    onChange({ country, state: newState, city: '' });
  };
  const onInternalCityChange = (event: unknown, value: AutocompleteValue) => {
    const newCity = value?.value || '';
    if (newCity === city) return;
    updateGeoBounds('');
    onChange({ country, state, city: newCity });
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Location" onDiscard={onDiscard} />
      <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
        <Autocomplete
          size="small"
          id="location-filter-country-autocomplete"
          options={countryAutocompleteOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          value={country ? { value: country, label: country } : { value: '', label: emptyLabel }}
          onChange={onInternalCountryChange}
          renderInput={(params) => <TextField {...params} label="Country" />}
          disabled={countryNamesStatus !== 'success'}
          disableClearable={country === ''}
          sx={{ flex: 1 }}
        />
        <Autocomplete
          size="small"
          id="location-filter-state-autocomplete"
          options={stateAutocompleteOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          value={state ? { value: state, label: state } : { value: '', label: emptyLabel }}
          onChange={onInternalStateChange}
          renderInput={(params) => <TextField {...params} label="State" />}
          disabled={!country || stateNamesStatus !== 'success'}
          disableClearable={state === ''}
          sx={{ flex: 1 }}
        />
        <Autocomplete
          size="small"
          id="location-filter-city-autocomplete"
          options={cityAutocompleteOptions}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          value={city ? { value: city, label: city } : { value: '', label: emptyLabel }}
          onChange={onInternalCityChange}
          renderInput={(params) => <TextField {...params} label="City" />}
          disabled={!country || !state || cityNamesStatus !== 'success'}
          disableClearable={city === ''}
          sx={{ flex: 1 }}
        />
      </Stack>
    </Card>
  );
};
