import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, BoxProps, Checkbox, Chip, Divider, FormControl, FormControlLabel, Stack, Tab, tabClasses, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAsync } from 'hooks/use-async';
import { CSSProperties, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { FeatureCategories, FeatureSubjectSubcategories } from 'types/features.enum';
import { Feature, ProfileData, SubjectFeature } from 'types/profile.interface';

import { FeatureSearch } from './FeatureSearch';

interface FeaturesEditProps {
  value: ProfileData['profileFeatures'];
  onChange: (value: ProfileData['profileFeatures']) => void;
}

export const FeaturesEdit = ({ value, onChange }: FeaturesEditProps) => {
  const theme = useTheme();
  const [activeCategory, setActiveCategory] = useState(FeatureCategories.Subjects);
  // Subcategories currently only apply to subjects
  const [activeSubcategory, setActiveSubcategory] = useState(FeatureSubjectSubcategories.AgricultureHorticulture);
  const [featureHighlight, setFeatureHighlight] = useState<Feature | SubjectFeature | null>(null);
  const [features] = useAsync<(Feature | SubjectFeature)[]>('/features');

  // Store references to procedurally generated elements so that scrollTo can be called on them during search.
  // We'll populate these later during initial render using callback refs
  const [subjectsListEls] = useState<Map<FeatureSubjectSubcategories, FixedSizeList>>(new Map());

  const artsListRef = useRef<FixedSizeList | null>(null);
  const musicListRef = useRef<FixedSizeList | null>(null);
  const sportsListRef = useRef<FixedSizeList | null>(null);
  const extracurricularListRef = useRef<FixedSizeList | null>(null);

  const [subjectsData, artsData, musicData, sportsData, extracurricularData] = useMemo(
    () =>
      features
        ? [
            features.filter((feature) => feature.category === FeatureCategories.Subjects) as SubjectFeature[],
            features.filter((feature) => feature.category === FeatureCategories.Arts) as Feature[],
            features.filter((feature) => feature.category === FeatureCategories.Music) as Feature[],
            features.filter((feature) => feature.category === FeatureCategories.Sports) as Feature[],
            features.filter((feature) => feature.category === FeatureCategories.Extracurricular) as Feature[],
          ]
        : [[], [], [], [], []],
    [features],
  );

  const subjectsMap: Map<SubjectFeature['subcategory'], SubjectFeature[]> = useMemo(() => {
    const subCategoriesMap = new Map();
    Object.values(FeatureSubjectSubcategories).forEach((subCategory) => subCategoriesMap.set(subCategory, []));

    return subjectsData.reduce((map, subject) => {
      map.get(subject.subcategory)!.push(subject);

      return map;
    }, subCategoriesMap);
  }, [subjectsData]);

  const [viewAddedFeatures, setViewAddedFeatures] = useState<boolean>(false);

  // TODO: Refactor to memoized map instead of useEffect-managed ref
  const valueMap = useRef<Map<ProfileData['profileFeatures'][0]['featureId'], ProfileData['profileFeatures'][0]>>(new Map());

  useEffect(() => {
    valueMap.current.clear();

    value.forEach((feature) => {
      valueMap.current.set(feature.featureId, feature);
    });
  }, [value]);

  useEffect(() => {
    if (!featureHighlight) return;

    let featureIndex;

    switch (featureHighlight.category) {
      case FeatureCategories.Subjects:
        featureIndex = subjectsMap.get(featureHighlight.subcategory)!.findIndex((f) => f.id === featureHighlight.id);
        if (featureIndex !== -1) subjectsListEls.get(featureHighlight.subcategory)?.scrollToItem(featureIndex, 'center');
        break;
      case FeatureCategories.Arts:
        featureIndex = artsData.findIndex((f) => f.id === featureHighlight.id);
        if (featureIndex !== -1) artsListRef.current!.scrollToItem(Math.round(featureIndex / 3), 'center');
        break;
      case FeatureCategories.Music:
        featureIndex = musicData.findIndex((f) => f.id === featureHighlight.id);
        if (featureIndex !== -1) musicListRef.current!.scrollToItem(Math.round(featureIndex / 3), 'center');
        break;
      case FeatureCategories.Sports:
        featureIndex = sportsData.findIndex((f) => f.id === featureHighlight.id);
        if (featureIndex !== -1) sportsListRef.current!.scrollToItem(Math.round(featureIndex / 3), 'center');
        break;
      case FeatureCategories.Extracurricular:
        featureIndex = extracurricularData.findIndex((f) => f.id === featureHighlight.id);
        if (featureIndex !== -1) extracurricularListRef.current!.scrollToItem(Math.round(featureIndex / 3), 'center');
        break;
    }
  }, [subjectsMap, subjectsListEls, artsData, extracurricularData, featureHighlight, musicData, sportsData, subjectsData]);

  const onValueChange = (event: SyntheticEvent, checked: boolean) => {
    // The MUI typing does not accept the generic SyntheticEvent<HTMLInputElement>
    const feature: Feature | SubjectFeature = JSON.parse((event.target as HTMLInputElement).value);

    if (checked) {
      valueMap.current.set(feature.id, { feature, featureId: feature.id, isAdvanced: false });
    } else {
      valueMap.current.delete(feature.id);
    }

    onChange(Array.from(valueMap.current.values()));
  };

  const onFeatureHighlight = (feature: Feature | SubjectFeature) => {
    setActiveCategory(feature.category);
    if (feature.subcategory) setActiveSubcategory(feature.subcategory);
    setFeatureHighlight(feature);
  };

  const onIsAdvancedChange = (event: SyntheticEvent, checked: boolean) => {
    // The MUI typing does not accept the generic SyntheticEvent<HTMLInputElement>
    const feature: Feature | SubjectFeature = JSON.parse((event.target as HTMLInputElement).value);

    valueMap.current.get(feature.id)!.isAdvanced = checked;

    onChange(Array.from(valueMap.current.values()));
  };

  const subjectsListRow =
    (items: SubjectFeature[]) =>
    ({ index, style }: { index: number; style: CSSProperties }) => {
      return (
        <div key={index} style={{ ...style, paddingLeft: '16px', paddingRight: '16px' }}>
          <Stack direction="row" alignItems="center" sx={{ px: 1, bgcolor: featureHighlight?.id === items[index].id ? 'success.light' : 'background.paper' }}>
            <FormControl size="small" sx={{ py: 0.5, flex: 1 }}>
              <FormControlLabel
                value={JSON.stringify(items[index])}
                control={<Checkbox checked={valueMap.current.has(items[index].id)} />}
                label={items[index].name}
                onChange={onValueChange}
              />
            </FormControl>
            <FormControl size="small" sx={{ flex: 1 }}>
              <FormControlLabel
                value={JSON.stringify(items[index])}
                control={<Checkbox disabled={!valueMap.current.has(items[index].id)} checked={valueMap.current.get(items[index].id)?.isAdvanced} />}
                label="Advanced course available"
                onChange={onIsAdvancedChange}
              />
            </FormControl>
          </Stack>
          {index + 1 < items.length && <Divider />}
        </div>
      );
    };

  const featuresListRow =
    (items: Feature[]) =>
    ({ index, style }: { index: number; style: CSSProperties }) => {
      const itemIndex = index * 3;

      return (
        <div key={index} style={{ ...style, paddingLeft: '24px', paddingRight: '24px' }}>
          <FormControl size="small" sx={{ py: 0.5, width: '33%' }}>
            <FormControlLabel
              value={JSON.stringify(items[itemIndex])}
              control={<Checkbox checked={valueMap.current.has(items[itemIndex].id)} />}
              label={items[itemIndex].name}
              onChange={onValueChange}
              sx={{ bgcolor: featureHighlight?.id === items[itemIndex].id ? 'success.light' : 'background.paper' }}
            />
          </FormControl>
          {itemIndex + 1 < items.length && (
            <FormControl size="small" sx={{ py: 0.5, width: '33%' }}>
              <FormControlLabel
                value={JSON.stringify(items[itemIndex + 1])}
                control={<Checkbox checked={valueMap.current.has(items[itemIndex + 1].id)} />}
                label={items[itemIndex + 1].name}
                onChange={onValueChange}
                sx={{ bgcolor: featureHighlight?.id === items[itemIndex + 1].id ? 'success.light' : 'background.paper' }}
              />
            </FormControl>
          )}
          {itemIndex + 2 < items.length && (
            <FormControl size="small" sx={{ py: 0.5, width: '33%' }}>
              <FormControlLabel
                value={JSON.stringify(items[itemIndex + 2])}
                control={<Checkbox checked={valueMap.current.has(items[itemIndex + 2].id)} />}
                label={items[itemIndex + 2].name}
                onChange={onValueChange}
                sx={{ bgcolor: featureHighlight?.id === items[itemIndex + 2].id ? 'success.light' : 'background.paper' }}
              />
            </FormControl>
          )}
          {itemIndex + 3 < items.length && <Divider sx={{ width: '100%' }} />}
        </div>
      );
    };

  // TODO: Move to subcomponent if performance degrades
  const addedFeatureRows = (features: (Feature | SubjectFeature)[]) =>
    features
      .filter((feature) => valueMap.current.has(feature.id))
      .map((feature) => (
        <Stack key={feature.id} direction="row">
          <Typography noWrap p={2} py={0.5} bgcolor={theme.palette.background.paper} width="100%">
            {feature.name}
          </Typography>
          {valueMap.current.get(feature.id)?.isAdvanced && <Chip label="Adv" />}
        </Stack>
      ));

  const featuresTabSx = {
    mx: 0.5,
    my: 1,
    px: 2,
    py: 1,
    color: grey[500],
    background: 'transparent',
    fontWeight: 500,
    borderRadius: '6px',
    minWidth: 0,
    [`&.${tabClasses.selected}`]: {
      color: grey[700],
      background: theme.palette.background.paper,
      boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    },
  };

  const tabPanelHeight = 544;
  const addedFeaturesWidth = viewAddedFeatures ? 246 : 0;
  const featuresPanelWidth = 1070 - addedFeaturesWidth;
  const subjectsPanelWidth = 883 - addedFeaturesWidth;

  const featuresPanelProps: BoxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignContent: 'start',
    flexWrap: 'wrap',
    height: '100%',
    maxHeight: `${tabPanelHeight}px`,
    width: '100%',
    py: 0,
    sx: { overflowY: 'auto', background: theme.palette.background.paper },
  };

  const subcategoryTabSx = {
    m: 0,
    px: 2,
    py: 1,
    textAlign: 'left',
    alignItems: 'self-start',
    width: '100%',
    color: grey[500],
    background: 'transparent',
    fontWeight: 500,
    borderRadius: '6px',
    [`&.${tabClasses.selected}`]: {
      color: theme.palette.primary.dark,
      background: theme.palette.primary.light,
    },
  };

  return (
    <TabContext value={activeCategory}>
      <Box display="flex" bgcolor={theme.palette.background.default} sx={{ borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
        <TabList TabIndicatorProps={{ sx: { display: 'none' } }} onChange={(_, value) => setActiveCategory(value)} sx={{ flex: 1, ml: 1 }}>
          <Tab label={FeatureCategories.Subjects} value={FeatureCategories.Subjects} sx={featuresTabSx} />
          <Tab label={FeatureCategories.Arts} value={FeatureCategories.Arts} sx={featuresTabSx} />
          <Tab label={FeatureCategories.Music} value={FeatureCategories.Music} sx={featuresTabSx} />
          <Tab label={FeatureCategories.Sports} value={FeatureCategories.Sports} sx={featuresTabSx} />
          <Tab label={FeatureCategories.Extracurricular} value={FeatureCategories.Extracurricular} sx={featuresTabSx} />
        </TabList>
        <Stack direction="row" alignItems="center" mr={1}>
          <FormControl size="small">
            <FormControlLabel
              control={<Checkbox value={viewAddedFeatures} onChange={(event, checked) => setViewAddedFeatures(checked)} />}
              label="View added features"
            />
          </FormControl>
          <FeatureSearch features={features} valueMap={valueMap.current} onFeatureHighlight={onFeatureHighlight} />
        </Stack>
      </Box>
      <Stack
        direction="row"
        bgcolor={theme.palette.background.default}
        height="570px"
        maxWidth="100%"
        sx={{ borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}
      >
        <TabPanel sx={{ p: 0, display: 'flex', flex: activeCategory === FeatureCategories.Subjects ? 1 : 0 }} value={FeatureCategories.Subjects}>
          <TabContext value={activeSubcategory}>
            <TabList
              TabIndicatorProps={{ sx: { display: 'none' } }}
              variant="scrollable"
              orientation="vertical"
              onChange={(_, value) => setActiveSubcategory(value)}
              sx={{ mx: 1 }}
            >
              {Object.values(FeatureSubjectSubcategories).map((subcategory) => (
                <Tab label={subcategory} value={subcategory} key={subcategory} sx={subcategoryTabSx} />
              ))}
            </TabList>

            {Object.values(FeatureSubjectSubcategories).map((subcategory) => (
              <TabPanel value={subcategory} key={subcategory} sx={{ p: 0, mr: viewAddedFeatures ? 1 : 2, width: '100%' }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  width="100%"
                  sx={{ overflowY: 'auto', maxHeight: `${tabPanelHeight}px`, background: theme.palette.background.paper }}
                >
                  <FixedSizeList
                    ref={(el) => el && subjectsListEls.set(subcategory, el)}
                    height={tabPanelHeight}
                    itemCount={subjectsMap.get(subcategory)!.length}
                    itemSize={51}
                    width={subjectsPanelWidth}
                  >
                    {subjectsListRow(subjectsMap.get(subcategory)!)}
                  </FixedSizeList>
                </Box>
              </TabPanel>
            ))}
          </TabContext>
        </TabPanel>
        <TabPanel sx={{ p: 2, pt: 0, pr: viewAddedFeatures ? 1 : 2 }} value={FeatureCategories.Arts}>
          <Box {...featuresPanelProps}>
            <FixedSizeList ref={artsListRef} height={tabPanelHeight} itemCount={Math.ceil(artsData.length / 3)} itemSize={50} width={featuresPanelWidth}>
              {featuresListRow(artsData)}
            </FixedSizeList>
          </Box>
        </TabPanel>
        <TabPanel sx={{ p: 2, pt: 0, pr: viewAddedFeatures ? 1 : 2 }} value={FeatureCategories.Music}>
          <Box {...featuresPanelProps}>
            <FixedSizeList ref={musicListRef} height={tabPanelHeight} itemCount={Math.ceil(musicData.length / 3)} itemSize={50} width={featuresPanelWidth}>
              {featuresListRow(musicData)}
            </FixedSizeList>
          </Box>
        </TabPanel>
        <TabPanel sx={{ p: 2, pt: 0, pr: viewAddedFeatures ? 1 : 2 }} value={FeatureCategories.Sports}>
          <Box {...featuresPanelProps}>
            <FixedSizeList ref={sportsListRef} height={tabPanelHeight} itemCount={Math.ceil(sportsData.length / 3)} itemSize={50} width={featuresPanelWidth}>
              {featuresListRow(sportsData)}
            </FixedSizeList>
          </Box>
        </TabPanel>
        <TabPanel sx={{ p: 2, pt: 0, pr: viewAddedFeatures ? 1 : 2 }} value={FeatureCategories.Extracurricular}>
          <Box {...featuresPanelProps}>
            <FixedSizeList
              ref={extracurricularListRef}
              height={tabPanelHeight}
              itemCount={Math.ceil(extracurricularData.length / 3)}
              itemSize={50}
              width={featuresPanelWidth}
            >
              {featuresListRow(extracurricularData)}
            </FixedSizeList>
          </Box>
        </TabPanel>
        {viewAddedFeatures && (
          <Box width={addedFeaturesWidth} mr={1}>
            <Typography textAlign="center" fontWeight="500" bgcolor={grey[300]} py={1} sx={{ borderTopLeftRadius: '6px', borderTopRightRadius: '6px' }}>
              Added features
            </Typography>
            <Stack height="504px" sx={{ overflowY: 'scroll' }}>
              {subjectsData.length > 0 && (
                <Typography fontWeight="600" p={2} pb={0.5} bgcolor={theme.palette.background.paper}>
                  Subjects
                </Typography>
              )}
              {addedFeatureRows(subjectsData)}
              {artsData.length > 0 && (
                <Typography fontWeight="600" p={2} pb={0.5} bgcolor={theme.palette.background.paper}>
                  Arts
                </Typography>
              )}
              {addedFeatureRows(artsData)}
              {musicData.length > 0 && (
                <Typography fontWeight="600" p={2} pb={0.5} bgcolor={theme.palette.background.paper}>
                  Music
                </Typography>
              )}
              {addedFeatureRows(musicData)}
              {sportsData.length > 0 && (
                <Typography fontWeight="600" p={2} pb={0.5} bgcolor={theme.palette.background.paper}>
                  Sports
                </Typography>
              )}
              {addedFeatureRows(sportsData)}
              {extracurricularData.length > 0 && (
                <Typography fontWeight="600" p={2} pb={0.5} bgcolor={theme.palette.background.paper}>
                  Extracurricular
                </Typography>
              )}
              {addedFeatureRows(extracurricularData)}
            </Stack>
          </Box>
        )}
      </Stack>
    </TabContext>
  );
};
