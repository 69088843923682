import { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, Button, FormControl, Modal, Typography, useTheme } from '@mui/material';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import { useGet } from 'hooks/use-get';
import { getConfig } from 'utils/config';

export const VersionCheckerModal = () => {
  const [versionCheckInterval, setVersionCheckInterval] = useState<number | null>(null);
  const [isUpToDate, setIsUpToDate] = useState(true);
  const { logout } = useAuth0();
  const { marketplaceUrl, version: currentVersion } = getConfig();
  const getVersion = useGet<string>(`${marketplaceUrl}/version`, { useApiUrl: false, skipAuth: true, headers: { Accept: 'text/plain' } });
  const theme = useTheme();
  const checkForUpdate = useCallback(async () => {
    try {
      const latestVersion = await getVersion();
      console.info(`Checking current version (${currentVersion}) against latest version (${latestVersion})`);
      if (latestVersion.trim() !== currentVersion.trim()) {
        setIsUpToDate(false);
      }
    } catch {
      console.error('Unable to check for application updates');
    }
  }, [getVersion, currentVersion]);

  useEffect(() => {
    // start the version check interval if it's not running and we aren't showing the logout modal
    if (versionCheckInterval === null && isUpToDate) {
      setVersionCheckInterval(setInterval(checkForUpdate, 60000) as any as number);
    }

    // if current version is out of date, clean up the interval to stop checking
    if (!isUpToDate && versionCheckInterval !== null) {
      clearInterval(versionCheckInterval);
      setVersionCheckInterval(null);
    }
  }, [checkForUpdate, isUpToDate, versionCheckInterval]);

  if (isUpToDate) {
    return <></>;
  }

  return (
    <>
      <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />
      <Modal open={true} aria-labelledby="version-check-modal-title" aria-describedby="version-check-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Box>
            <Avatar sx={{ mb: 1, bgcolor: 'primary.light' }}>
              <CampaignOutlinedIcon sx={{ color: 'primary.dark' }} />
            </Avatar>
            <Typography id="version-check-modal-title" variant="h6" component="h2">
              System update
            </Typography>
            <Typography id="version-check-modal-description" sx={{ mb: 3, mt: 2 }}>
              We've made a few changes to our system and need you to refresh and login again
            </Typography>
            <FormControl fullWidth>
              <Button variant="contained" onClick={() => logout({ logoutParams: { returnTo: marketplaceUrl } })} startIcon={<LogoutIcon />}>
                Logout
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
