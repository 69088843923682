import { env, EnvType } from 'utils/env';

// eslint-disable-next-line
import packageJson from '../../package.json';
import { BrandingOrganisation } from '../app/branding/branding.interface';

export interface AppConfig {
  environment: 'local' | 'development' | 'production';
  version: string;
  pageTitle: string;
  apiUrl: string;
  appBrandTheme: BrandingOrganisation;
  enrolmentsUrl: string;
  marketplaceUrl: string;
  cloudfrontUrl: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0CallbackUrl: string;
  auth0Audience: string;
  auth0Organisation?: string;
  s3Endpoint: string;
  s3Bucket: string;
  sentryDsn: string;
  mapboxToken: string;
  faviconUrl: string;
  googleAnalyticsMeasurementId: string;
  googleMapsApiKey: string;
  educatiusResourcesBasePath?: string;
  agencyManagementUrl: string;
}

const throwIfUndefined = (envVar: keyof EnvType): string => {
  if (env[envVar] === undefined) {
    throw new Error(`${String(envVar)} is not defined, cannot load application config`);
  }

  return env[envVar]!;
};

export const getConfig = (): AppConfig => {
  let deploymentTarget = throwIfUndefined('REACT_APP_ENV');
  if (deploymentTarget !== 'local' && deploymentTarget !== 'development' && deploymentTarget !== 'production') {
    deploymentTarget = 'production';
  }

  const auth0Organisation = throwIfUndefined('REACT_APP_AUTH0_ORGANISATION');

  let educatiusResourcesBasePath = undefined;
  const appBrandTheme = throwIfUndefined('REACT_APP_BRAND_THEMING') as BrandingOrganisation;
  if (appBrandTheme === 'educatius') {
    educatiusResourcesBasePath = throwIfUndefined('REACT_APP_EDUCATIUS_RESOURCES_BASEPATH');
  }

  return {
    environment: deploymentTarget as 'local' | 'development' | 'production',
    version: packageJson.version,
    pageTitle: throwIfUndefined('REACT_APP_PAGE_TITLE'),
    apiUrl: throwIfUndefined('REACT_APP_BACKEND_HOST'),
    appBrandTheme,
    enrolmentsUrl: throwIfUndefined('REACT_APP_ENROLLER'),
    marketplaceUrl: throwIfUndefined('REACT_APP_MARKETPLACE'),
    cloudfrontUrl: 'https://marketplace-images.enroller.app',
    auth0Domain: throwIfUndefined('REACT_APP_AUTH0_DOMAIN'),
    auth0ClientId: throwIfUndefined('REACT_APP_AUTH0_CLIENT_ID'),
    auth0CallbackUrl: throwIfUndefined('REACT_APP_AUTH0_CALLBACK_URL'),
    auth0Audience: throwIfUndefined('REACT_APP_AUTH0_AUDIENCE'),
    auth0Organisation: auth0Organisation === 'default' ? undefined : auth0Organisation,
    s3Endpoint: throwIfUndefined('REACT_APP_S3_ENDPOINT'),
    s3Bucket: throwIfUndefined('REACT_APP_S3_BUCKET'),
    sentryDsn: throwIfUndefined('REACT_APP_SENTRY_DSN'),
    mapboxToken: throwIfUndefined('REACT_APP_MAPBOX_TOKEN'),
    googleAnalyticsMeasurementId: 'G-QXSTNNJQ6J',
    googleMapsApiKey: throwIfUndefined('REACT_APP_GOOGLE_MAPS_API_KEY'),
    faviconUrl: throwIfUndefined('REACT_APP_FAVICON'),
    educatiusResourcesBasePath,
    agencyManagementUrl: throwIfUndefined('REACT_APP_AGENCY_MANAGEMENT_URL'),
  };
};
