import { useGet } from 'hooks/use-get';
import { usePromiseState, UsePromiseState } from 'hooks/use-promise-state';
import { useCallback, useMemo } from 'react';
import { PaginatedResponseEnvelope } from 'types/paginated-response-envelope.interface';
import { ProfileSummary } from 'types/profile.interface';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { useDebounce } from 'use-debounce';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'types/user.interface';

export const useFilteredProfilesPage = (): UsePromiseState<PaginatedResponseEnvelope<ProfileSummary>> => {
  const { user } = useAuth0<AuthUser>();

  const getOptions = useMemo(() => ({ skipAuth: !Boolean(user), useApiUrl: true }), [user]);

  const fetchFilteredProfiles = useGet<PaginatedResponseEnvelope<ProfileSummary>>(`/api/profiles/summaries`, getOptions);

  const { getSearchParamsFromFilters } = useFilters();

  const params = useMemo(() => getSearchParamsFromFilters({ paginate: true, sort: true, filterGeometry: true }), [getSearchParamsFromFilters]);

  const [debouncedParams] = useDebounce<URLSearchParams>(params, 300);

  const memoisedFetch = useCallback(() => fetchFilteredProfiles(debouncedParams), [fetchFilteredProfiles, debouncedParams]);

  return usePromiseState<PaginatedResponseEnvelope<ProfileSummary> | null>(memoisedFetch, null);
};
