import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Pill } from 'components/Pill';

export const HiddenSectionHelpPill = () => (
  <Pill
    sx={{ textTransform: 'inherit', letterSpacing: 'inherit', height: '24px', fontSize: 12 }}
    label="This section won&rsquo;t be visible on your school&rsquo;s profile"
    color="info"
    icon={<InfoOutlinedIcon color="success" fontSize="small" />}
  />
);
