import { useAuth0 } from '@auth0/auth0-react';
import { Paper, Typography, useTheme } from '@mui/material';
import { StudyProgram } from 'types/study-program.interface';
import { StudyProgramPeriodTypeNames } from 'types/study-program-period-type.enum';
import { canUser } from 'utils/userRoles';
import { useMatch, useParams } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { Permissions } from 'types/permissions.enum';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { StudyProgramPeriodBlock } from './StudyProgramPeriodBlock';

export interface StudyProgramProps {
  studyProgram: StudyProgram;
  costFormatter: Intl.NumberFormat;
  showCommission: boolean;
}

export const StudyProgramCard = ({ studyProgram, costFormatter, showCommission }: StudyProgramProps) => {
  const theme = useTheme();
  const { user } = useAuth0<AuthUser>();
  const params = useParams();
  const isPdfFormat = useIsPdfFormat();
  const isSharedView = useMatch('/marketing/profiles/:profileId/shared-view/p/:region/u/:userId/vp/:viewPricing');
  const canViewPricing = isPdfFormat ? false : isSharedView ? params.vp === '1' : canUser(Permissions.VIEW_PRICING, user);

  const firstCost = studyProgram.studyProgramPeriods?.[0];
  const firstCostType = firstCost?.costType;
  const costTypeText = firstCost?.commissionUsd ? '(Gross)' : '(Net)';
  const fallbackProgramName = `${StudyProgramPeriodTypeNames[studyProgram.periodType]} Program ${firstCostType && canViewPricing ? costTypeText : ''}`;

  const backgroundColor = theme.palette.background.default;

  return (
    <Paper key={studyProgram.id} sx={{ padding: '20px', marginBottom: '10px', backgroundColor }}>
      <Typography variant="h6" fontWeight="600" mb={1}>
        {studyProgram.name || fallbackProgramName}
      </Typography>
      {studyProgram.studyProgramPeriods.map((studyProgramPeriod) => (
        <StudyProgramPeriodBlock
          key={studyProgramPeriod.id!}
          studyProgramPeriod={studyProgramPeriod}
          costFormatter={costFormatter}
          showCommission={showCommission}
        />
      ))}
    </Paper>
  );
};
