import { Chip, Stack, Typography } from '@mui/material';
import { ApplicationWindowStatusPill } from 'components/ApplicationWindowStatusPill';
import { format } from 'date-fns';
import { StudyProgramPeriod } from 'types/study-program-period.interface';

export interface StudyProgramPeriodDescriptionProps {
  studyProgramPeriod: StudyProgramPeriod;
}

export const StudyProgramPeriodDescription = ({ studyProgramPeriod }: StudyProgramPeriodDescriptionProps) => {
  // Label is (only) populated via Zoho sync, and such profiles do not have the more description timestamp fields
  // Must be parsed for useful parts.
  if (studyProgramPeriod.label) {
    if (studyProgramPeriod.label === 'Unknown Availability') {
      return (
        <Stack key={studyProgramPeriod.id} direction="row" mt={1}>
          <Typography variant="body1" textTransform="capitalize">
            Program availability
          </Typography>
          <Chip color={'success'} sx={{ fontWeight: 600, ml: 1, border: 'none', color: 'white' }} size="small" label={'OPEN'} />
        </Stack>
      );
    }

    const { label } = studyProgramPeriod;
    const isOpen = label.toLowerCase().includes('open');
    const year = label.match(/20[0-9]{2}/g)?.[0] || '';
    const season = label
      .replace(year, '')
      .replace(/open|closed/i, '')
      .replaceAll(/\s+/g, '');
    return (
      <Stack key={studyProgramPeriod.id} direction="row" mt={1}>
        <Typography variant="body1" textTransform="capitalize">{`${season} ${year}`}</Typography>
        <Chip
          color={isOpen ? 'success' : 'secondary'}
          sx={{ fontWeight: 600, ml: 1, border: 'none', color: 'white' }}
          size="small"
          label={isOpen ? 'OPEN' : 'CLOSED'}
        />
      </Stack>
    );
  }

  if (!studyProgramPeriod.intakeApplicationsStartAt && !studyProgramPeriod.intakeApplicationsEndAt && !studyProgramPeriod.intakeStartAt) {
    return <></>;
  }

  const formattedIntakeStartDate = format(new Date(studyProgramPeriod.intakeStartAt), 'd MMMM yyyy');

  return (
    <Stack key={studyProgramPeriod.id} direction="row" mt={1} alignItems="center" spacing={2}>
      <Typography variant="body1" textTransform="capitalize">
        {formattedIntakeStartDate}
      </Typography>
      <ApplicationWindowStatusPill windowStart={studyProgramPeriod.intakeApplicationsStartAt} windowEnd={studyProgramPeriod.intakeApplicationsEndAt} />
    </Stack>
  );
};
