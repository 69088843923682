import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ProfileSummary } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';
import { canUserEditMarketingProfile } from 'utils/userRoles';

interface ProfileCardLinkProps {
  profile: ProfileSummary | null;
  children: ReactNode;
}

export const ProfileCardLink = ({ profile, children }: ProfileCardLinkProps) => {
  const { user } = useAuth0<AuthUser>();

  if (!profile) return <>{children}</>;

  const profileBasePath = `/marketing/profiles/${profile.slug}`;
  const profileAction = canUserEditMarketingProfile(user) ? `edit` : `view`;
  const cardLink = `${profileBasePath}/${profileAction}`;

  // React Router links are internal only so we need anchor tags for external site links
  return profile?.forceRedirectToWebsite ? (
    <a href={profile?.websiteUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
      {children}
    </a>
  ) : (
    <Link to={cardLink} style={{ textDecoration: 'none' }}>
      {children}
    </Link>
  );
};
