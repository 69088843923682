import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

type ColorOptions = 'success' | 'error' | 'warning' | 'info';

interface PillProps extends ChipProps {
  color?: ColorOptions;
}

export const Pill = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'color',
})<PillProps>(({ color = 'success', theme }) => {
  return {
    fontWeight: 500,
    fontSize: 10,
    letterSpacing: 1.5,
    borderRadius: 99,
    height: '20px',
    textTransform: 'uppercase',
    background: theme.palette[color].light,
    color: theme.palette[color].dark,
    [`& .${chipClasses.icon}`]: {
      color: theme.palette[color].dark,
    },
  };
});
