import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useAsync } from 'hooks/use-async';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';

interface DeleteButtonProps {
  file: IFile;
  onDelete: () => void;
}

export const DeleteButton = ({ file, onDelete }: DeleteButtonProps) => {
  const { profileSlug } = useParams();

  const deleteFileUrl = `/profiles/${profileSlug}/files/${file.id}`;
  const [, status, startDelete] = useAsync(deleteFileUrl, 'DELETE', false);

  useEffect(() => {
    if (status === 'success') onDelete();
  }, [status, onDelete]);

  return (
    <Tooltip title="Delete">
      <span>
        <IconButton aria-label="delete" disabled={status === 'pending'} onClick={() => startDelete()}>
          {status === 'pending' ? <CircularProgress size="24px" /> : <DeleteOutlinedIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
