import { Navigate, Outlet, useParams } from 'react-router-dom';

import { ProfileProvider } from './ProfileContext';

export const ProfileLoader = () => {
  const { profileSlug } = useParams();

  if (!profileSlug) {
    return <Navigate to="/marketing" />;
  }

  return (
    <ProfileProvider slug={profileSlug}>
      <Outlet />
    </ProfileProvider>
  );
};
