interface VideoUrlType {
  name: string;
  example?: string;
  regex: RegExp;
  transform: (url: string) => string;
}

export class VideoUrlParser {
  private static readonly youtubeEmbedUrlRegex = /(?:^https:\/\/www\.youtube(?:-nocookie)?\.com\/embed\/)([A-Za-z0-9_-]+)(?:\?start=([0-9]+))?(?:$)/;
  private static readonly youtubeShortUrlRegex = /(?:^https:\/\/youtu\.be\/)([A-Za-z0-9_-]+)(?:\?t=([0-9]+)s?)?(?:$)/;
  private static readonly youtubeWebUrlRegex = /(?:^https:\/\/www\.youtube(?:-nocookie)?\.com\/watch\?.*v=)([A-Za-z0-9_-]+).*(?:$)/;
  private static readonly vimeoWebUrlRegex = /(?:^https:\/\/vimeo\.com\/)([0-9]+)(?:$)/;
  private static readonly vimeoPlayerUrlRegex = /(?:^https:\/\/player\.vimeo\.com\/video\/)([0-9]+)(?:$)/;
  private static readonly vimeoNamespacedWebUrlRegex = /(?:^https:\/\/vimeo\.com\/)([0-9]+)(?:\/)([a-z0-9]+)(?:$)/;
  private static readonly vimeoNamespacePlayerUrlRegex = /(?:^https:\/\/player\.vimeo\.com\/video\/)([0-9]+)(?:\?h=)([a-z0-9]+)(?:$)/;

  private static readonly videoUrlTypes: VideoUrlType[] = [
    {
      name: 'Youtube Embed URL',
      // example: 'https://www.youtube.com/embed/Zcq_xLi2NGo?start=60',
      regex: VideoUrlParser.youtubeEmbedUrlRegex,
      transform: (v) => {
        const matches = v.match(VideoUrlParser.youtubeEmbedUrlRegex);
        if (!matches) throw new Error(`Invalid URL`);
        const startSuffix = matches[2] ? `?start=${matches[2]}` : '';
        return `https://www.youtube-nocookie.com/embed/${matches[1]}${startSuffix}`;
      },
    },
    {
      name: 'Youtube Short URL',
      // example: 'https://youtu.be/Zcq_xLi2NGo?t=60s',
      regex: VideoUrlParser.youtubeShortUrlRegex,
      transform: (v) => {
        const matches = v.match(VideoUrlParser.youtubeShortUrlRegex);
        if (!matches) throw new Error(`Invalid URL`);
        const startSuffix = matches[2] ? `?start=${matches[2]}` : '';
        return `https://www.youtube-nocookie.com/embed/${matches[1]}${startSuffix}`;
      },
    },
    {
      name: 'Youtube Web URL',
      // example: 'https://www.youtube.com/watch?v=Zcq_xLi2NGo&t=60s',
      regex: VideoUrlParser.youtubeWebUrlRegex,
      transform: (v) => {
        const matches = v.match(VideoUrlParser.youtubeWebUrlRegex);
        if (!matches) throw new Error(`Invalid URL`);
        // URL parser instead of regex as it's simpler for handling out-of-order query string params
        const params = new URL(v).searchParams;
        const vId = params.get('v');
        const startSuffix = params.get('t') ? `?start=${params.get('t')!.replace('s', '')}` : '';
        return `https://www.youtube-nocookie.com/embed/${vId}${startSuffix}`;
      },
    },
    {
      name: 'Vimeo Web URL',
      // example: 'https://vimeo.com/148751763',
      regex: VideoUrlParser.vimeoWebUrlRegex,
      transform: (v: string) => {
        const matches = v.match(VideoUrlParser.vimeoWebUrlRegex);
        if (!matches) throw new Error(`Invalid URL`);
        return `https://player.vimeo.com/video/${matches[1]}`;
      },
    },
    {
      name: 'Vimeo Player URL',
      // example: 'https://player.vimeo.com/video/148751763',
      regex: VideoUrlParser.vimeoPlayerUrlRegex,
      transform: (v) => v,
    },
    {
      name: 'Vimeo Namespaced Web URL',
      // example: 'https://vimeo.com/641132665/e11e1d6a7e',
      regex: VideoUrlParser.vimeoNamespacedWebUrlRegex,
      transform: (v: string) => {
        const matches = v.match(VideoUrlParser.vimeoNamespacedWebUrlRegex);
        if (!matches) throw new Error(`Invalid URL`);
        return `https://player.vimeo.com/video/${matches[1]}?h=${matches[2]}`;
      },
    },
    {
      name: 'Vimeo Namespaced Player URL',
      // example: 'https://player.vimeo.com/video/641132665?h=e11e1d6a7e',
      regex: VideoUrlParser.vimeoNamespacePlayerUrlRegex,
      transform: (v) => v,
    },
  ];

  public static isValid(videoUrl: string): boolean {
    for (const videoUrlType of VideoUrlParser.videoUrlTypes) {
      if (videoUrl.match(videoUrlType.regex) !== null) return true;
    }
    return false;
  }

  public static transform(videoUrl: string): string {
    for (const videoUrlType of VideoUrlParser.videoUrlTypes) {
      if (videoUrl.match(videoUrlType.regex) !== null) return videoUrlType.transform(videoUrl);
    }
    throw new Error('Invalid URL');
  }
}
