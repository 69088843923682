import { Card, FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Skeleton, Stack } from '@mui/material';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType, StudyProgramPeriodTypeNames } from 'types/study-program-period-type.enum';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';
import { useAsync } from 'hooks/use-async';

interface ProgramPricingFilterProps {
  pricingProgramPeriod: StudyProgramPeriodType;
  pricingMinUsd: StudyProgramPeriod['basePriceUsd'];
  pricingMaxUsd: StudyProgramPeriod['basePriceUsd'];
  onProgramPeriodChange: (newPeriod: StudyProgramPeriodType) => void;
  onMinUsdChange: (newMinUsd: string) => void;
  onMaxUsdChange: (newMaxUsd: string) => void;
  onDiscard: () => void;
}

export const ProgramPricingFilter = ({
  pricingProgramPeriod,
  pricingMinUsd,
  pricingMaxUsd,
  onProgramPeriodChange,
  onMinUsdChange,
  onMaxUsdChange,
  onDiscard,
}: ProgramPricingFilterProps) => {
  const fetchProgramPeriodOptionsUrl = `/profiles/filter-options/program-periods`;
  const [studyProgramPeriodTypes, studyProgramPeriodTypesStatus] = useAsync<StudyProgramPeriodType[]>(fetchProgramPeriodOptionsUrl);

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Program Pricing" onDiscard={onDiscard} />
      <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
        <FormControl sx={{ width: 300 }}>
          <InputLabel id="program-period-filter-select-label">Select period</InputLabel>
          {studyProgramPeriodTypesStatus === 'pending' && <Skeleton variant="rectangular" animation="wave" width="100%" height="40px" />}
          {studyProgramPeriodTypes?.length > 0 && (
            <Select
              labelId="program-period-filter-select-label"
              id="program-period-filter-select"
              label="Select period"
              value={pricingProgramPeriod}
              onChange={(event) => onProgramPeriodChange(event.target.value as StudyProgramPeriodType)}
            >
              {studyProgramPeriodTypes.map((studyProgramPeriodType) => (
                <MenuItem key={studyProgramPeriodType} value={studyProgramPeriodType}>
                  {StudyProgramPeriodTypeNames[studyProgramPeriodType]} program
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
        <FormControl sx={{ width: 150 }}>
          <InputLabel htmlFor="pricing-min-usd-input">From (USD)</InputLabel>
          <OutlinedInput
            id="pricing-min-usd-input"
            value={pricingMinUsd}
            onChange={(event) => onMinUsdChange(event.target.value.replaceAll(/[^\d]/g, ''))}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="From (USD)"
            placeholder="0"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </FormControl>
        <FormControl sx={{ width: 150 }}>
          <InputLabel htmlFor="pricing-max-usd-input">To (USD)</InputLabel>
          <OutlinedInput
            id="pricing-max-usd-input"
            value={pricingMaxUsd}
            onChange={(event) => onMaxUsdChange(event.target.value.replaceAll(/[^\d]/g, ''))}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="To (USD)"
            placeholder="Any"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </FormControl>
      </Stack>
    </Card>
  );
};
