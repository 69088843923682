import { Point } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { createGeoJSONCircle } from 'utils/create-geojson-circle';
import { getOptimisedImageUrl } from 'utils/getOptimisedImageUrl';

interface MapWithRadiusProps {
  center: Point;
  logoUrl: string | null;
  radiusKm: number;
  zoom: number;
  height: CSSProperties['height'];
  width: CSSProperties['width'];
}

export const MapWithRadius: React.FC<MapWithRadiusProps> = ({ center, logoUrl, radiusKm, zoom, width, height }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [markerPos, setMarkerPos] = useState<string>('');

  useEffect(() => {
    const [lng, lat] = center.coordinates;
    const initializeMap = ({ setMap, mapContainer }: any) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom,
        preserveDrawingBuffer: true, // Required for preserving the map image when printing
      });
      map.on('load', () => {
        setMap(map);
        map.resize();
        addMarker(map);
        addRadius(map);
      });
      // Disable scroll zoom
      map.keyboard.disable();
      map.scrollZoom.disable();
      map.touchZoomRotate.disable();
      map.doubleClickZoom.disable();
      // Add a scale control to the map
      map.addControl(new mapboxgl.ScaleControl());
      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, 'top-right');
    };

    const addMarker = (map: mapboxgl.Map) => {
      // Create a HTML element for each feature with the marker image
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = window.location.origin + '/map-marker.svg';

      // Create an image subelement to contain the logo
      const logoEl = document.createElement('img');
      const logoElSrc = logoUrl
        ? getOptimisedImageUrl(logoUrl, { resize: { width: 28, height: 28 } })
        : window.location.origin + '/map-marker-logo-fallback.svg';
      logoEl.className = 'map-marker-logo';
      logoEl.src = logoElSrc;
      logoEl.alt = 'school logo';
      el.appendChild(logoEl);

      // Make a marker for each feature and add it to the map
      const marker = new mapboxgl.Marker(el, { offset: [0, -32] }).setLngLat(center.coordinates as [number, number]).addTo(map);
      setMarker(marker);
      setMarkerPos(`${lat},${lng}`);
    };
    const addRadius = (map: mapboxgl.Map) => {
      const geometry = createGeoJSONCircle(center, radiusKm);
      map.addSource('radius', geometry as mapboxgl.AnySourceData);
      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'radius',
        layout: {},
        paint: {
          'fill-color': 'green',
          'fill-opacity': 0.15,
        },
      });
    };
    if (map && marker && markerPos !== `${lat},${lng}`) {
      marker.remove();
      addMarker(map);
      addRadius(map);
    }

    if (!map) {
      initializeMap({ setMap, mapContainer });
    }
  }, [map, center, logoUrl, radiusKm, zoom, marker, markerPos]);

  return <div className="mapboxgl-container" ref={mapContainer} style={{ height, width }} />;
};
