import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, AlertTitle, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Pill } from 'components/Pill';
import { useAsync } from 'hooks/use-async';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { FileRoles } from 'types/file-roles.enum';
import { UploadWithPreview } from 'app/marketing/profiles/Edit/UploadWithPreview';

interface SectionHeaderProps {
  isComplete: boolean;
}

const SectionHeader = ({ isComplete }: SectionHeaderProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        School logo
      </Typography>
      <Pill
        label={isComplete ? 'Complete' : 'Incomplete'}
        color={isComplete ? 'success' : 'error'}
        icon={isComplete ? <DoneIcon color="success" /> : <WarningAmberIcon color="error" />}
      />
    </Stack>
  );
};

export const LogoImage = () => {
  const { profileSlug } = useParams();
  const theme = useTheme();
  const fileRole = FileRoles.FILE_ROLE_MARKETING_LOGO;
  const fetchLogoUrl = `/profiles/${profileSlug}/files?role=${fileRole}`;
  const [files, getFilesStatus, fetchFiles] = useAsync<IFile[]>(fetchLogoUrl);

  return (
    <>
      <SectionHeader isComplete={files?.length > 0} />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <UploadWithPreview
            maxWidth={200}
            fileRole={fileRole}
            file={files?.length ? files[0] : null}
            onUploadComplete={fetchFiles}
            isLoading={getFilesStatus !== 'success'}
            mimeTypes={['image/jpeg', 'image/png']}
          />
        </Grid>
        <Grid item xs={6}>
          <Alert
            severity="info"
            color="info"
            sx={{
              '& .MuiAlert-icon': {
                color: theme.palette.secondary.dark,
              },
            }}
          >
            <AlertTitle>Logo dimensions</AlertTitle>
            It is best to select a variation of the school logo that would fit into a square. For best results, pick a logo that is at least 150px in width and
            height
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};
