import { useAuth0 } from '@auth0/auth0-react';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { Curriculum, ProfileData } from 'types/profile.interface';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';
import { useDebounce } from 'use-debounce';
import { canUser } from 'utils/userRoles';
import { AuthUser } from 'types/user.interface';
import { Permissions } from 'types/permissions.enum';
import { PricingRegion } from 'types/pricing-region.enum';

import { FilterBoolean } from './filter-boolean.type';
import { FilterFlag } from './filter-flag.type';
import { FilterParams } from './filter-params.interface';

type FiltersState = {
  filters: FilterParams;
  search: string;
  pagination: {
    pageNumber: number;
    pageSize: number;
    sortBy: string;
    sortDirection: 'ASC' | 'DESC';
  };
};

type getSearchParamsFromFiltersOptions = { paginate: boolean; sort: boolean; filterGeometry: boolean };

type FiltersContextData = {
  state: FiltersState;
  updateFilters: (filters: FilterParams) => void;
  updateSearch: (search: string) => void;
  updateGeoBounds: (geoBounds: string) => void;
  updatePageNumber: (pageNumber: number) => void;
  updatePagination: (pagination: { pageSize: number; sortBy: string; sortDirection: 'ASC' | 'DESC' }) => void;
  getSearchParamsFromFilters: (options: getSearchParamsFromFiltersOptions) => URLSearchParams;
};

const FiltersContext = createContext<FiltersContextData | undefined>(undefined);

const getFiltersFromSearchParams = (searchParams: URLSearchParams, user: AuthUser | undefined): FilterParams => {
  const filterParams: FilterParams = {};

  if (searchParams.get('coedStatus')) {
    filterParams.coedStatus = searchParams.get('coedStatus') as CoedStatuses;
  }
  if (searchParams.get('country')) {
    filterParams.country = searchParams.get('country') as ProfileData['country'];
  }
  if (searchParams.get('state')) {
    filterParams.state = searchParams.get('state') as ProfileData['state'];
  }
  if (searchParams.get('city')) {
    filterParams.city = searchParams.get('city') as ProfileData['city'];
  }
  if (searchParams.get('customAttribute')) {
    filterParams.customAttribute = searchParams.get('customAttribute') as ProfileData['customAttribute'];
  }
  if (searchParams.get('pricingProgramPeriod') && canUser(Permissions.VIEW_PRICING, user)) {
    filterParams.pricingProgramPeriod = searchParams.get('pricingProgramPeriod') as StudyProgramPeriodType;
  }
  if (searchParams.get('programAvailability')) {
    filterParams.programAvailability = searchParams.get('programAvailability') as StudyProgramPeriod['label'];
  }
  if (searchParams.get('pricingMinUsd')) {
    filterParams.pricingMinUsd = searchParams.get('pricingMinUsd') as StudyProgramPeriod['basePriceUsd'];
  }
  if (searchParams.get('pricingMaxUsd')) {
    filterParams.pricingMaxUsd = searchParams.get('pricingMaxUsd') as StudyProgramPeriod['basePriceUsd'];
  }
  if (searchParams.get('pricingOverrideRegion')) {
    filterParams.pricingOverrideRegion = searchParams.get('pricingOverrideRegion') as PricingRegion;
  }
  if (searchParams.get('pricingOverrideCostType')) {
    filterParams.pricingOverrideCostType = searchParams.get('pricingOverrideCostType') as 'net' | 'gross';
  }
  if (searchParams.get('isPrivateSchool')) {
    filterParams.isPrivateSchool = searchParams.get('isPrivateSchool') as FilterBoolean;
  }
  if (searchParams.get('hasEsolSupport')) {
    filterParams.hasEsolSupport = searchParams.get('hasEsolSupport') as FilterFlag;
  }
  if (searchParams.get('hasUsDiploma')) {
    filterParams.hasUsDiploma = searchParams.get('hasUsDiploma') as FilterFlag;
  }
  if (searchParams.get('acceptsAge')) {
    filterParams.acceptsAge = parseInt(searchParams.get('acceptsAge')!);
  }
  if (searchParams.get('dayOrBoarding')) {
    filterParams.dayOrBoarding = searchParams.get('dayOrBoarding') as ProfileData['dayOrBoarding'];
  }
  if (searchParams.get('acceptsGrade')) {
    filterParams.acceptsGrade = parseInt(searchParams.get('acceptsGrade')!);
  }
  if (searchParams.getAll('subjects').length > 0) {
    filterParams.subjects = searchParams.getAll('subjects');
  }
  if (searchParams.get('hasAllSubjects')) {
    filterParams.hasAllSubjects = searchParams.get('hasAllSubjects') as FilterFlag;
  }
  if (searchParams.getAll('advancedSubjects').length > 0) {
    filterParams.advancedSubjects = searchParams.getAll('advancedSubjects');
  }
  if (searchParams.get('hasAllAdvancedSubjects')) {
    filterParams.hasAllAdvancedSubjects = searchParams.get('hasAllAdvancedSubjects') as FilterFlag;
  }
  if (searchParams.getAll('sports').length > 0) {
    filterParams.sports = searchParams.getAll('sports');
  }
  if (searchParams.get('hasAllSports')) {
    filterParams.hasAllSports = searchParams.get('hasAllSports') as FilterFlag;
  }
  if (searchParams.get('intlCurriculum')) {
    filterParams.intlCurriculum = searchParams.get('intlCurriculum') as Curriculum['name'];
  }

  return filterParams;
};

interface FiltersProviderProps {
  children: ReactNode;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const { user } = useAuth0<AuthUser>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState<FilterParams>(getFiltersFromSearchParams(searchParams, user));
  const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || 'name');
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC'>((searchParams.get('sortDirection') as 'ASC' | 'DESC') || 'ASC');
  const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get('pageNumber') || '1'));
  const [pageSize, setPageSize] = useState(parseInt(searchParams.get('pageSize') || '12'));
  const [searchTerm, setSearchTerm] = useState<string>(searchParams.get('search') || '');
  const [geoBounds, setGeoBounds] = useState<string>(searchParams.get('geoBounds') || '');

  const [debouncedPricingMinUsd] = useDebounce<string>(filterParams.pricingMinUsd!, 400);
  const [debouncedPricingMaxUsd] = useDebounce<string>(filterParams.pricingMaxUsd!, 400);

  useEffect(() => {
    // Don't show empty params, e.g. 'country=&city=' vs ''
    const newSearchParams = {} as any;
    if (filterParams.coedStatus) newSearchParams['coedStatus'] = filterParams.coedStatus;
    if (filterParams.country) newSearchParams['country'] = filterParams.country;
    if (filterParams.state) newSearchParams['state'] = filterParams.state;
    if (filterParams.city) newSearchParams['city'] = filterParams.city;
    if (filterParams.customAttribute) newSearchParams['customAttribute'] = filterParams.customAttribute;
    if (filterParams.programAvailability) newSearchParams['programAvailability'] = filterParams.programAvailability;
    if (filterParams.pricingProgramPeriod) newSearchParams['pricingProgramPeriod'] = filterParams.pricingProgramPeriod;
    if (debouncedPricingMinUsd) newSearchParams['pricingMinUsd'] = debouncedPricingMinUsd;
    if (debouncedPricingMaxUsd) newSearchParams['pricingMaxUsd'] = debouncedPricingMaxUsd;
    if (filterParams.pricingOverrideRegion) newSearchParams['pricingOverrideRegion'] = filterParams.pricingOverrideRegion;
    if (filterParams.pricingOverrideCostType) newSearchParams['pricingOverrideCostType'] = filterParams.pricingOverrideCostType;
    if (filterParams.isPrivateSchool) newSearchParams['isPrivateSchool'] = filterParams.isPrivateSchool;
    if (filterParams.hasEsolSupport) newSearchParams['hasEsolSupport'] = filterParams.hasEsolSupport;
    if (filterParams.hasUsDiploma) newSearchParams['hasUsDiploma'] = filterParams.hasUsDiploma;
    if (filterParams.acceptsAge) newSearchParams['acceptsAge'] = filterParams.acceptsAge;
    if (filterParams.dayOrBoarding) newSearchParams['dayOrBoarding'] = filterParams.dayOrBoarding;
    if (filterParams.acceptsGrade) newSearchParams['acceptsGrade'] = filterParams.acceptsGrade;
    if ((filterParams.subjects?.length || 0) > 0) newSearchParams['subjects'] = filterParams.subjects;
    if (filterParams.hasAllSubjects && filterParams.hasAllSubjects === 'true') newSearchParams['hasAllSubjects'] = 'true';
    if ((filterParams.advancedSubjects?.length || 0) > 0) newSearchParams['advancedSubjects'] = filterParams.advancedSubjects;
    if (filterParams.hasAllAdvancedSubjects && filterParams.hasAllAdvancedSubjects === 'true') newSearchParams['hasAllAdvancedSubjects'] = 'true';
    if ((filterParams.sports?.length || 0) > 0) newSearchParams['sports'] = filterParams.sports;
    if (filterParams.hasAllSports && filterParams.hasAllSports === 'true') newSearchParams['hasAllSports'] = 'true';
    if (filterParams.intlCurriculum) newSearchParams['intlCurriculum'] = filterParams.intlCurriculum;
    // These are not in filterParams because they're handled by UI outside of the filter drawer
    if (geoBounds) newSearchParams['geoBounds'] = geoBounds;
    if (searchTerm) newSearchParams['search'] = searchTerm;
    if (sortBy) newSearchParams['sortBy'] = sortBy;
    if (sortDirection) newSearchParams['sortDirection'] = sortDirection;
    if (`${currentPage}`) newSearchParams['pageNumber'] = `${currentPage}`;
    if (`${pageSize}`) newSearchParams['pageSize'] = `${pageSize}`;
    setSearchParams(newSearchParams);
  }, [filterParams, currentPage, searchTerm, geoBounds, sortBy, sortDirection, setSearchParams, debouncedPricingMinUsd, debouncedPricingMaxUsd, pageSize]);

  const updateFilters = useCallback((filters: FilterParams) => {
    setFilterParams(filters);
    setCurrentPage(1);
  }, []);

  const updateSearch = useCallback((search: string) => {
    setSearchTerm(search);
    setCurrentPage(1);
  }, []);

  const updateGeoBounds = useCallback((geoBounds: string) => {
    setGeoBounds(geoBounds);
    setCurrentPage(1);
  }, []);

  const updatePagination = useCallback(({ pageSize, sortBy, sortDirection }: { pageSize: number; sortBy: string; sortDirection: 'ASC' | 'DESC' }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setPageSize(pageSize);
    setCurrentPage(1);
  }, []);

  const updatePageNumber = useCallback((pageNumber: number) => setCurrentPage(pageNumber), []);

  const getSearchParamsFromFilters = useCallback(
    ({ paginate, sort, filterGeometry }: getSearchParamsFromFiltersOptions) => {
      // Query string parameters for both paginated profile search and geocoord profile search
      const profileSearchParams = new URLSearchParams();

      // Profile Filter params
      if (searchTerm) profileSearchParams.set('search', searchTerm.trim());
      if (filterParams.coedStatus) profileSearchParams.append('coedStatus[]', filterParams.coedStatus);
      if (filterParams.country) profileSearchParams.append('country[]', filterParams.country);
      if (filterParams.state) profileSearchParams.append('state[]', filterParams.state);
      if (filterParams.city) profileSearchParams.append('city[]', filterParams.city);
      if (filterParams.customAttribute) profileSearchParams.append('customAttribute[]', filterParams.customAttribute);
      if (filterParams.programAvailability) profileSearchParams.append('programAvailability', filterParams.programAvailability);
      if (filterParams.pricingProgramPeriod) profileSearchParams.append('pricingProgramPeriod', filterParams.pricingProgramPeriod);
      if (filterParams.pricingMinUsd) profileSearchParams.append('pricingMinUsd', filterParams.pricingMinUsd);
      if (filterParams.pricingMaxUsd) profileSearchParams.append('pricingMaxUsd', filterParams.pricingMaxUsd);
      if (filterParams.pricingOverrideRegion) profileSearchParams.append('pricingOverrideRegion', filterParams.pricingOverrideRegion);
      if (filterParams.pricingOverrideCostType) profileSearchParams.append('pricingOverrideCostType', filterParams.pricingOverrideCostType);
      if (filterParams.isPrivateSchool) profileSearchParams.append('isPrivateSchool', filterParams.isPrivateSchool);
      if (filterParams.hasEsolSupport) profileSearchParams.append('hasEsolSupport', filterParams.hasEsolSupport);
      if (filterParams.hasUsDiploma) profileSearchParams.append('hasUsDiploma', filterParams.hasUsDiploma);
      if (filterParams.acceptsAge) profileSearchParams.append('acceptsAge', filterParams.acceptsAge.toString());
      if (filterParams.dayOrBoarding) profileSearchParams.append('dayOrBoarding', filterParams.dayOrBoarding);
      if (filterParams.acceptsGrade) profileSearchParams.append('acceptsGrade', filterParams.acceptsGrade.toString());
      if (filterParams.subjects) filterParams.subjects.forEach((subject: string) => profileSearchParams.append('subjects[]', subject));
      if (filterParams.hasAllSubjects) profileSearchParams.append('hasAllSubjects', filterParams.hasAllSubjects);
      if (filterParams.advancedSubjects)
        filterParams.advancedSubjects.forEach((advancedSubject: string) => profileSearchParams.append('advancedSubjects[]', advancedSubject));
      if (filterParams.hasAllAdvancedSubjects) profileSearchParams.append('hasAllAdvancedSubjects', filterParams.hasAllAdvancedSubjects);
      if (filterParams.sports) filterParams.sports.forEach((subject: string) => profileSearchParams.append('sports[]', subject));
      if (filterParams.hasAllSports) profileSearchParams.append('hasAllSports', filterParams.hasAllSports);
      if (filterParams.intlCurriculum) profileSearchParams.append('intlCurriculum', filterParams.intlCurriculum);

      if (filterGeometry) {
        profileSearchParams.append('geoBounds', geoBounds);
      }
      if (paginate) {
        profileSearchParams.append('pageNumber', currentPage.toString());
        profileSearchParams.append('pageSize', pageSize.toString());
      }
      if (sort) {
        profileSearchParams.append('sortBy', sortBy);
        profileSearchParams.append('sortDirection', sortDirection);
      }

      return profileSearchParams;
    },
    [filterParams, currentPage, geoBounds, pageSize, searchTerm, sortBy, sortDirection],
  );

  return (
    <FiltersContext.Provider
      value={{
        state: {
          filters: filterParams,
          search: searchTerm,
          pagination: { pageNumber: currentPage, pageSize, sortBy, sortDirection },
        },
        updateFilters,
        updateSearch,
        updateGeoBounds,
        updatePageNumber,
        updatePagination,
        getSearchParamsFromFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);

  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  return context;
};
