import { useAuth0 } from '@auth0/auth0-react';
import { HelpRounded } from '@mui/icons-material';
import { AppBar, Avatar, Box, Link, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Logo from 'assets/images/logo-enroller.svg';
import { UserMenuIconButton } from 'components/UserMenuIconButton';
import ReactGA from 'react-ga4';
import { useMatch, useNavigate } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';
import { isMarketingOnlySchoolUser } from 'utils/userRoles';

export const EnrollerHeader = () => {
  const { user } = useAuth0<AuthUser>();
  const navigate = useNavigate();
  const theme = useTheme();

  const isSharedView = useMatch('/marketing/profiles/:profileId/shared-view/p/:region/u/:userId/vp/:viewPricing');

  const sendGAEvent = (eventType: string) => {
    ReactGA.event({ category: 'Nav Link', label: `Marketplace Header: ${eventType}`, action: 'Clicked' });
  };

  const { enrolmentsUrl } = getConfig();
  const isAgent = user?.userData.roles.includes('agent') || false;
  const enrolmentsPageUrl = `${enrolmentsUrl}${isAgent ? '/enrolments' : '/my-schools'}`;

  const linkStyle = { ml: 2, mr: 2, color: '#414141', textDecoration: 'none', cursor: 'pointer' };
  const mpLinkStyle = {
    ...linkStyle,
    ...theme.mixins.titleGradient,
    fontWeight: 600,
    // TODO: Work out how to get this border aligned along the bottom of the AppBar
    // borderBottom: `3px solid ${theme.palette.primary.main}`,
  };
  const borderColor = grey[200];

  return (
    <AppBar sx={{ zIndex: 100, boxShadow: 0, borderBottom: '1px solid', borderColor }}>
      <Box
        sx={{
          py: 1.5,
          pl: 1.3,
          pr: 3.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#fff',
        }}
      >
        <Link href="/" sx={{ height: 26, mr: 5 }}>
          <img src={Logo} alt="Enroller" height="26px" />
        </Link>

        {!isSharedView && (
          <Box sx={{ height: 36, display: 'flex', alignItems: 'center' }}>
            <Link
              sx={mpLinkStyle}
              onClick={() => {
                sendGAEvent('Marketing');
                navigate('/marketing');
              }}
            >
              Marketplace
            </Link>
            {!isMarketingOnlySchoolUser(user?.userData.roles || []) && (
              <Link
                sx={linkStyle}
                href={enrolmentsPageUrl}
                onClick={() => {
                  sendGAEvent('Enrolments');
                }}
              >
                Enrolments
              </Link>
            )}
          </Box>
        )}
        <Box flex={1} />

        <Link
          href="//help.enroller.co.nz/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ mr: 1.8, height: 32 }}
          onClick={() => {
            sendGAEvent('Help');
          }}
        >
          <HelpRounded sx={{ color: grey[800], fontSize: '32px' }} />
        </Link>
        <UserMenuIconButton enableProfileLink sx={{ p: 0, height: 36 }}>
          <Avatar
            sx={{ background: theme.palette.primary.main, width: 32, height: 32, fontSize: 15, fontWeight: 'bold' }}
            onClick={() => {
              sendGAEvent('User menu');
            }}
          >
            {user?.userData.firstName[0]}
            {user?.userData.lastName[0]}
          </Avatar>
        </UserMenuIconButton>
      </Box>
    </AppBar>
  );
};
