import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Skeleton, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Country } from 'types/country.interface';
import { ProfileData } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';

const ENQUIRY_TYPES = [
  'The programme/course',
  'Important dates',
  'Fees, payment and cost of living',
  'Accommodation options',
  'How to apply',
  'Entry requirements',
  'Agent contracting',
  'English level required',
  'Other',
].map((type) => ({ label: type, value: type }));

type EnquireDialogProps = {
  open: boolean;
  onClose: () => any;
};

export const EnquireDialog = ({ open, onClose }: EnquireDialogProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth0<AuthUser>();
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const profileSlug = profile.slug;

  const [firstName, setFirstName] = useState(user?.userData.firstName || '');
  const [lastName, setLastName] = useState(user?.userData.lastName || '');
  const [agencyName, setAgencyName] = useState(user?.userData.agencyName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [contactNumber, setContactNumber] = useState(user?.userData.contactNumber || '');
  const [country, setCountry] = useState(user?.userData.countryOfResidence || '');
  const [reason, setReason] = useState('');
  const [content, setContent] = useState('');

  const [, postEnquiryStatus, postEnquiry] = useAsync(`/profiles/${profileSlug}/enquiries`, 'POST', false);

  const [countries, getCountriesStatus] = useAsync<Country[]>('/countries');

  const isUsSpelling = profile.country === 'United States';
  const enquireOrInquire = isUsSpelling ? 'Inquire' : 'Enquire';
  const enquiryOrInquiry = isUsSpelling ? 'Inquiry' : 'Enquiry';

  if (getCountriesStatus === 'error') {
    enqueueSnackbar('Failed to fetch countries list', { variant: 'error', key: `add-enquire-${profileSlug}`, preventDuplicate: true });
  }

  const onSubmit = () => {
    if (country && reason && content) {
      ReactGA.event({ category: 'Enquiry', label: profileSlug, action: 'Submitted' });
      postEnquiry({ firstName, lastName, agencyName, email, contactNumber, country, reason, content });
    }
  };

  useEffect(() => {
    if (postEnquiryStatus === 'success') {
      enqueueSnackbar(`${enquiryOrInquiry} sent successfully`, { variant: 'success', key: `add-enquire-${profileSlug}`, preventDuplicate: true });
      onClose();
    }
  }, [postEnquiryStatus, onClose, enqueueSnackbar, profileSlug, enquiryOrInquiry]);

  return (
    <Dialog open={open} scroll="paper">
      <DialogTitle>{enquireOrInquire} now</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          If you have any questions for us please complete the {enquiryOrInquiry} form below. We will respond to you directly via the contact details provided.
        </DialogContentText>
        <TextField
          margin="normal"
          fullWidth
          variant="standard"
          label={<span>Agency Name</span>}
          value={agencyName}
          onChange={(ev) => {
            setAgencyName(ev.target.value);
          }}
          disabled={Boolean(user)}
        />
        <TextField
          margin="normal"
          fullWidth
          variant="standard"
          label="Contact First Name"
          value={firstName}
          disabled={Boolean(user)}
          onChange={(ev) => {
            setFirstName(ev.target.value);
          }}
          data-cy="enquiryFirstName"
          required
        />
        <TextField
          margin="normal"
          fullWidth
          variant="standard"
          label="Contact Last Name"
          value={lastName}
          disabled={Boolean(user)}
          onChange={(ev) => {
            setLastName(ev.target.value);
          }}
          data-cy="enquiryLastName"
          required
        />
        <TextField
          margin="normal"
          fullWidth
          variant="standard"
          label="Email Address"
          value={email}
          disabled={Boolean(user)}
          onChange={(ev) => {
            setEmail(ev.target.value);
          }}
          data-cy="enquiryEmail"
          required
        />
        <TextField
          margin="normal"
          fullWidth
          variant="standard"
          label="Contact Number"
          value={contactNumber}
          onChange={(ev) => setContactNumber(ev.target.value)}
        />
        {getCountriesStatus === 'pending' && <Skeleton variant="text" />}
        {getCountriesStatus === 'success' && (
          <Autocomplete
            fullWidth
            options={countries.map((c: Country) => c.name) || []}
            autoHighlight
            value={country}
            renderInput={(params) => <TextField {...params} variant="standard" label="Country" margin="normal" required data-cy="enquiryCountry" />}
            onChange={(ev, country) => setCountry(country || '')}
          />
        )}
        <TextField
          margin="normal"
          label="I'd like to receive more information about:"
          fullWidth
          variant="standard"
          required
          select
          value={reason}
          onChange={(ev) => setReason(ev.target.value)}
          data-cy="enquiryInfoDropdown"
        >
          {ENQUIRY_TYPES.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="normal"
          label={`Write your ${enquiryOrInquiry} here`}
          fullWidth
          variant="standard"
          multiline
          rows={6}
          required
          onChange={(ev) => setContent(ev.target.value)}
          data-cy="enquiryTextField"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <LoadingButton onClick={onSubmit} loading={postEnquiryStatus === 'pending'} data-cy="submitEnquiryButton">
          {enquireOrInquire}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
