import { CloudUploadOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, alpha, Avatar, Box, Button, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AxiosRequestConfig } from 'axios';
import { Loader } from 'components/Loader';
import { useAsync } from 'hooks/use-async';
import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';

type FileUploadProps = {
  file: IFile | null;
  fileRole: string;
  mimeTypes?: string[];
  maxWidth: number;
  onUploadComplete?: (newFile: IFile[]) => void;
  isLoading?: boolean;
};

export const UploadWithPreview = ({ file, fileRole, mimeTypes, onUploadComplete, isLoading = false, maxWidth }: FileUploadProps) => {
  const { profileSlug } = useParams();

  const filesUrl = `/profiles/${profileSlug}/files`;
  const extraAxiosConfig: Partial<AxiosRequestConfig> = { headers: { 'Content-Type': 'multipart/form-data' } };
  const [res, uploadStatus, upload] = useAsync<IFile[]>(filesUrl, 'POST', false, extraAxiosConfig);

  const [, , deleteFile] = useAsync(`${filesUrl}/${file?.id}`, 'DELETE', false);

  useEffect(() => {
    if (uploadStatus === 'success' && onUploadComplete) {
      onUploadComplete(res);
    }
  }, [uploadStatus, onUploadComplete, res]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;
      if (file) deleteFile();

      const fileData = new FormData();
      fileData.append('role', fileRole);
      fileData.append('tag', 'school-upload');
      acceptedFiles.forEach((file, i) => fileData.append(`marketing-documents-${i}`, file));
      upload(fileData);
    },
    [deleteFile, file, fileRole, upload],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    disabled: isLoading || uploadStatus === 'pending',
    accept: mimeTypes || '',
    maxSize: 26214400,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    const f = file as IFile & File;
    return (
      <span key={f.path}>
        {errors.map((e) => (
          <Alert severity="error" key={e.code}>
            <AlertTitle>{e.message}</AlertTitle>
            {f.path} ({f.size} bytes)
          </Alert>
        ))}
      </span>
    );
  });

  const uploadHint = () => (
    <>
      <Avatar sx={{ backgroundColor: 'secondary.light', mb: 1 }}>
        <CloudUploadOutlined sx={{ color: 'secondary.dark' }} />
      </Avatar>
      <Typography color="primary" fontWeight="500" paragraph={false}>
        Click to upload
      </Typography>
      <Typography color="secondary" paragraph={false}>
        or drag and drop
      </Typography>
      <Typography variant="body2" color="secondary">
        {/* Server limit is technically 25MB */}
        (max filesize 20MB)
      </Typography>
    </>
  );

  const uploadRejectHint = () => (
    <>
      <Avatar sx={{ backgroundColor: 'secondary.light', mb: 1 }}>
        <CloudUploadOutlined sx={{ color: 'secondary.dark' }} />
      </Avatar>
      <Typography color="error">This file type is not allowed</Typography>
    </>
  );

  if (isLoading || uploadStatus === 'pending') {
    return (
      <Box
        sx={{
          border: `solid 1px ${grey[300]}`,
          p: 8,
          mb: 1,
          bgcolor: grey[50],
          color: grey[500],
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          border: `solid 1px ${grey[300]}`,
          p: 1,
          mb: 1,
          bgcolor: grey[50],
          color: grey[500],
          cursor: 'pointer',
          minHeight: '128px',
        }}
      >
        <input {...getInputProps()} />
        {!file && !isDragReject && uploadHint()}
        {!file && isDragReject && uploadRejectHint()}
        {file && (
          <Box sx={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={file.fileUrl} alt="School logo" style={{ maxWidth: `${maxWidth}px` }} />
            {isDragActive && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  p: 4,
                  color: grey[500],
                  bgcolor: alpha(grey[50], 0.8),
                }}
              >
                {!isDragReject && uploadHint()}
                {isDragReject && uploadRejectHint()}
              </Box>
            )}
          </Box>
        )}
      </Box>
      {fileRejectionItems}
      {file && (
        <Button variant="outlined" color="secondary" onClick={open}>
          Replace image
        </Button>
      )}
    </>
  );
};
