import {
  mdiBrush,
  mdiDramaMasks,
  mdiForumOutline,
  mdiHomeVariantOutline,
  mdiMapMarkerOutline,
  mdiMusic,
  mdiSchoolOutline,
  mdiSoccer,
  mdiStarOutline,
  mdiTrainCar,
  mdiWheelchairAccessibility,
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { Avatar } from '@mui/material';

interface IHighlightAvatarProps {
  icon: string;
  width: number;
  height: number;
  color: string;
  iconScale?: number | undefined;
}

/**
 * @see https://mui.com/components/material-icons/
 */
export const schoolHighlightIcons = new Map<string, { description: string; el: string }>(
  Object.entries({
    highlight: {
      description: 'Highlight',
      el: mdiStarOutline,
    },
    accessibility: {
      description: 'Accessibility',
      el: mdiWheelchairAccessibility,
    },
    arts: {
      description: 'Arts',
      el: mdiBrush,
    },
    transport: {
      description: 'Transport',
      el: mdiTrainCar,
    },
    languages: {
      description: 'Languages',
      el: mdiForumOutline,
    },
    locations: {
      description: 'Locations',
      el: mdiMapMarkerOutline,
    },
    accommodation: {
      description: 'Accommodation',
      el: mdiHomeVariantOutline,
    },
    music: {
      description: 'Music',
      el: mdiMusic,
    },
    academics: {
      description: 'Academics',
      el: mdiSchoolOutline,
    },
    sports: {
      description: 'Sports',
      el: mdiSoccer,
    },
    performingArts: {
      description: 'Performing Arts',
      el: mdiDramaMasks,
    },
  }),
);

export const HighlightAvatar = ({ icon, width, height, color, iconScale }: IHighlightAvatarProps) => {
  const iconComponent = schoolHighlightIcons.get(icon)?.el || mdiStarOutline;

  return (
    <Avatar sx={{ width, height }} style={{ textAlign: 'center', backgroundColor: '#eee', color }}>
      <Icon path={iconComponent!} title="User Profile" size={1} style={{ transform: `scale(${iconScale || 1})` }} />
    </Avatar>
  );
};
