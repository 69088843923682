import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';
import { getUserId } from 'utils/get-user-id';

export const useIntercom = () => {
  const location = useLocation();
  const { user } = useAuth0<AuthUser>();
  const { environment } = getConfig();

  useEffect(() => {
    if (environment === 'local') {
      console.info(`Skipping Intercom initialisation in local environment`);
      return;
    }

    if (user) {
      (window as any).Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'i7lw8l55',
        user_id: getUserId(user),
        name: `${user.userData.firstName} ${user.userData.lastName}`,
        email: user.email,
        created_at: user.userData.createdAt,
      });
    }
  }, [environment, user]);

  useEffect(() => {
    (window as any).Intercom('update');
  }, [location]);
};
