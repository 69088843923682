import { getConfig } from 'utils/config';
import { useAuth0 } from '@auth0/auth0-react';
import { getErrorMessage } from 'utils/errors';
import { deepMerge } from 'utils/deepMerge';
import { loginConfig } from 'app/auth/login-config';
import { useMemo } from 'react';

interface UseGetOptions {
  useApiUrl?: boolean;
  skipAuth?: boolean;
  headers?: HeadersInit;
}

// Static objects for default parameters to prevent rerenders
const emptyObject = {};
const defaultOptions = { useApiUrl: true, headers: emptyObject };

export const useGet = <T>(url: string, options: UseGetOptions = defaultOptions) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const get = useMemo(() => {
    const { useApiUrl, headers } = options;
    const { apiUrl } = getConfig();
    const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;
    const initialHeaders: HeadersInit = {
      Accept: 'application/json',
    };

    return async (params: URLSearchParams = new URLSearchParams()): Promise<T> => {
      try {
        if (options.skipAuth !== true) {
          const token = await getAccessTokenSilently();
          initialHeaders.Authorization = `Bearer ${token}`;
        }
      } catch {
        await loginWithRedirect(loginConfig(window.location.pathname));
        throw new Error('Not logged in. Redirecting...');
      }

      const config = deepMerge(
        {
          headers: initialHeaders,
          method: 'GET',
        },
        { headers },
      );

      return fetch(`${fetchUrl}?${params.toString()}`, config).then(async (resp) => {
        let body;
        if (resp.headers.get('content-type')?.includes('application/json')) {
          body = await resp.json();
        } else {
          body = await resp.text();
        }

        if (resp.status !== 200 && resp.status !== 204) {
          throw new Error(getErrorMessage(body));
        }

        return body;
      });
    };
  }, [getAccessTokenSilently, loginWithRedirect, options, url]);

  return get;
};
