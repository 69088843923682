import { Typography } from '@mui/material';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { useProfileSummaries } from 'app/marketing/ProviderList/ProfileSummaryCardGrid/ProfileSummariesContext';

export const ProfileBrowseTotal = () => {
  const {
    state: {
      pagination: { pageSize },
    },
  } = useFilters();

  const { lastTotal } = useProfileSummaries();

  return (
    <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }} data-intercom-target="search results">
      Showing {Math.min(pageSize, lastTotal)} of {lastTotal} profiles
    </Typography>
  );
};
