import { Box, Divider, Stack, Typography } from '@mui/material';
import { FeatureCategories } from 'types/features.enum';

import { FeatureListBox } from './FeatureListBox';
import { FeatureViewData } from './FeatureViewData.type';

interface FeaturesListViewProps {
  featureViewData: FeatureViewData;
}

export const FeaturesListView = ({ featureViewData }: FeaturesListViewProps) => {
  return (
    <Stack>
      <Box my={2}>
        <Typography variant="h6" m={1} mb={2}>
          {FeatureCategories.Subjects}
        </Typography>
        <FeatureListBox features={featureViewData.Subjects || []} featureWidth="50%" />
      </Box>
      <Divider />
      <Box my={2}>
        <Typography variant="h6" m={1} mb={2}>
          {FeatureCategories.Arts}
        </Typography>
        <FeatureListBox features={featureViewData.Arts || []} featureWidth="33%" />
      </Box>
      <Divider />
      <Box my={2}>
        <Typography variant="h6" m={1} mb={2}>
          {FeatureCategories.Music}
        </Typography>
        <FeatureListBox features={featureViewData.Music || []} featureWidth="33%" />
      </Box>
      <Divider />
      <Box my={2}>
        <Typography variant="h6" m={1} mb={2}>
          {FeatureCategories.Sports}
        </Typography>
        <FeatureListBox features={featureViewData.Sports || []} featureWidth="33%" />
      </Box>
      <Divider />
      <Box my={2}>
        <Typography variant="h6" m={1} mb={2}>
          {FeatureCategories.Extracurricular}
        </Typography>
        <FeatureListBox features={featureViewData.Extracurricular || []} featureWidth="50%" />
      </Box>
    </Stack>
  );
};
