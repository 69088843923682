import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo, useState } from 'react';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';
import { canUser } from 'utils/userRoles';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { AuthUser } from 'types/user.interface';
import { Permissions } from 'types/permissions.enum';

import { FilterParams } from './filter-params.interface';
import { FilterTypes } from './filter-types.enum';
import { AcceptsAgeFilter } from './filters/AcceptsAgeFilter';
import { AcceptsGradeFilter } from './filters/AcceptsGradeFilter';
import { AdvancedSubjectsFilter } from './filters/AdvancedSubjectsFilter';
import { CityFilter } from './filters/CityFilter';
import { CoedStatusFilter } from './filters/CoedStatusFilter';
import { CustomAttributeFilter } from './filters/CustomAttributeFilter';
import { DayOrBoardingFilter } from './filters/DayOrBoardingFilter';
import { EsolSupportFilter } from './filters/EsolSupportFilter';
import { LocationFilter, LocationTriple } from './filters/LocationFilter';
import { ProgramAvailabilityFilter } from './filters/ProgramAvailabilityFilter';
import { ProgramPricingFilter } from './filters/ProgramPricingFilter';
import { PublicOrPrivateFilter } from './filters/PublicOrPrivate';
import { SportsFilter } from './filters/SportsFilter';
import { SubjectsFilter } from './filters/SubjectsFilter';
import { UsDiplomaFilter } from './filters/UsDiplomaFilter';
import { getInitialFiltersFromParams } from './get-initial-filters-from-params';
import { IntlCurriculumFilter } from './filters/IntlCurriculumFilter';

export interface FiltersSelectorPanelProps {
  onClose: () => void;
}

export const FiltersSelectorPanel = ({ onClose }: FiltersSelectorPanelProps) => {
  const {
    state: { filters: values },
    updateFilters: onChange,
  } = useFilters();
  const { user } = useAuth0<AuthUser>();
  const activeFilters = getInitialFiltersFromParams(values);

  const [getFilterOptionsRes] = useAsync<string[]>('/profile-filters');
  const [newFilter, setNewFilter] = useState('');

  // Avoid looping repeatedly through the response array
  const filterOptions = useMemo(() => new Set(getFilterOptionsRes || []), [getFilterOptionsRes]);

  // Filter options which are already active should be hidden
  const inactiveFilterSelectorOptions = useMemo(() => {
    const inactiveOptions = new Set(filterOptions);
    activeFilters.forEach((filter) => inactiveOptions.delete(filter));

    // Permissions checks
    if (!canUser(Permissions.VIEW_PRICING, user)) {
      inactiveOptions.delete(FilterTypes.ProgramPricing);
    }

    return inactiveOptions;
  }, [user, filterOptions, activeFilters]);

  // Hide the filter selector altogether while all the options are active and there are no more available to select
  const showFilterSelector = inactiveFilterSelectorOptions.size > 0;

  const location: LocationTriple = {
    country: values.country || '',
    state: values.state || '',
    city: values.city || '',
  };

  const onDiscard = (fields: (keyof FilterParams)[]) => {
    const temp = { ...values };
    fields.forEach((field) => {
      delete temp[field];
    });
    onChange(temp);
  };

  const resolveFilter = (filterType: FilterTypes) => {
    switch (filterType) {
      case FilterTypes.CoedStatus:
        return (
          <CoedStatusFilter
            key={FilterTypes.CoedStatus}
            value={values.coedStatus || CoedStatuses.CoEd}
            onChange={(coedStatus) => onChange({ ...values, coedStatus })}
            onDiscard={() => onDiscard(['coedStatus'])}
          />
        );
      case FilterTypes.Location:
        return (
          <LocationFilter
            key={FilterTypes.Location}
            value={location}
            onChange={(newLocation: LocationTriple) => onChange({ ...values, ...newLocation })}
            onDiscard={() => onDiscard(['country', 'state', 'city'])}
          />
        );
      case FilterTypes.City:
        return (
          <CityFilter
            key={FilterTypes.City}
            value={values.city || ''}
            onChange={(city) => onChange({ ...values, city })}
            onDiscard={() => onDiscard(['city'])}
          />
        );
      case FilterTypes.ProgramPricing:
        return (
          <ProgramPricingFilter
            key={FilterTypes.ProgramPricing}
            pricingProgramPeriod={(values.pricingProgramPeriod || '') as StudyProgramPeriodType}
            pricingMinUsd={(values.pricingMinUsd || '') as StudyProgramPeriod['basePriceUsd']}
            pricingMaxUsd={(values.pricingMaxUsd || '') as StudyProgramPeriod['basePriceUsd']}
            onProgramPeriodChange={(pricingProgramPeriod: StudyProgramPeriodType) => onChange({ ...values, pricingProgramPeriod })}
            onMinUsdChange={(pricingMinUsd: StudyProgramPeriod['basePriceUsd']) => onChange({ ...values, pricingMinUsd })}
            onMaxUsdChange={(pricingMaxUsd: StudyProgramPeriod['basePriceUsd']) => onChange({ ...values, pricingMaxUsd })}
            onDiscard={() => onDiscard(['pricingProgramPeriod', 'pricingMinUsd', 'pricingMaxUsd'])}
          />
        );
      case FilterTypes.ProgramAvailability:
        return (
          <ProgramAvailabilityFilter
            key={FilterTypes.ProgramAvailability}
            value={values.programAvailability}
            onChange={(programAvailability) => onChange({ ...values, programAvailability })}
            onDiscard={() => onDiscard(['programAvailability'])}
          />
        );
      case FilterTypes.PublicOrPrivate:
        return (
          <PublicOrPrivateFilter
            key={FilterTypes.PublicOrPrivate}
            value={values.isPrivateSchool || 'false'}
            onChange={(isPrivateSchool) => onChange({ ...values, isPrivateSchool })}
            onDiscard={() => onDiscard(['isPrivateSchool'])}
          />
        );
      case FilterTypes.EsolSupport:
        return (
          <EsolSupportFilter
            key={FilterTypes.EsolSupport}
            value={values.hasEsolSupport}
            onChange={(hasEsolSupport) => onChange({ ...values, hasEsolSupport })}
            onDiscard={() => onDiscard(['hasEsolSupport'])}
          />
        );
      case FilterTypes.UsDiploma:
        return (
          <UsDiplomaFilter
            key={FilterTypes.UsDiploma}
            value={values.hasUsDiploma}
            onChange={(hasUsDiploma) => onChange({ ...values, hasUsDiploma })}
            onDiscard={() => onDiscard(['hasUsDiploma'])}
          />
        );
      case FilterTypes.DayOrBoarding:
        return (
          <DayOrBoardingFilter
            key={FilterTypes.DayOrBoarding}
            value={values.dayOrBoarding}
            onChange={(dayOrBoarding) => onChange({ ...values, dayOrBoarding })}
            onDiscard={() => onDiscard(['dayOrBoarding'])}
          />
        );
      case FilterTypes.CustomAttribute:
        return (
          <CustomAttributeFilter
            key={FilterTypes.CustomAttribute}
            value={values.customAttribute || ''}
            onChange={(customAttribute) => onChange({ ...values, customAttribute })}
            onDiscard={() => onDiscard(['customAttribute'])}
          />
        );
      case FilterTypes.AcceptsAge:
        return (
          <AcceptsAgeFilter
            key={FilterTypes.AcceptsAge}
            value={values.acceptsAge || undefined}
            onChange={(acceptsAge) => onChange({ ...values, acceptsAge })}
            onDiscard={() => onDiscard(['acceptsAge'])}
          />
        );
      case FilterTypes.AcceptsGrade:
        return (
          <AcceptsGradeFilter
            key={FilterTypes.AcceptsGrade}
            value={values.acceptsGrade || undefined}
            onChange={(acceptsGrade) => onChange({ ...values, acceptsGrade })}
            onDiscard={() => onDiscard(['acceptsGrade'])}
          />
        );
      case FilterTypes.Subjects:
        return (
          <SubjectsFilter
            key={FilterTypes.Subjects}
            value={{ subjects: values.subjects, hasAllSubjects: values.hasAllSubjects } || []}
            onChange={(tuple) => onChange({ ...values, subjects: tuple.subjects, hasAllSubjects: tuple.hasAllSubjects })}
            onDiscard={() => onDiscard(['subjects', 'hasAllSubjects'])}
          />
        );
      case FilterTypes.AdvancedSubjects:
        return (
          <AdvancedSubjectsFilter
            key={FilterTypes.AdvancedSubjects}
            value={{ advancedSubjects: values.advancedSubjects, hasAllAdvancedSubjects: values.hasAllAdvancedSubjects } || []}
            onChange={(tuple) => onChange({ ...values, advancedSubjects: tuple.advancedSubjects, hasAllAdvancedSubjects: tuple.hasAllAdvancedSubjects })}
            onDiscard={() => onDiscard(['advancedSubjects', 'hasAllAdvancedSubjects'])}
          />
        );
      case FilterTypes.Sports:
        return (
          <SportsFilter
            key={FilterTypes.Sports}
            value={{ sports: values.sports, hasAllSports: values.hasAllSports } || []}
            onChange={(tuple) => onChange({ ...values, sports: tuple.sports, hasAllSports: tuple.hasAllSports })}
            onDiscard={() => onDiscard(['sports', 'hasAllSports'])}
          />
        );
      case FilterTypes.InternationalCurriculum:
        return (
          <IntlCurriculumFilter
            key={FilterTypes.InternationalCurriculum}
            value={values.intlCurriculum || ''}
            onChange={(intlCurriculum) => onChange({ ...values, intlCurriculum })}
            onDiscard={() => onDiscard(['intlCurriculum'])}
          />
        );
      default:
        return <></>;
    }
  };

  const addActiveFilter = (filter: FilterTypes) => {
    // Set defaults for new filters where the input's default value is not empty
    switch (filter) {
      case FilterTypes.CoedStatus:
        onChange({ ...values, coedStatus: CoedStatuses.CoEd });
        break;
      case FilterTypes.PublicOrPrivate:
        onChange({ ...values, isPrivateSchool: 'false' });
        break;
      case FilterTypes.DayOrBoarding:
        onChange({ ...values, dayOrBoarding: 'day' });
        break;
      case FilterTypes.EsolSupport:
        onChange({ ...values, hasEsolSupport: 'true' });
        break;
      case FilterTypes.UsDiploma:
        onChange({ ...values, hasUsDiploma: 'true' });
        break;
      case FilterTypes.ProgramAvailability:
        onChange({ ...values, programAvailability: '' });
        break;
      case FilterTypes.Sports:
        onChange({ ...values, sports: [] });
        break;
      case FilterTypes.Subjects:
        onChange({ ...values, subjects: [] });
        break;
      case FilterTypes.AdvancedSubjects:
        onChange({ ...values, advancedSubjects: [] });
        break;
      case FilterTypes.Location:
        onChange({ ...values, country: undefined, state: undefined, city: undefined });
        break;
      case FilterTypes.ProgramPricing:
        onChange({ ...values, pricingProgramPeriod: undefined, pricingMinUsd: undefined, pricingMaxUsd: undefined });
        break;
      case FilterTypes.InternationalCurriculum:
        onChange({ ...values, intlCurriculum: '' });
        break;
      default:
        onChange({ ...values, [filter]: undefined });
    }
    setNewFilter('');
  };

  return (
    <Box px={3} py={2} sx={{ background: 'rgba(255, 255, 255, 1)', height: '100%' }}>
      <Stack direction="row">
        <Typography variant="h6" fontWeight="600" mb={2}>
          Filters
        </Typography>
        <Box flex={1} />
        <Box>
          <IconButton aria-label="discard" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
      {/* Enable for realtime inline filter debugging */}
      {/* <pre>{JSON.stringify(values)}</pre> */}
      {activeFilters.map(resolveFilter)}
      {showFilterSelector && (
        <Select
          fullWidth
          sx={{ mt: 1, width: '320px' }}
          size="small"
          value={newFilter}
          onChange={(event) => addActiveFilter(event.target.value as FilterTypes)}
          displayEmpty
        >
          <MenuItem disabled value="" sx={{ display: 'none' }}>
            Select filter
          </MenuItem>
          {/* These should be kept in alphabetical order by label */}
          {inactiveFilterSelectorOptions.has(FilterTypes.AcceptsAge) && <MenuItem value={FilterTypes.AcceptsAge}>Accepts Age</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.AcceptsGrade) && <MenuItem value={FilterTypes.AcceptsGrade}>Accepts Grade</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.AdvancedSubjects) && <MenuItem value={FilterTypes.AdvancedSubjects}>Advanced courses (AP)</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.City) && <MenuItem value={FilterTypes.City}>City</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.CoedStatus) && <MenuItem value={FilterTypes.CoedStatus}>Coeducation status</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.DayOrBoarding) && <MenuItem value={FilterTypes.DayOrBoarding}>Day or Boarding</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.EsolSupport) && <MenuItem value={FilterTypes.EsolSupport}>ESOL Support</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.InternationalCurriculum) && (
            <MenuItem value={FilterTypes.InternationalCurriculum}>International Curriculum</MenuItem>
          )}
          {inactiveFilterSelectorOptions.has(FilterTypes.Location) && <MenuItem value={FilterTypes.Location}>Location</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.ProgramAvailability) && (
            <MenuItem value={FilterTypes.ProgramAvailability}>Program Availability</MenuItem>
          )}
          {inactiveFilterSelectorOptions.has(FilterTypes.ProgramPricing) && <MenuItem value={FilterTypes.ProgramPricing}>Program type &amp; price</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.PublicOrPrivate) && <MenuItem value={FilterTypes.PublicOrPrivate}>Public/Private</MenuItem>}
          {/* TODO: Custom Attribute innerhtml should be pulled from domain custom attribute name if expanded beyond just VDET. */}
          {inactiveFilterSelectorOptions.has(FilterTypes.CustomAttribute) && <MenuItem value={FilterTypes.CustomAttribute}>Region</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.Sports) && <MenuItem value={FilterTypes.Sports}>Sports</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.Subjects) && <MenuItem value={FilterTypes.Subjects}>Subjects</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.UsDiploma) && <MenuItem value={FilterTypes.UsDiploma}>US Diploma</MenuItem>}
        </Select>
      )}
      {/* TODO: Put this somewhere else once designed */}
      {/* <Box flexGrow={1} /> */}
      {/* {isIframed && <img src="powered-by-enroller.png" alt="Powered by Enroller" />} */}
    </Box>
  );
};
