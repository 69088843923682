import { Box, Typography, useTheme } from '@mui/material';
import { branding } from 'app/branding';
import { usePageTitle } from 'app/PageTitleContext';
import { useIsIframed } from 'hooks/use-is-iframed';
import { useMountEffect } from 'hooks/use-mount-effect';
import { useLayoutEffect, useRef, useState } from 'react';

import { FiltersProvider } from './filtering/FiltersContext';
import { FilterDrawerStateProvider } from './FilterDrawerStateContext';
import { ProfileSummariesProvider } from './ProfileSummaryCardGrid/ProfileSummariesContext';
import { ProfileSearchHeader } from './ProfileSearchHeader';
import { MapViewOpenStateProvider } from './MapViewOpenStateContext';
import { ProfileResultsSection } from './ProfileResultsSection';

export const ProviderList = () => {
  const theme = useTheme();
  const isIframed = useIsIframed();

  const { setPageTitle } = usePageTitle();

  useMountEffect(() => setPageTitle('Browse schools'));

  const headerRef = useRef<HTMLElement>();
  const [headerHeight, setHeaderHeight] = useState(0);
  const appBarHeight = parseInt(theme.spacing(branding.headerHeight).replace('px', ''));

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight((isIframed ? 0 : appBarHeight) + headerRef.current.clientHeight);
    }
  }, [headerRef, isIframed, appBarHeight]);

  const resultsBoxHeight = `calc(100vh - ${headerHeight}px)`;

  return (
    <FiltersProvider>
      <FilterDrawerStateProvider>
        <MapViewOpenStateProvider>
          <ProfileSummariesProvider>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box ref={headerRef} sx={{ maxHeight: '120px', px: 4, py: 3, backgroundColor: 'background.paper' }}>
                <Typography display="inline" variant="h6" fontWeight="600" sx={theme.mixins.titleGradient}>
                  Browse schools
                </Typography>
                <br />
                <ProfileSearchHeader height={headerHeight} />
              </Box>
              <ProfileResultsSection height={resultsBoxHeight} />
            </Box>
          </ProfileSummariesProvider>
        </MapViewOpenStateProvider>
      </FilterDrawerStateProvider>
    </FiltersProvider>
  );
};
