export enum StudyProgramPeriodType {
  AcademicYear = 'ayp',
  Semester = 'sem',
  CalendarYear = 'cyp',
  ThreeSemester = 'sem3',
  Term = 'term',
}

export const StudyProgramPeriodTypeNames = {
  [StudyProgramPeriodType.CalendarYear]: 'Calendar year',
  [StudyProgramPeriodType.AcademicYear]: 'Academic year',
  [StudyProgramPeriodType.Semester]: 'Semester',
  [StudyProgramPeriodType.ThreeSemester]: 'Three semester',
  [StudyProgramPeriodType.Term]: 'Term',
};
