import DownloadOutlined from '@mui/icons-material/DownloadOutlined';
import { IconButton, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosRequestConfig } from 'axios';
import { useAsync } from 'hooks/use-async';
import fileDownload from 'js-file-download';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';

interface DownloadButtonProps {
  file: IFile;
}

export const DownloadButton = ({ file }: DownloadButtonProps) => {
  const { profileSlug } = useParams();

  const downloadUrl = `/profiles/${profileSlug}/files/${file.id}/download`;
  const extraAxiosConfig: Partial<AxiosRequestConfig> = { responseType: 'blob' };
  const [res, status, startDownload] = useAsync(downloadUrl, 'GET', false, extraAxiosConfig);

  useEffect(() => {
    if (status === 'success' && res) {
      fileDownload(res, file.filename);
    }
  }, [status, res, file.filename, profileSlug]);

  return (
    <Tooltip title="Download">
      <span>
        <IconButton aria-label="download" disabled={status === 'pending'} onClick={() => startDownload()}>
          {status === 'pending' ? <CircularProgress size="24px" /> : <DownloadOutlined />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
