import { Box, Paper } from '@mui/material';
import { useWindowSize } from 'hooks/use-window-size';
import { useState, useMemo } from 'react';

import { InteractiveProfileClusterMap } from './InteractiveProfileClusterMap';
import { ProfileCoordsProvider } from './ProfileCoordsContext';

export const ProfileClustersMap = () => {
  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement>();
  const size = useWindowSize();

  const [clientWidth, clientHeight] = useMemo(() => {
    if (!size) return [0, 0];
    const parentWidth = mapContainerEl ? mapContainerEl.clientWidth + 'px' : '50vw';
    const parentHeight = mapContainerEl ? mapContainerEl.clientHeight + 'px' : '85vh';
    return [parentWidth, parentHeight];
  }, [mapContainerEl, size]);

  return (
    <ProfileCoordsProvider>
      {/* calc 100% minus 64px to account for my: '32px' */}
      <Paper sx={{ minWidth: '50%', my: '32px', ml: '32px', height: 'calc(100% - 64px)' }}>
        <Box ref={(el: any) => setMapContainerEl(el)} sx={{ height: '100%' }}>
          <InteractiveProfileClusterMap width={clientWidth} height={clientHeight} />
        </Box>
      </Paper>
    </ProfileCoordsProvider>
  );
};
