import { Card, FormControl, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

interface ProgramAvailabilityFilterProps {
  value: string | undefined;
  onChange: (programAvailability: string) => void;
  onDiscard: () => void;
}

export const ProgramAvailabilityFilter = ({ value, onChange, onDiscard }: ProgramAvailabilityFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Program Availability" onDiscard={onDiscard} />
      <Stack direction="row" alignItems="center">
        <Typography sx={{ minWidth: 200 }} fontWeight={700}>
          Has spaces available for:
        </Typography>
        <FormControl fullWidth>
          <Select id="availability-filter-select" value={value} onChange={(event) => onChange(event.target.value)} size="small" displayEmpty>
            <MenuItem disabled value="" sx={{ display: 'none' }}>
              Select...
            </MenuItem>
            <MenuItem value="spring2025">Spring 2025</MenuItem>
            <MenuItem value="fall2025">Fall 2025</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </Card>
  );
};
