import { Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

interface CoedStatusFilterProps {
  value: CoedStatuses;
  onChange: (newCoedStatus: CoedStatuses) => void;
  onDiscard: () => void;
}

export const CoedStatusFilter = ({ value, onChange, onDiscard }: CoedStatusFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Coeducation Status" onDiscard={onDiscard} />
      <FormControl fullWidth>
        <InputLabel id="coed-filter-select-label">Select status</InputLabel>
        <Select
          labelId="coed-filter-select-label"
          id="coed-filter-select"
          label="Select status"
          value={value}
          onChange={(event) => onChange(event.target.value as CoedStatuses)}
        >
          <MenuItem value={CoedStatuses.CoEd}>Coeducational</MenuItem>
          <MenuItem value={CoedStatuses.Male}>Boys only</MenuItem>
          <MenuItem value={CoedStatuses.Female}>Girls only</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
