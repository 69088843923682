import { useAuth0 } from '@auth0/auth0-react';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, FormControl, MenuItem, Modal, Select, Typography, useTheme } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo, useState } from 'react';
import { Country } from 'types/country.interface';

export const PrimaryMarketModal = () => {
  const [primaryMarketCountry, setPrimaryMarketCountry] = useState<string>('');
  const [isDone, setIsDone] = useState<boolean>(false);

  const [, saveStatus, executeSave] = useAsync<{ primaryMarketCountry: string }>('/user', 'POST', false);
  const [countries] = useAsync<Country[]>(`/countries`);
  const countryOptions = useMemo(() => countries || [], [countries]);
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();

  const onSubmit = async () => {
    await executeSave({ primaryMarketCountry });

    // re-fetch token in the background to update user metadata
    await getAccessTokenSilently({ cacheMode: 'off' });
    setIsDone(true);
  };

  return isDone ? (
    <></>
  ) : (
    <>
      <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />
      <Modal open={true} aria-labelledby="primary-market-modal-title" aria-describedby="primary-market-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Box>
            <Avatar sx={{ mb: 1, bgcolor: 'primary.light' }}>
              <CampaignOutlinedIcon sx={{ color: 'primary.dark' }} />
            </Avatar>
            <Typography id="primary-market-modal-title" variant="h6" component="h2">
              System update
            </Typography>
            <Typography id="primary-market-modal-description" sx={{ mb: 3, mt: 2 }}>
              We've made a few changes to our system and need some information from you
            </Typography>
            <Typography variant="body2" fontWeight="600">
              Which country do you primarily support students from? <span style={{ color: 'red' }}>*</span>
            </Typography>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Select size="small" value={primaryMarketCountry} onChange={(event) => setPrimaryMarketCountry(event.target.value)} displayEmpty>
                <MenuItem disabled value="" sx={{ display: 'none' }}>
                  Select country
                </MenuItem>
                {countryOptions.map((c) => (
                  <MenuItem key={c.iso_3166_3} value={c.iso_3166_3}>
                    {/* TODO: Country flags once refactored into hook */}
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="body2">This should represent the most common nationality of your students</Typography>
            </FormControl>
            <FormControl fullWidth>
              <LoadingButton disabled={!primaryMarketCountry} loading={saveStatus === 'pending'} variant="contained" onClick={onSubmit} endIcon={<SaveIcon />}>
                Save
              </LoadingButton>
            </FormControl>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
