import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CharactersRemaining } from 'components/CharactersRemaining';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, useFieldArray, useFormState, useWatch } from 'react-hook-form';
import { StudyProgramPeriodType, StudyProgramPeriodTypeNames } from 'types/study-program-period-type.enum';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

import { StudyProgramPeriodSection } from './StudyProgramPeriodSection';

export interface StudyProgramsSectionProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

const NAME_CHAR_LIMIT = 100;

export const StudyProgramsSection = ({ control, errors }: StudyProgramsSectionProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'studyPrograms',
  });
  const watchFieldArray = useWatch({ control, name: 'studyPrograms' });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const { isSubmitting } = useFormState({ control });
  const [expanded, setExpanded] = useState<boolean[]>(Array(controlledFields.length).fill(false));

  // Errors on save expand errored periods
  // TODO: Check studyProgramPeriods for errors too?
  useEffect(() => {
    if (isSubmitting) {
      setExpanded((exp) => {
        if (errors && errors.studyPrograms && Array.isArray(errors.studyPrograms)) {
          const temp = [...exp];
          errors.studyPrograms.forEach((e, index) => {
            if (e && (e.name || e.quote)) {
              temp[index] = true;
            }
          });
          return temp;
        }
        return exp;
      });
    }
  }, [errors, setExpanded, isSubmitting]);

  const toggleExpand = (index: number, state: boolean) => {
    const temp = [...expanded];
    temp[index] = state;
    setExpanded(temp);
  };

  const addProgram = () => {
    append({ name: '', periodType: StudyProgramPeriodType.CalendarYear, studyProgramPeriods: [] });
    const temp = [...expanded];
    temp.push(true);
    setExpanded(temp);
  };

  const removeProgram = (index: number) => {
    remove(index);
    const temp = [...expanded];
    temp.splice(index, 1);
    setExpanded(temp);
  };

  const theme = useTheme();

  return (
    <section id="study-programs-section">
      <Divider sx={{ my: 2 }}>Programs offered</Divider>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" sx={{ py: 2, fontWeight: 500, mb: 1.5 }}>
          Add programs and tuition costs
        </Typography>
      </Stack>
      <Alert
        severity="info"
        color="info"
        sx={{
          mb: 2,
          '& .MuiAlert-icon': {
            color: theme.palette.secondary.dark,
          },
        }}
      >
        <AlertTitle>Provide potential students with accurate pricing and dates</AlertTitle>
        <Typography>This section is to provide students clarity on important dates and cost of tuition.</Typography>
      </Alert>
      <Box>
        {controlledFields.map((field, index) => (
          <Box key={index}>
            {/* outer Box needed to avoid Accordion group styling */}
            <Accordion
              key={index}
              expanded={expanded[index]}
              onChange={(_, exp) => toggleExpand(index, exp)}
              sx={{ mb: 2, [`&.${accordionClasses.expanded}:last-of-type`]: { mb: 2 } }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={500}>
                  {watchFieldArray[index]?.name && `${watchFieldArray[index]?.name}`} &nbsp;
                  {`(${StudyProgramPeriodTypeNames[watchFieldArray[index]?.periodType]} Program)`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" gap={3}>
                  <Controller
                    name={`studyPrograms.${index}.name`}
                    control={control}
                    rules={{
                      required: 'Program name is required',
                      maxLength: { value: NAME_CHAR_LIMIT, message: 'Program name is too long' },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Box sx={{ mb: 2, width: '100%' }}>
                        <FormControl fullWidth required>
                          <FormLabel id={`program-${index}-name-input-label`} required>
                            Program name
                          </FormLabel>
                          <TextField
                            size="small"
                            aria-labelledby={`program-${index}-name-input-label`}
                            value={value}
                            onChange={onChange}
                            error={Boolean(errors.studyPrograms?.[index]?.name)}
                          />
                        </FormControl>
                        <CharactersRemaining limit={NAME_CHAR_LIMIT} value={value} />
                        {errors.studyPrograms?.[index]?.name ? <Typography color="error">{errors.studyPrograms?.[index]?.name?.message}</Typography> : <br />}
                      </Box>
                    )}
                  />
                  <Controller
                    name={`studyPrograms.${index}.periodType`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl fullWidth required>
                        <FormLabel id={`program-${index}-period-type-input-label`}>Period Type</FormLabel>
                        <Select
                          aria-labelledby={`program-${index}-period-type-input-label`}
                          value={value || StudyProgramPeriodType.CalendarYear}
                          size="small"
                          onChange={onChange}
                          sx={{ mb: 2 }}
                        >
                          <MenuItem value={StudyProgramPeriodType.CalendarYear}>Calendar Year</MenuItem>
                          <MenuItem value={StudyProgramPeriodType.AcademicYear}>Academic Year</MenuItem>
                          <MenuItem value={StudyProgramPeriodType.Semester}>Semester</MenuItem>
                          <MenuItem value={StudyProgramPeriodType.ThreeSemester}>Three Semester</MenuItem>
                          <MenuItem value={StudyProgramPeriodType.Term}>Term</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  />
                  <Box width="100%" />
                </Stack>
                <StudyProgramPeriodSection studyProgramIndex={index} control={control} errors={errors} />
                <Stack direction="row-reverse">
                  <Button size="large" variant="outlined" onClick={() => removeProgram(index)} color="error" endIcon={<DeleteOutlinedIcon />}>
                    Delete program
                  </Button>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
        <Button variant="outlined" color="info" onClick={addProgram} endIcon={<AddIcon />}>
          Add program
        </Button>
      </Box>
    </section>
  );
};
