import { CountryGrades } from '../types/country-grades.interface';
import { Grade } from '../types/grade.interface';

const countryGradingSystems: { [id: string]: CountryGrades } = {
  'United States': {
    country: 'United States',
    prefix: 'Grade',
    equivalentAgeOffset: 5,
    minGrade: 0,
    maxGrade: 13,
    gradeNameOverrides: {
      0: 'Kindergarten',
      13: 'Postgraduate',
    },
  },
  'United Kingdom': {
    country: 'United Kingdom',
    prefix: 'Year',
    equivalentAgeOffset: 5,
    minGrade: 0,
    maxGrade: 13,
    gradeNameOverrides: {},
  },
  Australia: {
    country: 'Australia',
    prefix: 'Year',
    equivalentAgeOffset: 5,
    minGrade: 0,
    maxGrade: 12,
    gradeNameOverrides: {
      0: 'Kindergarten',
    },
  },
  'New Zealand': {
    country: 'New Zealand',
    prefix: 'Year',
    equivalentAgeOffset: 5,
    minGrade: 0,
    maxGrade: 13,
    gradeNameOverrides: {},
  },
  China: {
    country: 'China',
    prefix: 'Grade',
    equivalentAgeOffset: 5,
    minGrade: 0,
    maxGrade: 13,
    gradeNameOverrides: {
      0: 'Kindergarten',
      13: 'Postgraduate',
    },
  },
};

export const getCountryGradingSystem = (country: string) => countryGradingSystems[country] || countryGradingSystems['United States'];

export const getCountryGrades = (country: string) => {
  const gradingSystem = getCountryGradingSystem(country);
  const grades: Grade[] = [];
  for (let grade = gradingSystem.minGrade; grade <= gradingSystem.maxGrade; grade++) {
    const name = gradingSystem.gradeNameOverrides[grade] || `${gradingSystem?.prefix} ${grade}`;
    grades.push({ equivalentAge: grade + gradingSystem.equivalentAgeOffset, name });
  }
  return grades;
};
