import { Avatar, Box, ImageList, ImageListItem, Paper, Typography, Stack } from '@mui/material';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ImageEditOptions, getOptimisedImageUrl } from 'utils/getOptimisedImageUrl';
import { ImageWithSkeleton } from 'components/ImageWithSkeleton';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { PdfDownloadButton } from './ProfileHeaderActions/PdfDownloadButton';
import { ShareButton } from './ProfileHeaderActions/ShareButton';
import { InquireButton } from './ProfileHeaderActions/InquireButton';
import { ApplyButton } from './ProfileHeaderActions/ApplyButton';

export const SchoolProfileHeader = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const files = profile.files || [];
  const logo = files.find((file) => file.role === FileRoles.FILE_ROLE_MARKETING_LOGO);
  const photos = files.filter((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_PHOTOS);
  const banner = files.find((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_BANNER) || photos[0];
  const imageEditOptions: ImageEditOptions = {
    resize: { width: 1200, fit: 'cover' },
  };
  const bannerSrc = banner ? getOptimisedImageUrl(banner.fileUrl!, imageEditOptions) : '';

  const isPdfFormat = useIsPdfFormat();

  const shouldRenderActionButtons: boolean = !isSharedView && !isPdfFormat;

  return (
    <Paper sx={{ mb: 1, mt: 1 }}>
      <Box display="grid" gridTemplateRows="289px 51px 40px 20px" gridTemplateColumns="20px 91px 1fr">
        <Box gridArea="1 / 1 / 5 / 4">
          <ImageList cols={1} rowHeight={400} sx={{ mt: 0 }} component={Box} borderRadius="6px 6px 0 0">
            <ImageListItem>
              <ImageWithSkeleton src={bannerSrc} alt="banner" style={{ objectFit: 'cover', height: 'inherit' }} width="100%" height="100%" />
            </ImageListItem>
          </ImageList>
        </Box>
        <Box gridArea="2 / 2 / 4 / 3" borderRadius="8px" bgcolor="white" p="5px" zIndex={2}>
          <Avatar
            src={logo?.fileUrl}
            alt={profile.name}
            sx={{ height: 81, width: 'auto', minWidth: 81 }}
            imgProps={{ style: { width: 'auto', maxWidth: 130, objectFit: 'contain' } }}
            variant="square"
          >
            <AccountBalanceIcon sx={{ width: '55px', height: '55px' }} />
          </Avatar>
        </Box>
        <Box gridArea="3 / 1 / 5 / 4" bgcolor="rgb(0, 0, 0, 80%)" zIndex={1}>
          <Stack pl="130px" pr={2} height={60} spacing={2} direction="row" alignItems="center">
            <Typography variant="h4" fontWeight={700} fontSize="20px" sx={{ color: '#fff' }}>
              {profile.name}
            </Typography>
            <Box flex={1} />
            {shouldRenderActionButtons && (
              <>
                <PdfDownloadButton />
                <ShareButton />
                <InquireButton />
                <ApplyButton />
              </>
            )}
          </Stack>
        </Box>
      </Box>
    </Paper>
  );
};
