import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, Button, Grid, MenuItem, Pagination, Select, Stack, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { Country } from 'types/country.interface';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { useFilterDrawerState } from 'app/marketing/ProviderList/FilterDrawerStateContext';
import { Footer } from 'components/Footer';
import { useIsIframed } from 'hooks/use-is-iframed';

import { ProfileCard } from './ProfileCard';
import { useProfileSummaries } from './ProfileSummariesContext';

interface ProfileSummaryCardGridProps {
  halfWidth?: boolean;
}

export const ProfileSummaryCardGrid = ({ halfWidth }: ProfileSummaryCardGridProps) => {
  const {
    state: { filters: filterParams, pagination },
    updatePageNumber,
    updatePagination,
  } = useFilters();
  const { profileSummariesResult, lastTotal } = useProfileSummaries();

  const [countries] = useAsync<Country[]>('/countries');
  const countryFlagSrcMap = useMemo(
    () =>
      countries?.reduce((acc, country) => {
        acc.set(country.name, `/flag-icons/${country.iso_3166_3}.svg`);
        return acc;
      }, new Map<string, string>()),
    [countries],
  );

  const { setIsOpen: setIsFilterDrawerOpen } = useFilterDrawerState();
  const isIframed = useIsIframed();

  const onPageSizeChange = (pageSize: number) => {
    updatePagination({ ...pagination, pageSize });
  };

  const filteredProfiles = profileSummariesResult?.data || Array(pagination.pageSize).fill(null);
  const totalPageCount = Math.ceil(lastTotal / pagination.pageSize);

  const gridLayout = {
    sm: 12,
    md: halfWidth ? 12 : 6,
    lg: halfWidth ? 12 : 4,
    xl: halfWidth ? 6 : 3,
  };

  return filteredProfiles.length > 0 ? (
    <Stack direction="column" height="100%" width="100%" sx={{ overflowY: 'scroll' }} data-intercom-target="profile cards">
      <Stack direction="column" height="100%" width="100%" sx={{ pt: 2 }}>
        <Grid container spacing={4} alignItems="stretch" sx={{ pt: 1.5, px: 4 }}>
          {filteredProfiles.map((profile, i) => (
            <Grid item key={profile?.id || i} {...gridLayout} width="100%">
              <ProfileCard filterParams={filterParams} profile={profile} flagSrc={countryFlagSrcMap?.get(profile?.country) || null} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 4, pb: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Select
            value={pagination.pageSize}
            SelectDisplayProps={{ style: { padding: '5px 30px 5px 10px' } }}
            onChange={(e) => onPageSizeChange(e.target.value as number)}
          >
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={24}>24</MenuItem>
            <MenuItem value={36}>36</MenuItem>
            <MenuItem value={60}>60</MenuItem>
          </Select>

          <Pagination count={totalPageCount} sx={{ zIndex: 500 }} page={pagination.pageNumber} onChange={(_, v) => updatePageNumber(v)} />
        </Box>
        {/* keep footer at the bottom of page */}
        <Box flex="1"></Box>
        {!isIframed && !halfWidth && <Footer />}
      </Stack>
    </Stack>
  ) : (
    <Stack direction="column" width="100%" height="100%">
      <Box flex={1} />
      <Stack direction="column" alignItems="center" justifyContent="center">
        <Avatar sx={{ mb: 2, backgroundColor: 'primary.light', height: '72px', width: '72px' }}>
          <SearchIcon sx={{ color: 'primary.dark', height: '48px', width: '48px' }} />
        </Avatar>
        <Typography variant="h6" fontWeight="500" mb={1}>
          No schools found
        </Typography>
        <Typography mb={2} maxWidth={400} textAlign="center" color="secondary">
          The filters used did not match any schools. Please change filter criteria.
        </Typography>
        <Button
          variant="contained"
          endIcon={<Icon path={mdiFilter} size={1} />}
          sx={{
            mr: 1,
            backgroundColor: 'primary.light',
            color: 'primary.dark',
            fontSize: 20,
            '&:hover': {
              backgroundColor: 'primary.light',
            },
          }}
          onClick={() => setIsFilterDrawerOpen(true)}
        >
          Open filters
        </Button>
      </Stack>
      <Box flex={1} />
      {!isIframed && !halfWidth && <Footer />}
    </Stack>
  );
};
