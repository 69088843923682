import { InputAdornment, inputAdornmentClasses, outlinedInputClasses, styled, TextField } from '@mui/material';

interface AdornmentProps {
  maxRange: string;
  showSlash?: boolean;
}

export const AdornmentTextField = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.root}`]: {
    paddingRight: 0,
  },
  [`& .${inputAdornmentClasses.root}`]: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    padding: '19px 14px',
  },
}));

export const Adornment = ({ maxRange, showSlash = true }: AdornmentProps) => {
  return (
    <InputAdornment position="end">
      {showSlash && '/'} {maxRange}
    </InputAdornment>
  );
};
