import ReactGA from 'react-ga4';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import { FeatureCollection, GeoJsonProperties, Geometry, Point } from 'geojson';
import { canUserEditMarketingProfile } from 'utils/userRoles';
import { AuthUser } from 'types/user.interface';
import { getOptimisedImageUrl } from 'utils/getOptimisedImageUrl';

import { markerVisibilityZoom } from './map-source';
import { addMapUnclusteredPointsLayer } from './map-layers';

type mapboxClusterClickEvent = mapboxgl.MapMouseEvent & {
  features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
} & mapboxgl.EventData;

const markers: mapboxgl.Marker[] = [];

export const addMapEventHandlers = (
  map: mapboxgl.Map,
  profileCoords: FeatureCollection<Geometry, GeoJsonProperties>,
  user: AuthUser | undefined,
  themeColor: string,
  updateGeoBounds: (geoBounds: string) => void,
) => {
  map.on('click', 'clusters', zoomToCluster(map));
  map.on('click', 'unclustered-point', zoomToUnclusteredPoint(map));
  map.on('mouseenter', 'clusters', () => (map.getCanvas().style.cursor = 'pointer'));
  map.on('mouseleave', 'clusters', () => (map.getCanvas().style.cursor = ''));
  map.on('mouseenter', 'unclustered-point', () => (map.getCanvas().style.cursor = 'pointer'));
  map.on('mouseleave', 'unclustered-point', () => (map.getCanvas().style.cursor = ''));
  map.on('dragend', () => recalcGeoBounds(map, updateGeoBounds));
  map.on('zoomend', () => handleZoomEnd(map, profileCoords, user, themeColor, updateGeoBounds));
  map.on('boxzoomend', () => handleZoomEnd(map, profileCoords, user, themeColor, updateGeoBounds));
};

/**
 * Zoom to the extents of the points contained within the cluster
 */
const zoomToCluster = (map: mapboxgl.Map) => (e: mapboxClusterClickEvent) => {
  const features = map.queryRenderedFeatures(e.point, {
    layers: ['clusters'],
  });
  const clusterId = (features[0] as any).properties.cluster_id;
  const source = map.getSource('points') as GeoJSONSource;
  source.getClusterExpansionZoom(clusterId, function (err: any, zoom: number) {
    if (err) return;
    map.easeTo({
      center: (features[0].geometry as any).coordinates,
      zoom,
    });
  });
};

/**
 * Zoom to the minimum level required to hide the unclustered point layer and reveal the markers
 */
const zoomToUnclusteredPoint = (map: mapboxgl.Map) => (e: mapboxClusterClickEvent) => {
  const feature = e.features![0];
  map.easeTo({
    center: (feature.geometry as any).coordinates,
    zoom: markerVisibilityZoom,
  });
};

let hasMapBeenInteractedWith = false;

export const recalcGeoBounds = (map: mapboxgl.Map | null, updateGeoBounds: (geoBounds: string) => void) => {
  if (!map) return;
  const ne = map.getBounds().getNorthEast();
  const sw = map.getBounds().getSouthWest();
  updateGeoBounds(JSON.stringify([ne.lat, ne.lng, sw.lat, sw.lng]));

  if (!hasMapBeenInteractedWith) {
    ReactGA.event({ category: 'Map interacted with', action: 'Clicked' });
    hasMapBeenInteractedWith = true;
  }
};

const handleZoomEnd = (
  map: mapboxgl.Map,
  profileCoords: FeatureCollection<Geometry, GeoJsonProperties>,
  user: AuthUser | undefined,
  themeColor: string,
  updateGeoBounds: (geoBounds: string) => void,
) => {
  recalcGeoBounds(map, updateGeoBounds);
  if (map.getZoom() >= markerVisibilityZoom) {
    // Remove the unclustered point layer, and add markers for all the points
    if (map.getLayer('unclustered-point')) map.removeLayer('unclustered-point');

    for (const feature of profileCoords.features) {
      const point = feature.geometry as Point;

      // Create a HTML element for each feature with the marker image
      const el = document.createElement('div');
      el.className = 'marker';
      el.style.backgroundImage = window.location.origin + '/map-marker.svg';
      el.onclick = () => {
        const profileSlug = feature.properties?.profileSlug;
        const profileBasePath = `/marketing/profiles/${profileSlug}`;
        const profileAction = canUserEditMarketingProfile(user) ? `edit` : `view`;
        const cardLink = `${profileBasePath}/${profileAction}`;
        window.open(cardLink, '_blank');
      };

      // Create an image subelement to contain the logo
      const logoEl = document.createElement('img');
      const logoUrl = feature.properties?.logoUrl;
      const logoElSrc = logoUrl
        ? getOptimisedImageUrl(logoUrl, { resize: { width: 28, height: 28 } })
        : window.location.origin + '/map-marker-logo-fallback.svg';
      logoEl.className = 'map-marker-logo';
      logoEl.src = logoElSrc;
      logoEl.alt = 'school logo';
      el.appendChild(logoEl);

      // Make a marker for each feature and add it to the map
      const marker = new mapboxgl.Marker(el, { offset: [0, -32] }).setLngLat(point.coordinates as [number, number]).addTo(map!);
      markers.push(marker);
    }
  } else {
    // Remove the markers for all the points, and create the unclustered point layer
    for (let i = markers.length - 1; i >= 0; i--) {
      markers[i].remove();
    }
    markers.length = 0;
    addMapUnclusteredPointsLayer(map, themeColor);
  }
};
