import { Divider } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

import { BannerImage } from './BannerImage';
import { LogoImage } from './LogoImage';
import { SchoolDescription } from './SchoolDescription';
import { SchoolHighlights } from './SchoolHighlights';
import { SidebarTouchpointsSection } from './SidebarTouchpointsSection';

interface SchoolInfoEditTabProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const SchoolInfoEditTab = ({ errors, control }: SchoolInfoEditTabProps) => {
  return (
    <>
      <LogoImage />
      <Divider sx={{ my: 3 }} />
      <BannerImage />
      <Divider sx={{ my: 3 }} />
      <SchoolDescription errors={errors} control={control} />
      <Divider sx={{ my: 3 }} />
      <SidebarTouchpointsSection errors={errors} control={control} />
      <Divider sx={{ my: 3 }} />
      <SchoolHighlights errors={errors} control={control} />
    </>
  );
};
