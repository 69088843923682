import { Alert, AlertTitle, Box, Paper, Stack, Typography, useTheme } from '@mui/material';
import { formatDistance } from 'date-fns';
import { useWindowSize } from 'hooks/use-window-size';
import { useMemo, useState } from 'react';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { FileRoles } from 'types/file-roles.enum';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { MapWithRadius } from './MapWithRadius';

export const LocationSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement>();
  const size = useWindowSize();

  const [width, height] = useMemo(() => {
    if (!size) return [0, 0];
    const parentWidth = mapContainerEl?.clientWidth || 0;
    const parentHeight = mapContainerEl?.clientHeight || 0;
    return [parentWidth, parentHeight];
  }, [mapContainerEl?.clientWidth, mapContainerEl?.clientHeight, size]);

  const shouldRenderMapInfo: boolean = profile.nearCityAddressLine1 !== null || profile.airportAddressLine1 !== null;
  const metersToKilometers = (meters: number) => Number(meters / 1000).toFixed(2);
  const metersToMiles = (meters: number) => Number(meters * 0.000621371192).toFixed(2);
  // Human-readable time
  const secondsToHourMinutes = (seconds: number) => formatDistance(0, seconds * 1000);

  const logo = profile.files?.find((file) => file.role === FileRoles.FILE_ROLE_MARKETING_LOGO);
  const logoUrl = logo?.fileUrl || null;

  const isPdfFormat = useIsPdfFormat();
  const initialMapZoom = isPdfFormat ? 9 : 13;

  return (
    <section>
      <Paper sx={{ mb: '10px' }}>
        <Box sx={{ p: '20px' }}>
          <Typography mb={3} variant="h5" fontSize="24px" fontWeight={700} color={titleColor}>
            Location
          </Typography>
          <Box className="notranslate" ref={(el: any) => setMapContainerEl(el)} sx={{ minHeight: '450px', maxHeight: '600px' }}>
            <MapWithRadius center={profile.latLng} logoUrl={logoUrl} radiusKm={1} zoom={initialMapZoom} width={width} height={height} />
          </Box>
          {shouldRenderMapInfo && (
            <Stack direction="row" spacing={1} justifyContent="space-between" width="100%" alignItems="left" mt={2}>
              {profile.nearCityAddressLine1 && (
                <Alert
                  severity="info"
                  color="info"
                  sx={{
                    '& .MuiAlert-icon': {
                      color: theme.palette.secondary.dark,
                    },
                    width: '100%',
                  }}
                >
                  <AlertTitle>Nearest major city</AlertTitle>
                  <Typography>
                    {profile.nearCityAddressLine1}
                    {profile.nearCityDriveMetres && profile.nearCityDriveSeconds && (
                      <>
                        : {metersToKilometers(profile.nearCityDriveMetres)}km / {metersToMiles(profile.nearCityDriveMetres)}mi |{' '}
                        {secondsToHourMinutes(profile.nearCityDriveSeconds)} drive time (approx)
                      </>
                    )}
                  </Typography>
                </Alert>
              )}
              {profile.airportAddressLine1 && (
                <Alert
                  severity="info"
                  color="info"
                  sx={{
                    '& .MuiAlert-icon': {
                      color: theme.palette.secondary.dark,
                    },
                    width: '100%',
                  }}
                >
                  <AlertTitle>Arrival airport</AlertTitle>
                  <Typography>
                    {profile.airportAddressLine1}
                    {profile.airportDriveMetres && profile.airportDriveSeconds && (
                      <>
                        : {metersToKilometers(profile.airportDriveMetres)}km / {metersToMiles(profile.airportDriveMetres)}mi |{' '}
                        {secondsToHourMinutes(profile.airportDriveSeconds)} drive time (approx)
                      </>
                    )}
                  </Typography>
                </Alert>
              )}
            </Stack>
          )}
        </Box>
      </Paper>
    </section>
  );
};
