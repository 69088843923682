import { Divider } from '@mui/material';
import { ProfileData } from 'types/profile.interface';

import { DocumentsSection } from './DocumentsSection';
import { GallerySection } from './GallerySection';

interface MediaEditTabProps {
  profile: ProfileData;
}

export const MediaEditTab = ({ profile }: MediaEditTabProps) => {
  return (
    <>
      <GallerySection profile={profile} />
      <Divider sx={{ my: 3 }} />
      <DocumentsSection />
    </>
  );
};
