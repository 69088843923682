import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Autocomplete,
  Box,
  Chip,
  chipClasses,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useAsync } from 'hooks/use-async';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { Curriculum } from 'types/profile.interface';
import { stripNonNumerical } from 'utils/stripNonNumerical';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';
import { HTMLAttributes } from 'react';

import { religiousAffiliationOptions } from './religious-affiliation-options';

export interface SidebarTouchpointsSectionProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const SidebarTouchpointsSection = ({ errors, control }: SidebarTouchpointsSectionProps) => {
  const theme = useTheme();
  const [curriculumOptions] = useAsync<Curriculum[]>('/curriculums');

  return (
    <section id="sidebar-touchpoints-section">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        Sidebar touchpoints
      </Typography>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={4}>
          <Controller
            name="isPrivateSchool"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="public-private-radio-group-label">School type</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="public-private-radio-group-label"
                  name="public-private-radio-group"
                  value={Boolean(value)}
                  onChange={(event, value) => onChange(value === 'true')}
                >
                  <FormControlLabel value={false} control={<Radio />} label="Public" />
                  <FormControlLabel value={true} control={<Radio />} label="Private" />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="coedStatus"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="coed-select-label" sx={{ mb: 1 }}>
                  Coeducation Status
                </FormLabel>
                <Select aria-labelledby="coed-select-label" id="coed-select" value={value} onChange={onChange}>
                  <MenuItem value={CoedStatuses.CoEd}>Coeducational</MenuItem>
                  <MenuItem value={CoedStatuses.Male}>Boys only</MenuItem>
                  <MenuItem value={CoedStatuses.Female}>Girls only</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="internationalStudentPercentage"
            control={control}
            rules={{
              min: { value: 1, message: 'International student percentage must be greater than zero.' },
              max: { value: 100, message: 'International student percentage must be less than 100.' },
              required: 'International student percentage is required.',
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="international-student-percentage-input-label" sx={{ mb: 1 }}>
                  International student percentage
                </FormLabel>
                <TextField
                  aria-labelledby="international-student-percentage-input-label"
                  id="international-student-percentage-input"
                  value={value}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.internationalStudentPercentage)}
                />
                {errors.internationalStudentPercentage ? <Typography color="error">{errors.internationalStudentPercentage.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="dayOrBoarding"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="day-or-boarding-select-label" sx={{ mb: 1 }}>
                  School category
                </FormLabel>
                <Select aria-labelledby="day-or-boarding-select-label" id="day-or-boarding-select" value={value} onChange={onChange}>
                  <MenuItem value="day">Day school only</MenuItem>
                  <MenuItem value="boarding">Boarding school only</MenuItem>
                  <MenuItem value="dayAndBoarding">Day &amp; boarding school</MenuItem>
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="totalStudentCount"
            control={control}
            rules={{
              min: { value: 1, message: 'School population must be greater than zero.' },
              max: { value: 10000000, message: 'School population must be less than 10,000,000.' },
              required: 'School population is required.',
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="total-student-count-input-label" sx={{ mb: 1 }}>
                  School population
                </FormLabel>
                <TextField
                  aria-labelledby="total-student-count-input-label"
                  id="total-student-count-input"
                  value={value}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.totalStudentCount)}
                />
                {errors.totalStudentCount ? <Typography color="error">{errors.totalStudentCount.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="religiousAffiliation"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="affiliation-input-label" sx={{ mb: 1 }}>
                  Religious affiliation
                </FormLabel>
                <Select
                  aria-labelledby="affiliation-input-label"
                  id="affiliation-input"
                  // <Select> does not correctly handle empty string or null option values
                  value={value || 'none'}
                  onChange={(event) => onChange(event.target.value !== 'none' ? event.target.value : null)}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  <MenuItem value="none">No Religious Affiliation</MenuItem>
                  {religiousAffiliationOptions.map((affiliation) => (
                    <MenuItem key={affiliation} value={affiliation}>
                      {affiliation}
                    </MenuItem>
                  ))}
                </Select>
                <br />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="curriculums"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="curriculums-input-label" sx={{ mb: 1 }}>
                  International Curriculums
                </FormLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  aria-labelledby="curriculums-input-label"
                  id="curriculums-autocomplete"
                  size="small"
                  options={curriculumOptions || value}
                  disableCloseOnSelect
                  disabled={value.length >= 3}
                  getOptionLabel={(o) => (o as any).name}
                  isOptionEqualToValue={(option: { id: number }, value: { id: number }) => option.id === value.id}
                  value={value}
                  renderInput={(params) => <TextField {...params} placeholder="Select curriculum" />}
                  renderOption={(props: HTMLAttributes<HTMLLIElement>, option: Curriculum, { selected }: { selected: boolean }) => {
                    return (
                      <li {...props}>
                        <Stack width="100%" direction="row">
                          <Box>{option.name}</Box>
                          <Box flex={1} />
                          {selected && <CheckIcon color="success" />}
                        </Stack>
                      </li>
                    );
                  }}
                  renderTags={() => <></>}
                  onChange={(event, values) => onChange(values)}
                />
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  {value.map((curriculum: Curriculum, index: number) => (
                    <Chip
                      sx={{
                        mr: 1,
                        mt: 1,
                        bgcolor: theme.palette.background.paper,
                        borderRadius: '3px',
                        height: 'auto',
                        [`& .${chipClasses.label}`]: {
                          px: 1,
                          py: '3px',
                        },
                        [`& .${chipClasses.deleteIcon}`]: {
                          fontSize: '16px',
                          color: theme.palette.secondary.main,
                        },
                      }}
                      size="small"
                      key={curriculum.id}
                      label={curriculum.name}
                      deleteIcon={<ClearIcon />}
                      onDelete={() => onChange([...value.slice(0, index), ...value.slice(index + 1)])}
                    />
                  ))}
                </Box>
                {errors.curriculums ? <Typography color="error">{errors.curriculums.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="averageClassSize"
            control={control}
            rules={{
              min: { value: 1, message: 'Average class size must be greater than zero.' },
              max: { value: 10000, message: 'Average class size must be less than 10,000.' },
              required: 'Average class size is required.',
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl required fullWidth size="small">
                <FormLabel id="average-class-size-input-label" sx={{ mb: 1 }}>
                  Average class size
                </FormLabel>
                <TextField
                  aria-labelledby="average-class-size-input-label"
                  id="average-class-size-input"
                  value={value}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.averageClassSize)}
                />
                {errors.averageClassSize ? <Typography color="error">{errors.averageClassSize.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="homestayAccomodation"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="homestay-accomodation-input-label" sx={{ mb: 1 }}>
                  Homestay Accomodation
                </FormLabel>
                <Select
                  aria-labelledby="homestay-accomodation-input-label"
                  id="homestay-accomodation-input"
                  // <Select> does not correctly handle empty string or null option values
                  value={value || 'none'}
                  onChange={(event) => onChange(event.target.value !== 'none' ? event.target.value : null)}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                    },
                  }}
                >
                  <MenuItem value="none">Unavailable</MenuItem>
                  <MenuItem value="School">Managed by School</MenuItem>
                  <MenuItem value="Educatius">Managed by Educatius</MenuItem>
                  <MenuItem value="Australian Homestay Network">Managed by Australian Homestay Network</MenuItem>
                </Select>
                <br />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Controller
            name="domesticCurriculums"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="domestic-curriculums-input-label" sx={{ mb: 1 }}>
                  Domestic Curriculums
                </FormLabel>
                <TextField
                  aria-labelledby="domestic-curriculums-input-label"
                  id="domestic-curriculums-input"
                  onKeyUp={(event: any) => {
                    if (event.key === 'Enter') {
                      // Add curriculum to value store, and make input blank
                      if (event.target.value) {
                        value.push(event.target.value);
                        onChange(value);
                      }
                      event.target.value = '';
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Press Enter after typing to add">
                          <InfoOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  error={Boolean(errors.domesticCurriculums)}
                />
                <Box display="flex" flexDirection="row" flexWrap="wrap" sx={{ pt: 1 }}>
                  {value.map((domesticCurriculum: string, index: number) => (
                    <Chip
                      sx={{
                        mr: 1,
                        mb: 1,
                        bgcolor: theme.palette.background.paper,
                        borderRadius: '3px',
                        height: 'auto',
                        [`& .${chipClasses.label}`]: {
                          px: 1,
                          py: '3px',
                        },
                        [`& .${chipClasses.deleteIcon}`]: {
                          fontSize: '16px',
                          color: theme.palette.secondary.main,
                        },
                      }}
                      size="small"
                      key={index}
                      label={domesticCurriculum}
                      deleteIcon={<ClearIcon />}
                      onDelete={() => onChange([...value.slice(0, index), ...value.slice(index + 1)])}
                    />
                  ))}
                </Box>
                {errors.domesticCurriculums ? <Typography color="error">{errors.domesticCurriculums.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </section>
  );
};
