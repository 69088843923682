import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { PricingRegion } from 'types/pricing-region.enum';
import CheckIcon from '@mui/icons-material/Check';

type RegionOption = { value: PricingRegion; label: string };

const regionOptions: RegionOption[] = [
  { value: PricingRegion.China, label: 'China' },
  { value: PricingRegion.RestOfWorld, label: 'ROW' },
  { value: PricingRegion.EuropeanEconomicArea, label: 'Europe' },
];

interface PricingOverrideSelectorProps {
  costType: 'net' | 'gross' | undefined;
  region: PricingRegion | undefined;
  onChange: (value: { newCostType: 'net' | 'gross'; newRegion: PricingRegion }) => void;
}

export const PricingOverrideSelector = ({ costType, region, onChange }: PricingOverrideSelectorProps) => (
  <Select
    fullWidth
    sx={{ width: '215px' }}
    size="small"
    value={`${costType || ''},${region || ''}`}
    onChange={(event) => {
      onChange({
        newCostType: event.target.value.split(',')[0] as 'net' | 'gross',
        newRegion: event.target.value.split(',')[1] as PricingRegion,
      });
    }}
    renderValue={(value) => {
      if (value === ',') return 'Select Pricing';
      return `${value.split(',')[0].toUpperCase()} Pricing: ${regionOptions.find((option) => option.value === value.split(',')[1])?.label}`;
    }}
    displayEmpty
  >
    <MenuItem disabled value="," sx={{ display: 'none' }}>
      Select Pricing
    </MenuItem>
    <MenuItem disabled sx={{ fontWeight: 500, color: 'black' }}>
      NET Pricing
    </MenuItem>
    {regionOptions.map((regionOption) => (
      <MenuItem value={`net,${regionOption.value}`} key={regionOption.value} sx={{ width: '100%' }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography sx={{ ml: 2 }}>{regionOption.label}</Typography>
          {`net,${regionOption.value}` === `${costType},${region}` && <CheckIcon color="success" />}
        </Stack>
      </MenuItem>
    ))}
    <MenuItem disabled sx={{ fontWeight: 500, color: 'black' }}>
      GROSS Pricing
    </MenuItem>
    {regionOptions.map((regionOption) => (
      <MenuItem value={`gross,${regionOption.value}`} key={regionOption.value} sx={{ width: '100%' }}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography sx={{ ml: 2 }}>{regionOption.label}</Typography>
          {`gross,${regionOption.value}` === `${costType},${region}` && <CheckIcon color="success" />}
        </Stack>
      </MenuItem>
    ))}
  </Select>
);
