import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, autocompleteClasses, InputAdornment, ListSubheader, Popper, Stack, styled, TextField, Typography, useTheme } from '@mui/material';
import { Pill } from 'components/Pill';
import { createContext, forwardRef, HTMLAttributes, PropsWithChildren, ReactChild, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Feature, ProfileData, SubjectFeature } from 'types/profile.interface';

interface FeatureSearchProps {
  features: (Feature | SubjectFeature)[];
  valueMap: Map<ProfileData['profileFeatures'][0]['featureId'], ProfileData['profileFeatures'][0]>;
  onFeatureHighlight: (feature: Feature | SubjectFeature) => void;
}

/**
 * Search result list is virtualised for performance reasons
 * @see https://mui.com/material-ui/react-autocomplete/#virtualization
 */
export const FeatureSearch = ({ features, valueMap, onFeatureHighlight }: FeatureSearchProps) => {
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false);

  const theme = useTheme();

  function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props;
    const dataSet = data[index];

    if (dataSet.hasOwnProperty('group')) {
      return (
        <ListSubheader key={dataSet.key} component="div" style={style}>
          {dataSet.group}
        </ListSubheader>
      );
    }

    // disable selected background in listbox as we use a Pill
    dataSet[0]['aria-selected'] = false;

    return (
      <Stack component="li" direction="row" justifyItems="space-between" key={dataSet[1].id} {...dataSet[0]} style={style}>
        <Typography
          flex={1}
          noWrap
          onClick={() => {
            setAutocompleteOpen(false);
            onFeatureHighlight(dataSet[1]);
          }}
        >
          {dataSet[1].name}
        </Typography>
        {valueMap.has(dataSet[1].id) && <Pill color="info" label="Added" />}
      </Stack>
    );
  }

  const ListboxOuterElementContext = createContext<PropsWithChildren<HTMLAttributes<HTMLElement>>>({});

  const ListboxOuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(ListboxOuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
  });

  function useResetCache(data: any) {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true);
      }
    }, [data]);
    return ref;
  }

  const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
      boxSizing: 'border-box',
      '& ul': {
        padding: 0,
        margin: 0,
      },
    },
  });

  const [inputValue, setInputValue] = useState('');

  const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactChild[] = [];
    (children as ReactChild[]).forEach((item: ReactChild & { children?: ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemHeight = 42;

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemHeight;
      }

      return itemData.length * itemHeight;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <ListboxOuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 16}
            width="100%"
            ref={gridRef}
            outerElementType={ListboxOuterElementType}
            innerElementType="ul"
            itemSize={() => itemHeight}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </ListboxOuterElementContext.Provider>
      </div>
    );
  });

  return (
    <Autocomplete
      multiple
      size="small"
      options={features || []}
      disableCloseOnSelect
      clearOnBlur={false}
      clearOnEscape={false}
      disableClearable
      openOnFocus
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onClose={() => setAutocompleteOpen(false)}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => {
        if (reason === 'input') setInputValue(value);
      }}
      disableListWrap
      disablePortal
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.name}
      noOptionsText="No features found"
      renderOption={(props, option) => [props, option] as ReactNode}
      renderGroup={(params) => params as unknown as ReactNode}
      sx={{ width: '350px', backgroundColor: theme.palette.background.paper }}
      renderTags={() => <></>}
      renderInput={(params) => {
        params.InputProps.endAdornment = null;
        params.InputProps.startAdornment = (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        );

        return <TextField {...params} placeholder="Search for feature" />;
      }}
    />
  );
};
