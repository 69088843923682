import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { IFile } from 'types/file.interface';
import { fileSize } from 'utils';

import { FileDownloadButton } from './FileDownloadButton';

interface FileTableProps {
  files: IFile[];
  profileSlug: string;
}

export const FileTable = ({ files, profileSlug }: FileTableProps) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>File&nbsp;Name</TableCell>
          <TableCell>File&nbsp;Type</TableCell>
          <TableCell>File&nbsp;Size</TableCell>
          <TableCell>Date&nbsp;Added</TableCell>
          <TableCell>Download</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {files.map((file) => (
          <TableRow key={file.id}>
            <TableCell>{file.filename}</TableCell>
            <TableCell>{file.mime.split('/')[1]}</TableCell>
            <TableCell>{file.size ? fileSize(file.size) : '-'}</TableCell>
            <TableCell>{moment(file.createdAt).fromNow()}</TableCell>
            <TableCell>
              <FileDownloadButton file={file} profileSlug={profileSlug}></FileDownloadButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
