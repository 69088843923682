import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useIsIframed } from 'hooks/use-is-iframed';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from 'utils/config';

interface Auth0ProviderWithNavigateProps {
  children?: ReactNode;
}

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate();
  const { auth0Domain, auth0ClientId, auth0CallbackUrl, auth0Audience, auth0Organisation } = getConfig();

  const isIframed = useIsIframed();
  if (isIframed) {
    return <>{children}</>;
  }

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || '/marketing');
  };

  if (!(auth0Domain && auth0ClientId && auth0CallbackUrl && auth0Audience)) {
    return <></>;
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: auth0CallbackUrl,
        scope: 'openid profile email offline_access read:current_user',
        organization: auth0Organisation,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {children}
    </Auth0Provider>
  );
};
