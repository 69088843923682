import LogRocket from 'logrocket';
import { AuthUser } from 'types/user.interface';

import { getUserId } from './get-user-id';

const appID = 'hhyqna/enroller-marketplace';

export const initLogRocket = () => {
  LogRocket.init(appID, {
    // COPY FROM ENROLLER-APP
    network: {
      requestSanitizer: function (request: any) {
        const body = !request.body || request.body instanceof FormData ? null : JSON.parse(request.body);

        if (request.headers['Authorization']) {
          delete request.headers['Authorization'];
        }

        if (body) {
          Object.keys(body).forEach((field) => {
            if (field.includes('password')) {
              delete body[field];
            }
          });

          request.body = body;
        }

        return request;
      },
      responseSanitizer: function (response: any) {
        const body = response.body ? JSON.parse(response.body) : null;

        if (body && body.data && body.data.access_token) {
          response.body = body;
          delete response.body.data.access_token;
        }

        return response;
      },
    },
  });
};

export const setLogRocketIdentity = (user: AuthUser) => {
  LogRocket.identify(getUserId(user), {
    name: `${user.userData.firstName} ${user.userData.lastName}`,
    email: user.email!,
    user_type: user.userData.roles[0],
  });
};
