import { AuthUser } from 'types/user.interface';

import { Permissions } from '../types/permissions.enum';

const PROFILE_EDITOR_ROLES = ['institutional-marketing-only-staff', 'institutional-admin', 'institutional-staff'];

export const canUserEditMarketingProfile = (user: AuthUser | undefined): boolean => PROFILE_EDITOR_ROLES.some((role) => user?.userData?.roles?.includes(role));
export const isMarketingOnlySchoolUser = (userRoles: string[]): boolean => userRoles.includes('institutional-marketing-only-staff');

export const canUserManageAgency = (user: AuthUser | undefined): boolean => Boolean(user?.userData?.roles?.includes('agent-agency-admin'));

export const canUser = (permission: Permissions, user: AuthUser | undefined): boolean => Boolean(user?.userPermissions.includes(permission));
