import { Divider, FormControl, FormLabel, Stack, Typography } from '@mui/material';
import { AddressAutocomplete } from 'components/AddressAutocomplete';
import { Control, Controller } from 'react-hook-form';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

export interface MapInfoSectionProps {
  control: Control<EditableProfileFields>;
}

export const MapInfoSection = ({ control }: MapInfoSectionProps) => (
  <section id="map-info-section">
    <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
      Map info
    </Typography>
    <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
      <Controller
        name="nearCity"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth size="small">
            <FormLabel id="nearest-city-autocomplete-label">Nearest major city</FormLabel>
            <AddressAutocomplete
              initialPlace={value || ''}
              types="(cities)"
              onChange={(address) => {
                onChange(address);
              }}
            />
          </FormControl>
        )}
      />
      <Controller
        name="airport"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth size="small">
            <FormLabel id="airport-autocomplete-label">Arrival airport</FormLabel>
            <AddressAutocomplete
              initialPlace={value || ''}
              types="airport"
              onChange={(address) => {
                onChange(address);
              }}
            />
          </FormControl>
        )}
      />
    </Stack>
  </section>
);
