import { createContext, ReactNode, useContext, useState } from 'react';

type MapViewOpenStateContextData = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const MapViewOpenStateContext = createContext<MapViewOpenStateContextData | undefined>(undefined);

interface MapViewOpenStateProviderProps {
  children: ReactNode;
}

export const MapViewOpenStateProvider = ({ children }: MapViewOpenStateProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <MapViewOpenStateContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </MapViewOpenStateContext.Provider>
  );
};

export const useMapViewOpenState = () => {
  const context = useContext(MapViewOpenStateContext);

  if (context === undefined) {
    throw new Error('useMapViewOpenState must be used within a MapViewOpenStateProvider');
  }

  return context;
};
