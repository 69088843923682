import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, Box, Card, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

import { FilterFlag } from '../filter-flag.type';
import { FilterParams } from '../filter-params.interface';

export type SportsTuple = Pick<FilterParams, 'sports' | 'hasAllSports'>;

interface SportsFilterProps {
  value: SportsTuple;
  onChange: (newValue: SportsTuple) => void;
  onDiscard: () => void;
}

export const SportsFilter = ({ value, onChange, onDiscard }: SportsFilterProps) => {
  const { sports, hasAllSports } = value;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 400);

  // Params for fetching sport options
  const fetchSportOptionsParams = new URLSearchParams({ search: debouncedSearchTerm });
  const fetchSportsOptionsUrl = `/profiles/filter-options/sports?${fetchSportOptionsParams}`;

  // Setup sport options
  const [sportNames] = useAsync<string[]>(fetchSportsOptionsUrl);
  const [lastOptions, setLastOptions] = useState<string[]>([]);
  useEffect(() => {
    if (sportNames?.length > 0) setLastOptions(sportNames);
  }, [sportNames]);

  // Don't hide options while subsequent options requests are in flight and sportNames is null
  const sportOptions = useMemo(() => sportNames || lastOptions, [sportNames, lastOptions]);

  // Bold the typed partial substring without breaking case or squashing spaces between element boundaries
  const renderPartialBoldOption = (props: HTMLAttributes<HTMLLIElement>, option: string, { selected }: { selected: boolean }) => {
    const i = option.toLowerCase().indexOf(debouncedSearchTerm);
    const len = debouncedSearchTerm.length;
    const glyphs = [...option]
      .map((char) => (char === ' ' ? <>&nbsp;</> : char))
      .map((glyph: ReactNode, pos) => (pos >= i && pos < i + len ? <strong>{glyph}</strong> : <>{glyph}</>));
    return (
      <li {...props}>
        <Stack width="100%" direction="row">
          <Box>{glyphs}</Box>
          <Box flex={1} />
          {selected && <CheckIcon color="success" />}
        </Stack>
      </li>
    );
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Sports" onDiscard={onDiscard} />
      <Stack direction="row">
        <Select
          sx={{ height: '56px', width: '107px' }}
          value={hasAllSports || 'false'}
          onChange={(event) => onChange({ sports, hasAllSports: event.target.value as FilterFlag })}
        >
          <MenuItem value="false">Has any</MenuItem>
          <MenuItem value="true">Has all</MenuItem>
        </Select>
        <Autocomplete
          id="sports-filter-autocomplete"
          options={sportOptions}
          multiple
          autoComplete
          filterOptions={(x) => x}
          disableCloseOnSelect
          value={sports}
          onChange={(event, newValue) => onChange({ hasAllSports, sports: newValue })}
          onInputChange={(event, newSearchTerms) => setSearchTerm(newSearchTerms)}
          renderInput={(params) => <TextField {...params} label="Search for sport" />}
          renderOption={renderPartialBoldOption}
          sx={{ flex: 1, mb: 1, ml: 1 }}
        />
      </Stack>
    </Card>
  );
};
