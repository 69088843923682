import { Box, Button, Paper, Stack, Typography, useTheme } from '@mui/material';
import { SlateRenderer } from 'components/SlateRenderer';
import { MouseEventHandler, useState } from 'react';
import ReactGA from 'react-ga4';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

export const AboutSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const isPdfFormat = useIsPdfFormat();

  const [isAboutSectionCollapsed, setIsAboutSectionCollapsed] = useState<boolean>(!isPdfFormat);
  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  const description = profile.description?.length > 0 ? profile.description : [{ children: [{ text: '' }] }];

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    const anchor = event.target as HTMLAnchorElement;
    const href = anchor?.getAttribute('href') || undefined;
    ReactGA.event({ category: 'Institution Profile Content Link', label: href, action: 'Clicked' });
  };

  return (
    <Paper sx={{ overflowY: 'hidden', position: 'relative', mb: '10px' }}>
      <Box p="20px">
        <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor}>
          About
        </Typography>
        <Box sx={{ overflowY: 'hidden', maxHeight: isAboutSectionCollapsed ? '140px' : 'inherit', marginBottom: '10px' }}>
          <SlateRenderer rootSlateNode={description} onLinkClick={onLinkClick} />
        </Box>
        {!isPdfFormat && (
          <Stack direction="row" justifyContent="center">
            <Box>
              {isAboutSectionCollapsed && (
                <Button endIcon={<ExpandMore />} onClick={() => setIsAboutSectionCollapsed(false)}>
                  Read more
                </Button>
              )}
              {!isAboutSectionCollapsed && (
                <Button endIcon={<ExpandLess />} onClick={() => setIsAboutSectionCollapsed(true)}>
                  Read less
                </Button>
              )}
            </Box>
          </Stack>
        )}
      </Box>
    </Paper>
  );
};
