import React, { CSSProperties, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { useTheme } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'types/user.interface';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';

import { useProfileCoords } from './ProfileCoordsContext';
import { addMapClustersCountLayer, addMapClustersLayer, addMapUnclusteredPointsLayer } from './map-layers';
import { addMapSource, markerVisibilityZoom } from './map-source';
import { mapSetup } from './map-setup';
import { addMapEventHandlers } from './map-events';

interface InteractiveProfileClusterMapProps {
  height: CSSProperties['height'];
  width: CSSProperties['width'];
}

export const InteractiveProfileClusterMap: React.FC<InteractiveProfileClusterMapProps> = ({ width, height }) => {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement>();
  const { updateGeoBounds } = useFilters();
  const { profileCoords } = useProfileCoords();

  const { user } = useAuth0<AuthUser>();
  const theme = useTheme();
  const themeColor = theme.palette.primary.main;

  useEffect(() => {
    mapSetup(mapContainerEl, setMap);
  }, [mapContainerEl]);

  useEffect(() => {
    if (!map || !profileCoords) return;
    if (map.getLayer('clusters')) map.removeLayer('clusters');
    if (map.getLayer('cluster-count')) map.removeLayer('cluster-count');
    if (map.getLayer('unclustered-point') && map.getZoom() <= markerVisibilityZoom) map.removeLayer('unclustered-point');
    if (map.getSource('points')) map.removeSource('points');
    addMapSource(map, profileCoords);
    addMapClustersLayer(map, themeColor);
    addMapClustersCountLayer(map);
    if (map.getZoom() <= markerVisibilityZoom) addMapUnclusteredPointsLayer(map, themeColor);
    addMapEventHandlers(map, profileCoords, user, themeColor, updateGeoBounds);
  }, [map, profileCoords, themeColor, user, updateGeoBounds]);

  return <div className="mapboxgl-container" ref={(el) => setMapContainerEl(el!)} style={{ width, height, borderRadius: '0.5rem' }} />;
};
