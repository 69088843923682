import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';

export const ProfileSearchField = () => {
  const {
    state: { search },
    updateSearch,
  } = useFilters();
  const [value, setValue] = useState(search);

  // TODO: Move into the API search fetch hook as map changes will also need debouncing
  // Might as well centralise it at this point so other filters can take advantages of this.
  const [debouncedSearchTerm] = useDebounce<string>(value, 400);

  const isFirstUpdate = useRef(true);

  useEffect(() => {
    // The debounce here triggers an extra render on mount.
    // If we call updateSearch on this render, it will also set the current page back to 1.
    // So we skip updating the search when the debouncedSearchTerm changes on the first render.
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    updateSearch(debouncedSearchTerm);
  }, [updateSearch, debouncedSearchTerm]);

  return (
    <TextField
      sx={{ minWidth: '30%' }}
      size="small"
      placeholder="Search profiles"
      variant="outlined"
      value={value}
      data-intercom-target="search input"
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton sx={{ visibility: search ? 'visible' : 'hidden' }} onClick={() => setValue('')}>
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};
