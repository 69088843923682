import { Pill } from 'components/Pill';
import { addDays, isAfter } from 'date-fns';

export interface ApplicationWindowStatusPillProps {
  windowStart: string;
  windowEnd: string;
}

export const ApplicationWindowStatusPill = ({ windowStart, windowEnd }: ApplicationWindowStatusPillProps) => {
  let label: string;
  let color: 'success' | 'info' | 'error' | 'warning';

  // Opening Soon - should display when the start date is after today
  // Open - should display when the end date is after today and the start date is before
  // Closed - should display when today is 24 hours past end date

  const isOpeningSoon = isAfter(new Date(windowStart), new Date());
  const isClosed = isAfter(addDays(new Date(), 1), new Date(windowEnd));

  if (isOpeningSoon) {
    label = 'Opening Soon';
    color = 'info';
  } else if (isClosed) {
    label = 'Closed';
    color = 'info';
  } else {
    label = 'Open';
    color = 'success';
  }

  return <Pill label={label} color={color} />;
};
