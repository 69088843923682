import { Autocomplete, Card, Skeleton, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { ProfileData } from 'types/profile.interface';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

interface CustomAttributeFilterProps {
  value: ProfileData['customAttribute'];
  onChange: (newCustomAttrib: ProfileData['customAttribute']) => void;
  onDiscard: () => void;
}

export const CustomAttributeFilter = ({ value, onChange, onDiscard }: CustomAttributeFilterProps) => {
  const fetchCustomAttributeOptionsUrl = `/profiles/filter-options/custom-attributes`;
  const [customAttribs, customAttribsStatus] = useAsync<string[]>(fetchCustomAttributeOptionsUrl);
  const customAttribOptions = useMemo(() => customAttribs?.map((label) => ({ label })) || [], [customAttribs]);

  // TODO: Custom Attribute name should be pulled from domain custom attribute name if expanded beyond just VDET.
  const filterTitle = 'Region';

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title={filterTitle} onDiscard={onDiscard} />
      <Autocomplete
        id="custom-attribute-autocomplete"
        options={customAttribOptions}
        isOptionEqualToValue={(opt, val) => opt.label === val.label}
        value={{ label: value }}
        onChange={(event, value) => onChange(value?.label || '')}
        renderInput={(params) => (customAttribsStatus === 'success' ? <TextField {...params} label="Region" /> : <Skeleton animation="wave" />)}
        sx={{ flex: 1 }}
      />
    </Card>
  );
};
