import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { getConfig } from './config';
import { getUserId } from './get-user-id';

import { AuthUser } from '../types/user.interface';

export const initSentry = () => {
  const { sentryDsn, environment, apiUrl, version } = getConfig();

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing({ tracePropagationTargets: [apiUrl] })],
    tracesSampleRate: 1.0,
    environment,
    release: version,
  });
};

export const setSentryUser = (user: AuthUser) => {
  Sentry.setUser({
    username: `${user.userData.firstName} ${user.userData.lastName}`,
    email: user.email,
    id: getUserId(user),
  });
};
