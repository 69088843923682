import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Avatar, Box, LinearProgress, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AxiosRequestConfig } from 'axios';
import { useAsync } from 'hooks/use-async';
import { useMountEffect } from 'hooks/use-mount-effect';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { fileSize } from 'utils';

interface FileUploadProps {
  files: File[];
  fileRole: any;
  onUploadComplete: (files: IFile[]) => void;
  fileUrl?: string;
}

export const FileUpload = ({ files, fileRole, onUploadComplete, fileUrl }: FileUploadProps) => {
  const theme = useTheme();
  const { profileSlug } = useParams();
  // We need to keep a local copy as useAsync resets the error after showing snackbar
  const [errorMessage, setErrorMessage] = useState<string>('');

  const fetchFilesUrl = fileUrl ?? `/profiles/${profileSlug}/files`;
  const extraAxiosConfig: Partial<AxiosRequestConfig> = { headers: { 'Content-Type': 'multipart/form-data;' } };
  const [res, status, execute, error] = useAsync<IFile[]>(fetchFilesUrl, 'POST', false, extraAxiosConfig);
  const color = status === 'pending' ? grey[300] : theme.palette.primary.dark;

  useMountEffect(() => {
    const fileData = new FormData();
    fileData.append('role', fileRole);
    fileData.append('tag', 'school-upload');
    files.forEach((file, i) => fileData.append(`marketing-documents-${i}`, file));

    execute(fileData);
  });

  useEffect(() => {
    if (error) {
      setErrorMessage((error.response?.data as any)?.message || error.message);
    }
  }, [error]);

  useEffect(() => {
    if (status === 'success') {
      onUploadComplete(res);
    }
    if (status === 'error') {
      onUploadComplete([]);
    }
    // We only care about status changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Stack p={2} my={2} borderRadius="5px" sx={{ borderStyle: 'solid', borderWidth: '1px', borderColor: color }}>
      {files.map((file, idx) => (
        <Stack direction="row" mb={2} key={idx}>
          <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
            <DescriptionOutlinedIcon sx={{ color: 'primary.dark' }} />
          </Avatar>
          <Stack direction="column" flexGrow={2}>
            <Typography>{file.name}</Typography>
            {status === 'success' && <Typography color="secondary">Uploaded - {fileSize(file.size)}</Typography>}
            {status === 'pending' && <Typography color="secondary">Uploading...</Typography>}
            {status === 'error' && <Typography color="error">{errorMessage}</Typography>}
          </Stack>
          {/* TODO: re-enable for abort functionality */}
          {/*{status === 'pending' && idx === 0 && (*/}
          {/*  <Box>*/}
          {/*    <Tooltip title="Discard">*/}
          {/*      <IconButton aria-label="discard" onClick={() => abort?.()}>*/}
          {/*        <CloseIcon color="error" />*/}
          {/*      </IconButton>*/}
          {/*    </Tooltip>*/}
          {/*  </Box>*/}
          {/*)}*/}
          {status === 'success' && idx === 0 && (
            <Box>
              <CheckCircleIcon sx={{ color }} />
            </Box>
          )}
          {status === 'error' && idx === 0 && (
            <Box>
              <CloseIcon color="error" />
            </Box>
          )}
        </Stack>
      ))}
      {status === 'pending' && <LinearProgress />}
    </Stack>
  );
};
