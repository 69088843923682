import { useAuth0 } from '@auth0/auth0-react';
import UnpublishedIcon from '@mui/icons-material/HighlightOff';
import PublishedIcon from '@mui/icons-material/TaskAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Container, Link, Paper, Stack, Tab, Tooltip, Typography, useTheme } from '@mui/material';
import { Footer } from 'components/Footer';
import { Pill } from 'components/Pill';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfileData } from 'types/profile.interface';
import { updateProfile, useProfile } from 'app/marketing/profiles/ProfileContext';

import { AcademicInfoEditTab } from './AcademicInfo';
import { EditableProfileFields, getEditableProfileFields, getProfileFields } from './get-editable-profile-fields';
import { MediaEditTab } from './Media';
import { ProgramsFeesEditTab } from './ProgramsFees';
import { SchoolInfoEditTab } from './SchoolInfo';
import { UnsavedChangesModal } from './UnsavedChangesModal';

export const EditProfileForm = () => {
  const {
    state: { profile, status },
    dispatch,
  } = useProfile();
  const editableProfileFields = getEditableProfileFields(profile!);
  const {
    reset: formReset,
    control: formControl,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<EditableProfileFields>({
    mode: 'onTouched',
    defaultValues: editableProfileFields as any, // TSC complains about the profile description type 🤷‍♂️
  });
  const { editTab } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { getAccessTokenSilently } = useAuth0();
  const [activeTab, setActiveTab] = useState(editTab || 'info');
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const nextTab = useRef<string>('');

  // If profile is updated reset the form values
  useEffect(() => {
    formReset(getEditableProfileFields(profile!), { keepDirty: false, keepTouched: false });
  }, [formReset, profile]);

  const onUpdateProfile = (data: Partial<ProfileData>) => getAccessTokenSilently().then((token) => updateProfile(token, dispatch, profile!, data));

  // When navigating tabs check form for unsaved changes
  const onSubmit = handleSubmit(
    (data: EditableProfileFields) => onUpdateProfile(getProfileFields(data)),
    () => enqueueSnackbar('There are validation errors, please review changes', { variant: 'error', preventDuplicate: true }),
  );
  const onNavigate = (tab: string) => {
    navigate(`../${tab}`);
    setActiveTab(tab);
  };
  const onTabChange = (tab: string) => {
    if (isDirty) {
      setIsSaveModalOpen(true);
      nextTab.current = tab;
    } else {
      onNavigate(tab);
    }
  };
  const onSaveModalClose = () => {
    setIsSaveModalOpen(false);
    nextTab.current = activeTab;
  };
  const onSaveModalDiscard = () => {
    setIsSaveModalOpen(false);
    formReset(editableProfileFields, { keepDirty: false, keepTouched: false });
    onNavigate(nextTab.current);
  };
  const onSaveModalSave = async () => {
    setIsSaveModalOpen(false);
    await handleSubmit(async (data: EditableProfileFields) => {
      await onUpdateProfile(getProfileFields(data));
      onNavigate(nextTab.current);
    })();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <TabContext value={activeTab}>
        <Box
          sx={{
            px: 4,
            pt: 2,
            background: 'white',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={{ ...theme.mixins.titleGradient, fontSize: 24, fontWeight: '600' }}>{profile!.name}</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Pill label={profile!.isPublished ? 'Published' : 'Unpublished'} color={profile!.isPublished ? 'success' : 'warning'} />
              <LoadingButton loading={status === 'pending'} variant="outlined" color="secondary" onClick={onSubmit}>
                Save Progress
              </LoadingButton>
              {profile!.id ? (
                <Link href={`/marketing/profiles/${profile!.slug}/preview`} target="_blank" sx={{ textDecoration: 'none' }}>
                  <Button variant="outlined" color="secondary" endIcon={<VisibilityIcon />}>
                    Preview
                  </Button>
                </Link>
              ) : (
                <Tooltip title="A profile must be saved before previewing" arrow>
                  <span>
                    <Button variant="outlined" color="secondary" endIcon={<VisibilityIcon />} disabled>
                      Preview
                    </Button>
                  </span>
                </Tooltip>
              )}
              <Controller
                name="isPublished"
                control={formControl}
                render={({ field: { value: isPublished, onChange } }) => (
                  <LoadingButton
                    disabled={!profile!.id}
                    loading={status === 'pending'}
                    variant={isPublished ? 'text' : 'contained'}
                    color={isPublished ? 'error' : 'primary'}
                    endIcon={isPublished ? <UnpublishedIcon /> : <PublishedIcon />}
                    onClick={() => {
                      if (isDirty) {
                        onChange(!isPublished);
                        setIsSaveModalOpen(true);
                      } else {
                        onUpdateProfile({ isPublished: !isPublished });
                      }
                    }}
                    /* Stops jank when switching between modes */
                    sx={{ width: '110px' }}
                  >
                    {isPublished ? 'Unpublish' : 'Publish'}
                  </LoadingButton>
                )}
              />
            </Stack>
          </Stack>
          <TabList
            centered
            onChange={(_, value) => onTabChange(value)}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              '& button.Mui-selected': { background: theme.palette.primary.light },
            }}
          >
            <Tab label="School Info" value="info" />
            <Tab label="Programs &amp; Fees" value="programs" />
            <Tab label="Academic Info &amp; Features" value="features" />
            <Tab label="Media" value="media" />
          </TabList>
        </Box>

        <Box sx={{ overflowY: 'scroll', flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Container sx={{ flex: 1 }}>
            <Paper>
              <form onSubmit={onSubmit}>
                <TabPanel sx={{ pt: 0 }} value="info">
                  <SchoolInfoEditTab errors={errors} control={formControl} />
                </TabPanel>
                {/* TODO: Return once latest expanded pricing backend is supported */}
                <TabPanel sx={{ pt: 0 }} value="programs">
                  <ProgramsFeesEditTab control={formControl} errors={errors} />
                </TabPanel>
                <TabPanel sx={{ pt: 0 }} value="features">
                  <AcademicInfoEditTab control={formControl} errors={errors} />
                </TabPanel>
                <TabPanel sx={{ pt: 0 }} value="media">
                  <MediaEditTab profile={profile!} />
                </TabPanel>
              </form>
            </Paper>
          </Container>
          <Footer />
        </Box>
      </TabContext>
      <UnsavedChangesModal isOpen={isSaveModalOpen} onSave={onSaveModalSave} onDiscard={onSaveModalDiscard} onClose={onSaveModalClose} />
    </Box>
  );
};
