export enum FeatureSubjectSubcategories {
  AgricultureHorticulture = 'Agriculture and Horticulture',
  EnglishLanguageArts = 'English & Language Arts',
  FinanceBusinessLaw = 'Finance, Business & Law',
  FoodTechnology = 'Food technology',
  ForeignLanguages = 'Foreign Languages',
  Mathematics = 'Mathematics',
  Other = 'Other',
  PhysicalEducationHealth = 'Physical Education and Health',
  Science = 'Science',
  SocialStudiesHistory = 'Social Studies & History',
  VisualArtTechnology = 'Visual Art & Technology',
}

export enum FeatureCategories {
  Subjects = 'Subjects',
  Arts = 'Arts',
  Music = 'Music',
  Sports = 'Sports',
  Extracurricular = 'Extracurricular',
}
