import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Stack, Typography } from '@mui/material';

export interface FilterHeaderProps {
  title: string;
  onDiscard: () => void;
}

export const FilterHeader = ({ title, onDiscard }: FilterHeaderProps) => {
  return (
    <Stack direction="row">
      <Typography variant="h6" mb={2}>
        {title}
      </Typography>
      <Box flex={1} />
      <Box>
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Stack>
  );
};
