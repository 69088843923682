import { createContext, ReactNode, useContext } from 'react';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { AsyncStatus, useAsync } from 'hooks/use-async';
import { FeatureCollection } from 'geojson';

type ProfileCoordsContextData = {
  status: AsyncStatus;
  profileCoords: FeatureCollection;
};

const ProfileCoordsContext = createContext<ProfileCoordsContextData | undefined>(undefined);

interface ProfileCoordsProviderProps {
  children: ReactNode;
}

export const ProfileCoordsProvider = ({ children }: ProfileCoordsProviderProps) => {
  const { getSearchParamsFromFilters } = useFilters();

  const profileSearchParams = getSearchParamsFromFilters({ paginate: false, sort: false, filterGeometry: false });

  // Load the actual profiles we'll show with user-defined filtering and pagination
  const getFilteredProfilesUrl = `/profiles/coords?${profileSearchParams.toString()}`;
  const [getFilteredProfileCoords, getFilteredProfilesStatus] = useAsync<FeatureCollection>(getFilteredProfilesUrl);

  return (
    <ProfileCoordsContext.Provider
      value={{
        status: getFilteredProfilesStatus,
        profileCoords: getFilteredProfileCoords,
      }}
    >
      {children}
    </ProfileCoordsContext.Provider>
  );
};

export const useProfileCoords = () => {
  const context = useContext(ProfileCoordsContext);

  if (context === undefined) {
    throw new Error('useProfileCoords must be used within a ProfileCoordsProvider');
  }

  return context;
};
