import { useAuth0 } from '@auth0/auth0-react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, IconButton, Menu, MenuItem, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useMemo, useState } from 'react';
import { Permissions } from 'types/permissions.enum';
import { ProfileData } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';
import { canUser } from 'utils/userRoles';
import { PricingRegion } from 'types/pricing-region.enum';
import { PricingOverrideSelector } from 'components/PricingOverrideSelector';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgram } from 'types/study-program.interface';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { StudyProgramCard } from './StudyProgramCard';

export const StudyProgramsSection = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };
  const { user } = useAuth0<AuthUser>();

  const costFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: profile.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
      }),
    [profile.currency],
  );

  const [showCommission, setShowCommission] = useLocalStorage('showPricingCommission', false);

  const isPdfFormat = useIsPdfFormat();

  // Hide the menu button if there is no commission to show, as its toggle is currently the only menu option
  // Do not show this menu to when viewing in shared mode either
  const hasCommission = profile.studyPrograms.some((program) => program.studyProgramPeriods.some((period) => period.commission));
  const showPricingMenuButton = !isSharedView && !isPdfFormat && hasCommission && canUser(Permissions.VIEW_PRICING, user);

  // Track open state of the menu for showing commission
  const [showPricingMenuAnchorEl, setShowPricingMenuAnchorEl] = useState<HTMLElement | null>(null);

  // TODO: Make this permission-based at some point?
  const isEducatiusStaff = user?.userData.roles.includes('educatius-staff');
  const onPricingOverrideChange = ({ newCostType, newRegion }: { newCostType: 'net' | 'gross'; newRegion: PricingRegion }) => {
    setCostType(newCostType);
    setRegion(newRegion);
  };

  const [costType, setCostType] = useState<'net' | 'gross' | undefined>(undefined);
  const [region, setRegion] = useState<PricingRegion | undefined>(undefined);

  const pricingFilter = (spp: StudyProgramPeriod) => spp.costType === costType && spp.region === region;

  const filteredStudyPrograms: StudyProgram[] = profile.studyPrograms
    .filter((studyProgram) => {
      if (studyProgram.studyProgramPeriods.length < 1) return false;
      if (isEducatiusStaff && !costType) return false;
      if (isEducatiusStaff && !studyProgram.studyProgramPeriods.some(pricingFilter)) return false;
      return true;
    })
    .map((studyProgram) => {
      if (!isEducatiusStaff) return studyProgram;
      const filteredStudyProgramPeriods = studyProgram.studyProgramPeriods.filter(pricingFilter);
      return { ...studyProgram, studyProgramPeriods: filteredStudyProgramPeriods };
    });

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ mb: '10px' }}>
        <Box sx={{ p: '20px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" mb="20px">
            <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor}>
              Programs and Availability
            </Typography>

            <Stack direction="row" alignItems="center">
              {isEducatiusStaff && <PricingOverrideSelector costType={costType} region={region} onChange={onPricingOverrideChange} />}
              {showPricingMenuButton && (
                <Box>
                  <IconButton
                    aria-label="discard"
                    id="show-pricing-menu-button"
                    aria-controls={showPricingMenuAnchorEl ? 'pricing-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={showPricingMenuAnchorEl ? 'true' : undefined}
                    onClick={(event) => setShowPricingMenuAnchorEl(event.currentTarget)}
                  >
                    <MoreHorizIcon color="secondary" />
                  </IconButton>
                </Box>
              )}
              {canUser(Permissions.VIEW_COMMISSION, user) && (
                <Menu
                  id="pricing-menu"
                  anchorEl={showPricingMenuAnchorEl}
                  open={showPricingMenuAnchorEl !== null}
                  onClose={() => setShowPricingMenuAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'show-pricing-menu-button',
                  }}
                >
                  <MenuItem onClick={() => setShowCommission(!showCommission)}>{showCommission ? 'Hide' : 'Show'} commission</MenuItem>
                </Menu>
              )}
            </Stack>
          </Stack>
          {filteredStudyPrograms.map((studyProgram) => (
            <StudyProgramCard key={studyProgram.id!} studyProgram={studyProgram} costFormatter={costFormatter} showCommission={showCommission} />
          ))}
          {isEducatiusStaff && costType && filteredStudyPrograms.length === 0 && <Typography>No programs matching selected pricing.</Typography>}
          {isEducatiusStaff && !costType && <Typography>Select pricing to see programs.</Typography>}
        </Box>
      </Paper>
      {!isSharedView && profile.availabilityNotes && (
        <Paper sx={{ mb: '10px' }}>
          <Box sx={{ p: '20px' }}>
            <Typography variant="h6" color="secondary" mb={1}>
              Availability Notes
            </Typography>
            <Typography color="secondary">{profile.availabilityNotes}</Typography>
          </Box>
        </Paper>
      )}
    </section>
  );
};
