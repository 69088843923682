import { Card, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

import { FilterBoolean } from '../filter-boolean.type';

interface PublicOrPrivateFilterProps {
  value: FilterBoolean;
  onChange: (isPrivate: FilterBoolean) => void;
  onDiscard: () => void;
}

export const PublicOrPrivateFilter = ({ value, onChange, onDiscard }: PublicOrPrivateFilterProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value as FilterBoolean);
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Public or private school" onDiscard={onDiscard} />
      <FormControl fullWidth>
        <RadioGroup value={value} onChange={handleChange} row name="public-or-private-filter-radio-group">
          <FormControlLabel value="false" control={<Radio />} label="Public" />
          <FormControlLabel value="true" control={<Radio />} label="Private" />
        </RadioGroup>
      </FormControl>
    </Card>
  );
};
