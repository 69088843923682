import { EducatiusFooter } from './EducatiusFooter';
import { EducatiusHeader } from './EducatiusHeader';

import { Branding } from '../branding.interface';

export const educatiusBranding: Branding = {
  header: <EducatiusHeader />,
  headerHeight: 11,
  footer: <EducatiusFooter />,
  fontFamily: 'TheFuture, sans-serif',
  paletteOptions: {
    primary: {
      main: '#00968f',
      // TODO: proper light variant?
      light: '#00968f22',
      dark: '#005151',
      contrastText: '#ffffff',
    },
    titleGradientColor1: '#00968f',
    titleGradientColor2: '#00968f',
  },
};
