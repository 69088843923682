import CheckIcon from '@mui/icons-material/Check';
import { Card, Stack, Typography } from '@mui/material';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

import { FilterFlag } from '../filter-flag.type';

interface EsolSupportFilterProps {
  value: FilterFlag;
  onChange: (hasEsolSupport: FilterFlag) => void;
  onDiscard: () => void;
}

export const EsolSupportFilter = ({ onDiscard }: EsolSupportFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="ESOL Support" onDiscard={onDiscard} />
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckIcon />
        <Typography pt={'5px'}>English as second / other spoken language support</Typography>
      </Stack>
    </Card>
  );
};
