import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';
import { setSentryUser } from 'utils/sentry';

export const useSentry = () => {
  const { user } = useAuth0<AuthUser>();
  const { environment } = getConfig();

  useEffect(() => {
    if (environment === 'local') {
      console.info(`Skipping Sentry initialisation in local environment`);
      return;
    }

    if (user) {
      setSentryUser(user);
    }
  }, [environment, user]);
};
