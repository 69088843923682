import { Autocomplete, Card, Skeleton, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { ProfileData } from 'types/profile.interface';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

interface CityFilterProps {
  value: ProfileData['city'];
  onChange: (newCity: ProfileData['city']) => void;
  onDiscard: () => void;
}

export const CityFilter = ({ value, onChange, onDiscard }: CityFilterProps) => {
  const fetchCityOptionsUrl = `/profiles/filter-options/cities`;

  const [cityNames, cityNamesStatus] = useAsync<string[]>(fetchCityOptionsUrl);
  const cityOptions = useMemo(() => cityNames?.map((label) => ({ label })) || [], [cityNames]);

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="City" onDiscard={onDiscard} />
      <Autocomplete
        id="city-autocomplete"
        options={cityOptions}
        isOptionEqualToValue={(opt, val) => opt.label === val.label}
        value={{ label: value }}
        onChange={(event, value) => onChange(value?.label || '')}
        renderInput={(params) => (cityNamesStatus === 'success' ? <TextField {...params} label="City" /> : <Skeleton animation="wave" />)}
        sx={{ flex: 1 }}
      />
    </Card>
  );
};
