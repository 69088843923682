import { Box, Typography } from '@mui/material';
import Logo from 'assets/images/logo-enroller.svg';
import { Link } from 'react-router-dom';

export const PageNotFound = () => (
  <Box minHeight="100vh" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
    <Link to="/">
      <img src={Logo} alt="Enroller - Advanced Enrolment Software &trade;" width="350px" />
    </Link>
    <Typography variant="h2" my={5}>
      Page not found.
    </Typography>
    <Typography variant="h3">
      <Link to="/">Take me home!</Link>
    </Typography>
  </Box>
);
