import { Stack, useMediaQuery, useTheme } from '@mui/material';

import { ProfileClustersMap } from './ProfileClustersMap';
import { ProfileSummaryCardGrid } from './ProfileSummaryCardGrid';
import { useMapViewOpenState } from './MapViewOpenStateContext';

interface ProfileResultsSectionProps {
  height: string;
}

export const ProfileResultsSection = ({ height }: ProfileResultsSectionProps) => {
  const { isOpen: isMapViewOpen } = useMapViewOpenState();
  const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));

  const showMapView = isNotMobile && isMapViewOpen;

  return (
    <Stack direction="row" height={height} maxHeight={height} minHeight={height}>
      {showMapView && <ProfileClustersMap />}
      <ProfileSummaryCardGrid halfWidth={showMapView} />
    </Stack>
  );
};
