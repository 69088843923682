import { PricingRegion } from '../types/pricing-region.enum';

export const CountryRegionMappings: { [key: number]: PricingRegion } = {
  // European Economic Area / EU
  40: PricingRegion.EuropeanEconomicArea, // Austria
  56: PricingRegion.EuropeanEconomicArea, // Belgium
  100: PricingRegion.EuropeanEconomicArea, // Bulgaria
  191: PricingRegion.EuropeanEconomicArea, // Croatia
  196: PricingRegion.EuropeanEconomicArea, // Cyprus
  203: PricingRegion.EuropeanEconomicArea, // Czech Republic
  208: PricingRegion.EuropeanEconomicArea, // Denmark
  233: PricingRegion.EuropeanEconomicArea, // Estonia
  246: PricingRegion.EuropeanEconomicArea, // Finland
  250: PricingRegion.EuropeanEconomicArea, // France
  276: PricingRegion.EuropeanEconomicArea, // Germany
  300: PricingRegion.EuropeanEconomicArea, // Greece
  348: PricingRegion.EuropeanEconomicArea, // Hungary
  352: PricingRegion.EuropeanEconomicArea, // Iceland
  380: PricingRegion.EuropeanEconomicArea, // Italy
  428: PricingRegion.EuropeanEconomicArea, // Latvia
  440: PricingRegion.EuropeanEconomicArea, // Lithuania
  442: PricingRegion.EuropeanEconomicArea, // Luxembourg
  470: PricingRegion.EuropeanEconomicArea, // Malta
  528: PricingRegion.EuropeanEconomicArea, // Netherlands
  616: PricingRegion.EuropeanEconomicArea, // Poland
  620: PricingRegion.EuropeanEconomicArea, // Portugal
  642: PricingRegion.EuropeanEconomicArea, // Romania
  703: PricingRegion.EuropeanEconomicArea, // Slovakia
  705: PricingRegion.EuropeanEconomicArea, // Slovenia
  724: PricingRegion.EuropeanEconomicArea, // Spain
  752: PricingRegion.EuropeanEconomicArea, // Sweden

  // Non-EU EEA countries
  372: PricingRegion.EuropeanEconomicArea, // Ireland
  438: PricingRegion.EuropeanEconomicArea, // Liechtenstein
  578: PricingRegion.EuropeanEconomicArea, // Norway

  // China
  156: PricingRegion.China,

  // Everything else is ROW
};

export const getRegionFromCountryId = (countryId?: number): PricingRegion => {
  return countryId ? CountryRegionMappings[countryId] || PricingRegion.RestOfWorld : PricingRegion.RestOfWorld;
};
