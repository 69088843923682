import { Alert, Box, Paper, Stack, Typography, useTheme } from '@mui/material';

export const EducatiusPricingMessage = () => {
  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  return (
    <section>
      <Paper sx={{ mb: '10px' }}>
        <Box style={{ padding: '20px' }}>
          <Stack>
            {/* TODO: Refactor this into the StudyProgramsSection component instead of duplicating the section heading here under certain conditions*/}
            <Typography mb="20px" variant="h5" fontSize="24px" fontWeight={700} color={titleColor} flex={1}>
              Programs and Pricing
            </Typography>

            <Alert
              severity="info"
              color="info"
              sx={{
                mb: 2,
                '& .MuiAlert-icon': {
                  color: theme.palette.secondary.dark,
                },
              }}
            >
              <Typography>Pricing details coming soon</Typography>
            </Alert>
          </Stack>
        </Box>
      </Paper>
    </section>
  );
};
