import CheckIcon from '@mui/icons-material/Check';
import { Card, Stack, Typography } from '@mui/material';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

import { FilterFlag } from '../filter-flag.type';

interface UsDiplomaFilterProps {
  value: FilterFlag;
  onChange: (hasUsDiploma: FilterFlag) => void;
  onDiscard: () => void;
}

export const UsDiplomaFilter = ({ onDiscard }: UsDiplomaFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="US Diploma Year" onDiscard={onDiscard} />
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckIcon />
        <Typography pt={'5px'}>US Diploma year offered</Typography>
      </Stack>
    </Card>
  );
};
