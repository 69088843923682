import { Box, Typography } from '@mui/material';
import { Feature, SubjectFeature } from 'types/profile.interface';

interface FeatureListBoxProps {
  features: (Feature | SubjectFeature)[];
  featureWidth: string;
}

export const FeatureListBox = ({ features, featureWidth }: FeatureListBoxProps) => {
  if (features.length < 1) return <Typography pl={3}>No data available</Typography>;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="start"
      alignContent="start"
      flexWrap="wrap"
      height="100%"
      width="100%"
      py={0}
      pl={3}
      sx={{ overflowY: 'auto' }}
    >
      {features.map((feature: Feature | SubjectFeature) => (
        <Typography key={feature.id} display="inline" textOverflow="wrap" width={featureWidth} mb={1}>
          {feature.name}
        </Typography>
      ))}
    </Box>
  );
};
