import ReactGA from 'react-ga4';
import { Box, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { ProfileData } from 'types/profile.interface';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';

export const ApplyButton = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const { user } = useAuth0<AuthUser>();

  const isEducatiusAgent = user?.userData.contractedWithEducatius;
  const isEducatiusProfile = Boolean(profile?.isExternallyManaged) && Boolean(profile?.educatiusSchoolId);

  // if educatius agent viewing Educatius institution
  const shouldRenderEducatiusApplyButton = isEducatiusAgent && isEducatiusProfile;
  // if agent is connected with Enroller institution
  const shouldRenderEnrollerApplyButton = user?.userData.roles.includes('agent') && profile.connected;

  if (!shouldRenderEducatiusApplyButton || !shouldRenderEnrollerApplyButton) {
    return <></>;
  }

  const { enrolmentsUrl } = getConfig();
  const applyLink = shouldRenderEducatiusApplyButton ? `https://www.gozapp.com/login.aspx` : `${enrolmentsUrl}/${profile.institutionSlug}/applicants/new`;

  const onApplyClick = () => {
    ReactGA.event({ category: 'Apply Button', label: profile.name, action: 'Clicked' });
    window.open(applyLink, '_blank');
  };

  return (
    <Box bgcolor="white" borderRadius="6px">
      <Button startIcon={<TaskAltIcon />} color="inherit" variant="outlined" onClick={onApplyClick}>
        Apply
      </Button>
    </Box>
  );
};
