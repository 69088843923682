import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Alert, AlertTitle, Grid, Stack, Typography, useTheme } from '@mui/material';
import { Pill } from 'components/Pill';
import { editorIsEmpty, SlateEditor } from 'components/SlateEditor';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

interface SectionHeaderProps {
  control: Control<EditableProfileFields>;
}

const SectionHeader = ({ control }: SectionHeaderProps) => {
  const description = useWatch({
    control,
    name: 'description',
  });
  const isComplete = !editorIsEmpty(description);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        School Description
      </Typography>
      <Pill
        label={isComplete ? 'Complete' : 'Incomplete'}
        color={isComplete ? 'success' : 'error'}
        icon={isComplete ? <DoneIcon color="success" /> : <WarningAmberIcon color="error" />}
      />
    </Stack>
  );
};

export interface SchoolDescriptionProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const SchoolDescription = ({ errors, control }: SchoolDescriptionProps) => {
  const theme = useTheme();

  return (
    <section id="school-description-section">
      <SectionHeader control={control} />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <Controller
            name="description"
            control={control}
            rules={{ required: 'School description is required.', validate: { empty: (val) => !editorIsEmpty(val) } }}
            render={({ field: { value, onChange, onBlur } }) => {
              return (
                <SlateEditor
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  boxStyle={{ height: 200, overflowY: 'scroll' }}
                  inErrorState={!!errors.description}
                />
              );
            }}
          />
          {errors.description ? <Typography color="error">School description is required.</Typography> : <br />}
        </Grid>
        <Grid item xs={6}>
          <Alert
            severity="info"
            color="info"
            sx={{
              '& .MuiAlert-icon': {
                color: theme.palette.secondary.dark,
              },
            }}
          >
            <AlertTitle>Standout</AlertTitle>
            Please add a description that captures your value proposition and clearly differentiates your institution from others
          </Alert>
        </Grid>
      </Grid>
    </section>
  );
};
