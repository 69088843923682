import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, Typography } from '@mui/material';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { canUser } from 'utils/userRoles';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useParams } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { Permissions } from 'types/permissions.enum';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { StudyProgramPeriodDescription } from './StudyProgramPeriodDescription';

export interface StudyProgramPeriodBlockProps {
  studyProgramPeriod: StudyProgramPeriod;
  costFormatter: Intl.NumberFormat;
  showCommission: boolean;
}

export const StudyProgramPeriodBlock = ({ studyProgramPeriod, costFormatter, showCommission }: StudyProgramPeriodBlockProps) => {
  const {
    state: { profile, isSharedView },
  } = useProfile();
  const { user } = useAuth0<AuthUser>();
  const params = useParams();
  const isPdfFormat = useIsPdfFormat();
  const currency = profile!.currency;

  const showPricing = isPdfFormat ? false : isSharedView ? params.vp === '1' : canUser(Permissions.VIEW_PRICING, user);
  const shouldRenderAdditionalFees = Boolean(
    studyProgramPeriod.insuranceFee || studyProgramPeriod.applicationFee || studyProgramPeriod.accommodationFee || studyProgramPeriod.otherFees,
  );

  return (
    <Box display="flex" flexDirection="row">
      {/* Availability */}
      <Box flex="1">
        <StudyProgramPeriodDescription studyProgramPeriod={studyProgramPeriod} />
      </Box>
      {/* Pricing */}
      {showPricing && (
        <Stack direction="column" width="280px">
          {/* Base price */}
          <Stack direction="row" justifyContent="space-between" mb={1}>
            <Typography variant="h6" fontWeight="500" mr="15px">
              Program
            </Typography>
            <Typography variant="h6" fontWeight="600">
              {costFormatter.format(parseFloat(studyProgramPeriod.basePrice))} {currency}
            </Typography>
          </Stack>

          {/* Additional Fees */}
          {shouldRenderAdditionalFees && (
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" fontWeight="500" fontSize="16px" mr="15px">
                Additional Fees
              </Typography>
              <Stack direction="column">
                {studyProgramPeriod.insuranceFee && studyProgramPeriod.insuranceFee !== '0' && (
                  <Typography variant="caption" alignSelf="end" mb={0.5}>
                    Insurance: {costFormatter.format(parseFloat(studyProgramPeriod.insuranceFee))}
                  </Typography>
                )}
                {studyProgramPeriod.applicationFee && studyProgramPeriod.applicationFee !== '0' && (
                  <Typography variant="caption" alignSelf="end" mb={0.5}>
                    Application fee: {costFormatter.format(parseFloat(studyProgramPeriod.applicationFee))}
                  </Typography>
                )}
                {studyProgramPeriod.accommodationFee && studyProgramPeriod.accommodationFee !== '0' && (
                  <Typography variant="caption" alignSelf="end" mb={0.5}>
                    Accommodation: {costFormatter.format(parseFloat(studyProgramPeriod.accommodationFee))}
                  </Typography>
                )}
                {studyProgramPeriod.otherFees && studyProgramPeriod.otherFees !== '0' && (
                  <Typography variant="caption" alignSelf="end" mb={0.5}>
                    Other fees: {costFormatter.format(parseFloat(studyProgramPeriod.otherFees))}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}

          {/* Commission */}
          {showCommission && !isSharedView && studyProgramPeriod.commission && (
            <Box display="flex" flexDirection="row" justifyContent="space-between" mt={2}>
              <Typography variant="body1" fontWeight="500">
                Commission
              </Typography>
              <Typography variant="body2">
                {costFormatter.format(parseFloat(studyProgramPeriod.commission))} {currency}
              </Typography>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};
