export const religiousAffiliationOptions = [
  `Anglican`,
  `Assyrian Apostolic`,
  `Australian Aboriginal Traditional Religions`,
  `Baha'i`,
  `Baptist`,
  `Brethren`,
  `Buddhism`,
  `Catholic`,
  `Chinese Religions`,
  `Churches of Christ`,
  `Druse`,
  `Eastern Orthodox`,
  `Hinduism`,
  `Islam`,
  `Japanese Religions`,
  `Jehovah's Witnesses`,
  `Judaism`,
  `Latter-day Saints`,
  `Lutheran`,
  `Miscellaneous Religions`,
  `Nature Religions`,
  `Oriental Orthodox`,
  `Other Christian`,
  `Other Protestant`,
  `Other Spiritual Beliefs`,
  `Pentecostal`,
  `Presbyterian and Reformed`,
  `Salvation Army`,
  `Secular Beliefs`,
  `Seventh-day Adventist`,
  `Sikhism`,
  `Spiritualism`,
  `Uniting Church`,
];
