import { Paper, Typography, useTheme } from '@mui/material';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { FileRoles } from 'types/file-roles.enum';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';
import { ProfileData } from 'types/profile.interface';

import { ImageVideoCarousel } from './ImageVideoCarousel';
import { ImageGrid } from './ImageGrid';

export const GallerySection = () => {
  const isPdfFormat = useIsPdfFormat();

  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const files = profile.files || [];
  const photos = files.filter((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_PHOTOS);
  const videoUrls = profile.videoUrls;

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  return (
    <section>
      <Paper style={{ padding: 20, marginBottom: '20px' }}>
        <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor} mb={3}>
          Gallery
        </Typography>
        {isPdfFormat && <ImageGrid imageFiles={photos} />}
        {!isPdfFormat && <ImageVideoCarousel imageFiles={photos} videoUrls={videoUrls} />}
      </Paper>
    </section>
  );
};
