import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAsync } from 'hooks/use-async';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { FileRoles } from 'types/file-roles.enum';
import { fileSize } from 'utils';
import { capitaliseFirst } from 'utils/capitalise-first';

import { DeleteButton } from './DeleteButton';
import { DownloadButton } from './DownloadButton';
import { UploadDocumentsModal } from './UploadDocumentsModal';

const documentRoles = [FileRoles.FILE_ROLE_MARKETING_MATERIALS, FileRoles.FILE_ROLE_PROSPECTUS, FileRoles.FILE_ROLE_CONTRACTS_AGREEMENTS];

export const DocumentsSection = () => {
  const { profileSlug } = useParams();
  // TODO: Loading state (skeleton somewhere?)
  // TODO: filter on file types
  const [files, getFilesStatus, refetchFiles] = useAsync<IFile[]>(`/profiles/${profileSlug}/files`);
  const [documents, setDocuments] = useState<IFile[]>([]);

  const sortDocuments = (a: IFile, b: IFile) => (a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : -1);

  // Preserve the list of documents between re-fetches triggered by adding or removing files
  useEffect(() => {
    if (getFilesStatus === 'success') {
      const docFiles = files?.filter((f) => documentRoles.includes(f.role)) || [];
      setDocuments(docFiles.sort(sortDocuments));
    }
  }, [getFilesStatus, files]);

  // Track open state of the document role menu for uploading new documents
  const [roleMenuAnchorEl, setRoleMenuAnchorEl] = useState<HTMLElement | null>(null);

  // Role that the upload documents modal will create files for on upload
  const [modalRole, setModalRole] = useState<FileRoles>(documentRoles[0]);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const openUploadModalForRole = (role: FileRoles) => () => {
    setModalRole(role);
    setRoleMenuAnchorEl(null);
    setIsUploadModalOpen(true);
  };
  const closeUploadModal = (newDocuments: IFile[]) => {
    setIsUploadModalOpen(false);
    if (newDocuments.length === 0) return;
    setDocuments((d) => d.concat(newDocuments).sort(sortDocuments));
    // Give time for any in-flight requests to finish
    setTimeout(() => refetchFiles(), 1000);
  };

  const removeDocument = (document: IFile) => () => {
    const index = documents.findIndex((d) => d === document);
    setDocuments((f) => [...f.slice(0, index), ...f.slice(index + 1)]);
  };

  return (
    <section id="documents-section">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        Documents
      </Typography>
      <TableContainer component={Card}>
        <Stack direction="row" p={2}>
          <Typography fontWeight="500" sx={{ alignSelf: 'center' }}>
            Files uploaded
          </Typography>
          <Box flex={1} />
          <Tooltip title="Refresh list">
            <span>
              <IconButton aria-label="refresh list" disabled={getFilesStatus === 'pending'} onClick={() => refetchFiles()} sx={{ mr: 1 }}>
                {getFilesStatus === 'pending' ? <CircularProgress size="24px" /> : <RefreshOutlined />}
              </IconButton>
            </span>
          </Tooltip>
          <Button
            id="document-upload-menu-button"
            variant="outlined"
            color="secondary"
            aria-controls={roleMenuAnchorEl ? 'document-upload-role-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={roleMenuAnchorEl ? 'true' : undefined}
            onClick={(event) => setRoleMenuAnchorEl(event.currentTarget)}
            endIcon={<CloudUploadOutlinedIcon />}
          >
            Upload documents
          </Button>
          <Menu
            id="document-upload-role-menu"
            anchorEl={roleMenuAnchorEl}
            open={roleMenuAnchorEl !== null}
            onClose={() => setRoleMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'document-upload-menu-button',
            }}
          >
            {documentRoles.map((role) => (
              <MenuItem key={role} onClick={openUploadModalForRole(role)}>
                {capitaliseFirst(role.replaceAll('-', ' '))}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <Divider />
        <Table sx={{ minWidth: 650 }} aria-label="documents table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'background.default' }}>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  File name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  Category
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  File size
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  Last updated
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="secondary" fontWeight="500">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.length === 0 && (
              <TableRow>
                <TableCell>
                  <Button
                    id="document-upload-menu-button-2"
                    variant="text"
                    aria-controls={roleMenuAnchorEl ? 'document-upload-role-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={roleMenuAnchorEl ? 'true' : undefined}
                    onClick={(event) => setRoleMenuAnchorEl(event.currentTarget)}
                    endIcon={<CloudUploadOutlinedIcon />}
                  >
                    Upload your first document
                  </Button>
                </TableCell>
              </TableRow>
            )}
            {documents.map((document) => (
              <TableRow key={document.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Stack direction="row">
                    <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
                      <DescriptionOutlinedIcon sx={{ color: 'primary.dark' }} />
                    </Avatar>
                    <Stack direction="column">
                      <Typography fontWeight="500">{document.filename}</Typography>
                      <Typography color="secondary">PDF document</Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>
                  <Typography>{document.role.replaceAll('-', ' ')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{document.size ? fileSize(document.size) : '-'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{moment(document.createdAt).format('MMM D, yyyy')}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" justifyContent="flex-end">
                    <DownloadButton file={document} />
                    <DeleteButton file={document} onDelete={removeDocument(document)} />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UploadDocumentsModal isOpen={isUploadModalOpen} fileRole={modalRole} onClose={closeUploadModal} />
    </section>
  );
};
