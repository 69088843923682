import { Point } from 'geojson';

export const createGeoJSONCircle = (center: Point, radiusInKm: number, points: number = 64) => {
  const [longitude, latitude] = center.coordinates;

  const km = radiusInKm;

  const ret = [];
  const distanceX = km / (111.32 * Math.cos((latitude * Math.PI) / 180));
  const distanceY = km / 110.574;

  let theta, x, y;
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI);
    x = distanceX * Math.cos(theta);
    y = distanceY * Math.sin(theta);

    ret.push([longitude + x, latitude + y]);
  }
  ret.push(ret[0]);

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [ret],
          },
        },
      ],
    },
  };
};
