import { Card, FormControl, MenuItem, Select, Skeleton } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { Curriculum } from 'types/profile.interface';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

interface IntlCurriculumFilterProps {
  value: Curriculum['name'];
  onChange: (newCurriculum: Curriculum['name']) => void;
  onDiscard: () => void;
}

export const IntlCurriculumFilter = ({ value, onChange, onDiscard }: IntlCurriculumFilterProps) => {
  /**
   * Note that if it's important that only curriculums that are shown on a profile on the domain be shown,
   * then we should implement /profiles/filter-options/curriculums and switch to that.
   * This doesn't seem particuluarly important while we have only 2 international curriculms however.
   */
  const fetchCurriculumOptionsUrl = `/curriculums`;

  const [curriculums, curriculumsStatus] = useAsync<Curriculum[]>(fetchCurriculumOptionsUrl);
  const curriculumOptions = useMemo(() => curriculums?.map((c) => ({ label: c.name })) || [], [curriculums]);

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="International Curriculum" onDiscard={onDiscard} />
      {curriculumsStatus === 'pending' && <Skeleton variant="rectangular" animation="wave" width="100%" height="40px" />}
      {curriculumOptions?.length > 0 && (
        <FormControl fullWidth>
          <Select id="curriculum-filter-select" value={value} onChange={(event) => onChange(event.target.value)} size="small" displayEmpty>
            <MenuItem disabled value="" sx={{ display: 'none' }}>
              Select...
            </MenuItem>
            {curriculumOptions.map((c) => (
              <MenuItem value={c.label}>{c.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Card>
  );
};
