import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';
import { Navigate } from 'react-router-dom';

import { loginConfig } from './login-config';

export const Login = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Navigate to="/marketing" />;
  }

  // We do not set a `prompt` as auth0 will automatically infer which one we need
  // When a new user logs in, it will use the `consent` prompt which is needed for first time logins
  // After that it will use the `silent` login and not prompt them to do anything
  loginWithRedirect(loginConfig('/marketing'));

  return <Loader />;
};
