import { createContext, ReactNode, useContext, useState } from 'react';

type FilterDrawerStateContextData = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const FilterDrawerStateContext = createContext<FilterDrawerStateContextData | undefined>(undefined);

interface FilterDrawerStateProviderProps {
  children: ReactNode;
}

export const FilterDrawerStateProvider = ({ children }: FilterDrawerStateProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <FilterDrawerStateContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
    >
      {children}
    </FilterDrawerStateContext.Provider>
  );
};

export const useFilterDrawerState = () => {
  const context = useContext(FilterDrawerStateContext);

  if (context === undefined) {
    throw new Error('useFilterDrawerState must be used within a FilterDrawerStateProvider');
  }

  return context;
};
