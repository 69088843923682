import mapboxgl from 'mapbox-gl';

const clusterCircleSizeSteps = [20, 20, 25, 50, 30, 125, 35, 250, 40, 500, 45, 750, 50, 1500, 55];

export const addMapClustersLayer = (map: mapboxgl.Map, themeColor: string) => {
  // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
  if (map.getLayer('clusters')) return;
  map.addLayer({
    id: 'clusters',
    type: 'circle',
    source: 'points',
    filter: ['has', 'point_count'],
    paint: {
      'circle-stroke-color': '#FFFFFF',
      'circle-stroke-width': 3,
      'circle-color': themeColor,
      'circle-radius': ['step', ['get', 'point_count'], ...clusterCircleSizeSteps],
    },
  });
};

export const addMapClustersCountLayer = (map: mapboxgl.Map) => {
  if (map.getLayer('cluster-count')) return;
  map.addLayer({
    id: 'cluster-count',
    type: 'symbol',
    source: 'points',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': ['get', 'point_count_abbreviated'],
      'text-font': ['Arial Unicode MS Bold'],
      'text-size': 16,
    },
    paint: {
      'text-color': '#ffffff',
    },
  });
};

export const addMapUnclusteredPointsLayer = (map: mapboxgl.Map, themeColor: string) => {
  if (map.getLayer('unclustered-point')) return;
  map.addLayer({
    id: 'unclustered-point',
    type: 'circle',
    source: 'points',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': themeColor,
      'circle-radius': 10,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#ffffff',
    },
  });
};
