import { FormControlLabel, Switch } from '@mui/material';
import { ChangeEvent } from 'react';
import ReactGA from 'react-ga4';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { useMapViewOpenState } from 'app/marketing/ProviderList/MapViewOpenStateContext';

export const MapViewToggle = () => {
  const { isOpen, setIsOpen } = useMapViewOpenState();
  const { updateGeoBounds } = useFilters();

  const onToggleShowMapViewChange = (ev: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!checked) updateGeoBounds('');
    if (checked) ReactGA.event({ category: 'Map toggle on', action: 'Clicked' });
    setIsOpen(checked);
  };

  return <FormControlLabel control={<Switch checked={isOpen} onChange={onToggleShowMapViewChange} />} label="Map View" />;
};
