import { AuthUser } from 'types/user.interface';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { PricingOverrideSelector } from 'components/PricingOverrideSelector';
import { CommissionCSVDownloadButton } from 'app/marketing/ProviderList/ProfileSearchHeader/CommissionCSVDownloadButton';
import { MapViewToggle } from 'app/marketing/ProviderList/ProfileSearchHeader/MapViewToggle';
import { ProfileSortButton } from 'app/marketing/ProviderList/ProfileSearchHeader/ProfileSortButton';
import { ProfileSearchField } from 'app/marketing/ProviderList/ProfileSearchHeader/ProfileSearchField';
import { FilterCountChip } from 'app/marketing/ProviderList/ProfileSearchHeader/FilterCountChip';
import { ProfileBrowseTotal } from 'app/marketing/ProviderList/ProfileSearchHeader/ProfileBrowseTotal';
import { useIsIframed } from 'hooks/use-is-iframed';
import mapboxgl from 'mapbox-gl';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';
import { PricingRegion } from 'types/pricing-region.enum';

import { ProfileFilterButton } from './ProfileFilterButton';

interface ProfileSearchHeaderProps {
  height: number;
}

export const ProfileSearchHeader = ({ height }: ProfileSearchHeaderProps) => {
  const { user } = useAuth0<AuthUser>();
  const theme = useTheme();
  const isIframed = useIsIframed();
  const isMapboxSupported = mapboxgl.supported();
  const isNotMobile = useMediaQuery(theme.breakpoints.up('md'));

  const {
    state: { filters },
    updateFilters,
  } = useFilters();

  const onPricingOverrideChange = ({ newCostType, newRegion }: { newCostType: 'net' | 'gross'; newRegion: PricingRegion }) => {
    updateFilters({ ...filters, pricingOverrideRegion: newRegion, pricingOverrideCostType: newCostType });
  };

  const showMapToggle = isMapboxSupported && isNotMobile && !isIframed;

  const isEnrollerAdmin = user?.userData.roles.includes('enroller-admin');
  const isEducatiusStaff = user?.userData.roles.includes('educatius-staff');

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <ProfileSearchField />
      <ProfileBrowseTotal />
      <FilterCountChip />
      <Box flex={1} />
      {isEducatiusStaff && (
        <PricingOverrideSelector costType={filters.pricingOverrideCostType} region={filters.pricingOverrideRegion} onChange={onPricingOverrideChange} />
      )}
      {isEnrollerAdmin && <CommissionCSVDownloadButton />}
      {showMapToggle && <MapViewToggle />}
      <ProfileSortButton />
      <ProfileFilterButton headerHeight={height} />
    </Stack>
  );
};
