import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Box, Button, FormControl, IconButton, Modal, Stack, Tooltip, Typography, useTheme } from '@mui/material';

interface UnsavedChangesModalProps {
  isOpen: boolean;
  onSave: () => void;
  onDiscard: () => void;
  onClose: () => void;
}

export const UnsavedChangesModal = ({ isOpen, onSave, onDiscard, onClose }: UnsavedChangesModalProps) => {
  const theme = useTheme();

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Modal open={isOpen} aria-labelledby="unsaved-changes-modal-title" aria-describedby="unsaved-changes-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Box>
            <Stack direction="row" alignItems="center">
              <Typography id="unsaved-changes-modal-title" variant="h6" component="h2">
                Unsaved changes
              </Typography>
              <Box flex={1} />
              <Tooltip title="Cancel">
                <span>
                  <IconButton aria-label="cancel" onClick={onClose}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Typography id="unsaved-changes-modal-description" sx={{ mb: 3, mt: 2 }} color="secondary" variant="body2">
              You are navigating away from this section with unsaved changes. Do you want to save or discard the changes?
            </Typography>
            <FormControl fullWidth>
              <Stack direction="row" spacing={1}>
                <Button fullWidth variant="contained" onClick={onDiscard} color="error" endIcon={<DeleteOutlinedIcon />}>
                  Discard
                </Button>
                <Button fullWidth variant="contained" onClick={onSave} color="primary" endIcon={<TaskAltIcon />}>
                  Save changes
                </Button>
              </Stack>
            </FormControl>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
