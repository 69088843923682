import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, useTheme } from '@mui/material';
import { FeatureCategories } from 'types/features.enum';
import { useEffect, useState } from 'react';

import { FeatureListBox } from './FeatureListBox';
import { FeatureViewData } from './FeatureViewData.type';

interface FeaturesTabbedViewProps {
  featureViewData: FeatureViewData;
}

export const FeaturesTabbedView = ({ featureViewData }: FeaturesTabbedViewProps) => {
  const [currentTab, setCurrentTab] = useState<FeatureCategories>(FeatureCategories.Subjects);

  const theme = useTheme();

  useEffect(() => {
    const featuresArray = [featureViewData.Subjects, featureViewData.Arts, featureViewData.Music, featureViewData.Sports, featureViewData.Extracurricular];
    const firstListWithEntries = featuresArray.find((list) => (list?.length || 0) > 0) || featureViewData.Subjects;
    const firstTabIndex = featuresArray.indexOf(firstListWithEntries);
    setCurrentTab(Object.values(FeatureCategories)[firstTabIndex].toString() as FeatureCategories);
  }, [featureViewData]);

  return (
    <Box flexGrow={1} typography="body1" display="flex" height={250} mt={3}>
      <TabContext value={currentTab}>
        <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
          <TabList
            orientation="vertical"
            onChange={(event, newValue) => setCurrentTab(newValue)}
            sx={{
              '& button': { background: theme.palette.background.default },
              '& button.Mui-selected': { background: theme.palette.background.paper },
            }}
          >
            <Tab label={FeatureCategories.Subjects} value={FeatureCategories.Subjects} />
            <Tab label={FeatureCategories.Arts} value={FeatureCategories.Arts} />
            <Tab label={FeatureCategories.Music} value={FeatureCategories.Music} />
            <Tab label={FeatureCategories.Sports} value={FeatureCategories.Sports} />
            <Tab label={FeatureCategories.Extracurricular} value={FeatureCategories.Extracurricular} />
          </TabList>
        </Box>
        <TabPanel value={FeatureCategories.Subjects} sx={{ py: 1, px: 2, width: '100%' }}>
          <FeatureListBox features={featureViewData.Subjects || []} featureWidth="50%" />
        </TabPanel>
        <TabPanel value={FeatureCategories.Arts} sx={{ py: 1, px: 2, width: '100%' }}>
          <FeatureListBox features={featureViewData.Arts || []} featureWidth="50%" />
        </TabPanel>
        <TabPanel value={FeatureCategories.Music} sx={{ py: 1, px: 2, width: '100%' }}>
          <FeatureListBox features={featureViewData.Music || []} featureWidth="50%" />
        </TabPanel>
        <TabPanel value={FeatureCategories.Sports} sx={{ py: 1, px: 2, width: '100%' }}>
          <FeatureListBox features={featureViewData.Sports || []} featureWidth="50%" />
        </TabPanel>
        <TabPanel value={FeatureCategories.Extracurricular} sx={{ py: 1, px: 2, width: '100%' }}>
          <FeatureListBox features={featureViewData.Extracurricular || []} featureWidth="50%" />
        </TabPanel>
      </TabContext>
    </Box>
  );
};
