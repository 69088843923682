import ReactGA from 'react-ga4';
import { Box, Button } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { useIsIframed } from 'hooks/use-is-iframed';
import { useSnackbar } from 'notistack';
import { getRegionFromCountryId } from 'utils/countryRegions';
import { PricingRegion } from 'types/pricing-region.enum';
import { canUser } from 'utils/userRoles';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'types/user.interface';
import { getUserId } from 'utils/get-user-id';
import { Permissions } from 'types/permissions.enum';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { ProfileData } from 'types/profile.interface';

export const ShareButton = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const { user } = useAuth0<AuthUser>();
  const { enqueueSnackbar } = useSnackbar();
  const isIframed = useIsIframed();

  if (isIframed) {
    return <></>;
  }

  const origin = window.location.origin;
  const region: PricingRegion = getRegionFromCountryId(user?.userData.primaryMarketCountryId);
  const shareLink = `${origin}/marketing/profiles/${profile.id}/shared-view/p/${region}/u/${getUserId(user!)}/vp/${
    canUser(Permissions.VIEW_PRICING, user) ? 1 : 0
  }`;
  const copyShareLink = async () => {
    await navigator.clipboard.writeText(shareLink);
    ReactGA.event({ category: 'Profile Shared Link Created', label: profile.name, action: 'Clicked' });
    enqueueSnackbar('Copied to clipboard!', { variant: 'success' });
  };

  return (
    <Box bgcolor="white" borderRadius="6px">
      <Button startIcon={<ShareIcon />} color="inherit" variant="outlined" onClick={copyShareLink}>
        Share
      </Button>
    </Box>
  );
};
