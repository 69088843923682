import { Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

const schoolAges = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];

interface AcceptsAgeFilterProps {
  value: number | undefined;
  onChange: (newAge: number) => void;
  onDiscard: () => void;
}

export const AcceptsAgeFilter = ({ value, onChange, onDiscard }: AcceptsAgeFilterProps) => {
  const dropdownValue = value || '';
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Accepts Age" onDiscard={onDiscard} />
      <FormControl fullWidth>
        <InputLabel id="age-filter-select-label">Select age</InputLabel>
        <Select
          labelId="age-filter-select-label"
          id="age-filter-select"
          label="Select age"
          value={dropdownValue}
          onChange={(event) => onChange(event.target.value as number)}
        >
          {schoolAges.map((n) => (
            <MenuItem key={n} value={n}>
              {n}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Card>
  );
};
