import { MouseEventHandler, ReactElement } from 'react';
import { Node as SlateNode, Text } from 'slate';

interface SlateRendererProps {
  rootSlateNode: SlateNode | SlateNode[];
  onLinkClick?: MouseEventHandler<HTMLAnchorElement>; // Support behavior such as sending a React GA event when a link is clicked
}

const serialize = (node: SlateNode, onLinkClick?: MouseEventHandler<HTMLAnchorElement>): ReactElement => {
  // key shouldn't really be random since react uses it internally to decide whether to rerender component subtrees
  // however it's not an issue since the subtree "never" changes, i.e. only with the parent slate node tree root
  const key = Math.random().toString();
  if (Text.isText(node)) {
    let text: ReactElement | string = node.text;
    if (node.bold) {
      text = <strong>{text}</strong>;
    }
    if (node.italic) {
      text = <em>{text}</em>;
    }
    if (node.underline) {
      text = <u>{text}</u>;
    }
    return <span key={key}>{text}</span>;
  }

  const children = node.children.map((childNode) => serialize(childNode, onLinkClick));

  switch (node.type) {
    case 'block-quote':
      return (
        <blockquote
          key={key}
          style={{
            padding: '0.4rem 0 0.4rem 2rem',
            backgroundImage: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="%23adadad"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></svg>')`,
            backgroundRepeat: 'no-repeat',
          }}
        >
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return <ul key={key}>{children}</ul>;
    case 'heading-three':
      return <h3 key={key}>{children}</h3>;
    case 'list-item':
      return <li key={key}>{children}</li>;
    case 'numbered-list':
      return <ol key={key}>{children}</ol>;
    case 'link':
      return (
        <a href={node.url as string} onClick={onLinkClick} key={key}>
          {children}
        </a>
      );
    default:
      return <p key={key}>{children}</p>;
  }
};

export const SlateRenderer = ({ rootSlateNode, onLinkClick }: SlateRendererProps) => {
  const isArray = Array.isArray(rootSlateNode);
  if (isArray) {
    return <>{rootSlateNode.map((rootNode) => serialize(rootNode, onLinkClick))}</>;
  }
  return <>{serialize(rootSlateNode, onLinkClick)}</>;
};
