import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Card, CardMedia, Chip, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileSummary } from 'types/profile.interface';
import { StudyProgram } from 'types/study-program.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';
import { getOptimisedImageUrl, ImageEditOptions } from 'utils/getOptimisedImageUrl';
import { canUser } from 'utils/userRoles';
import { AuthUser } from 'types/user.interface';
import { Permissions } from 'types/permissions.enum';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { grey } from '@mui/material/colors';

import { ProfileCardLink } from './ProfileCardLink';

import { FilterParams } from '../filtering/filter-params.interface';

interface ProfileCardProps {
  profile: ProfileSummary | null;
  filterParams: Readonly<FilterParams>;
  flagSrc: string | null;
}

export const ProfileCard = ({ profile, filterParams, flagSrc }: ProfileCardProps) => {
  const { user } = useAuth0<AuthUser>();

  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(true);
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(true);
  const [isFlagLoading, setIsFlagLoading] = useState<boolean>(true);

  const [isBannerError, setIsBannerError] = useState<boolean>(false);
  const [isLogoError, setIsLogoError] = useState<boolean>(false);
  const [isFlagError, setIsFlagError] = useState<boolean>(false);

  const [isHover, setIsHover] = useState(false);
  const cardBoxShadowStyle = { boxShadow: isHover ? `0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814` : '0px 1px 2px #1018280D' };
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const logo = profile?.files ? profile?.files.find((file) => file.role === FileRoles.FILE_ROLE_MARKETING_LOGO) : null;

  const bannerEditOptions: ImageEditOptions = {
    resize: { width: 850, fit: 'cover' },
  };

  const logoEditOptions: ImageEditOptions = {
    resize: { width: 75, fit: 'cover' },
  };

  const cardMediaImagePath = profile ? getOptimisedImageUrl(profile.firstImagePath, bannerEditOptions) : '';
  const logoImagePath = logo ? getOptimisedImageUrl(logo.fileUrl!, logoEditOptions) : '';

  // Profile data loading
  useEffect(() => {
    setIsProfileLoading(profile === null);
  }, [profile]);

  // Banner image loading
  useEffect(() => {
    const bannerImage = new Image();
    bannerImage.onload = () => setIsBannerLoading(false);
    bannerImage.onerror = () => {
      setIsBannerError(true);
      setIsBannerLoading(false);
    };
    if (profile?.firstImagePath) bannerImage.src = cardMediaImagePath;
    else setIsBannerLoading(false);
  }, [profile, cardMediaImagePath]);

  // Logo image loading
  useEffect(() => {
    const logoImage = new Image();
    logoImage.onload = () => setIsLogoLoading(false);
    logoImage.onerror = () => {
      setIsLogoError(true);
      setIsLogoLoading(false);
    };
    if (logo?.fileUrl) logoImage.src = logoImagePath;
    else setIsLogoLoading(false);
  }, [logo, logoImagePath]);

  // Flag image loading
  useEffect(() => {
    const flagImage = new Image();
    flagImage.onload = () => setIsFlagLoading(false);
    flagImage.onerror = () => {
      setIsFlagError(true);
      setIsFlagLoading(false);
    };
    if (flagSrc) flagImage.src = flagSrc;
    else setIsFlagLoading(false);
  }, [flagSrc]);

  const skeletonBgColor = '#4242421C'; // Not available on theme object, copied via inspector

  const avatar = (
    <Avatar
      sx={{ height: '75px', width: '75px', cursor: 'pointer', borderRadius: '0.5rem', border: '1px solid #F2F4F7', background: '#FFF' }}
      imgProps={{ style: { height: '65px', width: '65px', maxHeight: '65px', maxWidth: '65px', objectFit: 'contain' } }}
      alt={profile?.name || 'School Logo'}
      variant="square"
      src={!isLogoError ? logoImagePath : ''}
    >
      <AccountBalanceIcon sx={{ color: '#ddd', width: '40px', height: '40px' }} />
    </Avatar>
  );

  // Price tag program period logic
  // If profiles are currently filtered to a program period, display that program's price.
  // Otherwise, show the academic year program price, else if none then the calendar year program price, otherwise no price.
  const filterProgramPeriod = filterParams.pricingProgramPeriod;
  const getStudyProgramPeriod = (periodType: StudyProgramPeriodType) => (p: StudyProgram) => p.periodType === periodType && p.studyProgramPeriods.length > 0;
  const studyProgram = filterProgramPeriod
    ? profile?.studyPrograms?.find(getStudyProgramPeriod(filterProgramPeriod))
    : profile?.studyPrograms?.find(getStudyProgramPeriod(StudyProgramPeriodType.AcademicYear)) ||
      profile?.studyPrograms?.find(getStudyProgramPeriod(StudyProgramPeriodType.CalendarYear));
  const costFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: profile?.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
      }),
    [profile?.currency],
  );
  // Use first study period under the relevant program
  // TODO: Confirm this is fine, or maybe use the "Next starting" period ?
  const studyProgramPeriod = studyProgram?.studyProgramPeriods[0];
  const priceTag = studyProgramPeriod && `${costFormatter.format(parseFloat(studyProgramPeriod.basePrice))} ${profile?.currency}`;
  const showPricing = canUser(Permissions.VIEW_PRICING, user) && priceTag;

  const renderAddress = () => {
    const { city, state, country } = profile!;
    const addressParts: string[] = [];
    // Show city if exists
    if (city) addressParts.push(city);
    if (state && !addressParts.includes(state)) {
      // Show state if exists and different from city
      addressParts.push(state);
    } else if (country && state === city) {
      // Show country if exists and state same as city
      addressParts.push(country);
    }
    return addressParts.join(', ');
  };

  return (
    <ProfileCardLink profile={profile}>
      <Card sx={{ height: '280px', ...cardBoxShadowStyle }} variant="elevation" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {isProfileLoading || isBannerLoading ? (
          <Skeleton
            variant="rectangular"
            animation={isBannerError ? false : 'wave'}
            height={170}
            sx={{ cursor: 'pointer', width: 'calc(100% - 10px)', mt: '5px', mx: '5px', borderRadius: '0.5rem' }}
          />
        ) : (
          <CardMedia
            sx={{ height: 170, cursor: 'pointer', bgcolor: skeletonBgColor, mt: '5px', mx: '5px', borderRadius: '0.5rem' }}
            image={cardMediaImagePath}
            title={profile!.name}
          >
            <Stack direction="column" width="100%" height="100%" alignItems="left">
              {!isFlagLoading && (
                <Box>
                  <Chip
                    label={profile?.country || ''}
                    icon={isFlagError ? <></> : <img src={flagSrc || ''} alt={`${profile?.country} flag`.trim()} width="16px" height="16px" />}
                    size="small"
                    sx={{ m: 1, backgroundColor: 'background.paper', cursor: 'pointer' }}
                  />
                </Box>
              )}
              <Box flex="1" />
              {showPricing && (
                <Box>
                  <Chip label={priceTag} size="small" sx={{ m: 1, backgroundColor: 'background.paper', cursor: 'pointer' }} />
                </Box>
              )}
            </Stack>
          </CardMedia>
        )}

        <Stack direction="row" sx={{ px: 2, py: 2 }}>
          {isProfileLoading || isLogoLoading ? (
            <Skeleton
              variant="rectangular"
              animation={isLogoError ? false : 'wave'}
              sx={{ cursor: 'pointer', height: '75px', width: '75px', borderRadius: '0.5rem' }}
            />
          ) : (
            avatar
          )}
          <Stack direction="column" sx={{ ml: 2 }}>
            {isProfileLoading ? (
              <>
                <Skeleton variant="text" animation="wave" width="260px" sx={{ mt: 0.5, mb: 0.8 }} />
                <Skeleton variant="text" animation="wave" width="160px" />
              </>
            ) : (
              <>
                <Tooltip title={profile!.name}>
                  <Typography
                    gutterBottom
                    variant="h4"
                    sx={{
                      mb: 0.8,
                      mt: 0.5,
                      fontSize: 18,
                      fontWeight: 500,
                      color: grey[900],
                      cursor: 'pointer',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                    }}
                  >
                    {profile!.name}
                  </Typography>
                </Tooltip>
                <Typography variant="body2" mb={0.4}>
                  {renderAddress()}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    </ProfileCardLink>
  );
};
