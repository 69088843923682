import { Download } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosRequestConfig } from 'axios';
import { useAsync } from 'hooks/use-async';
import fileDownload from 'js-file-download';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { IFile } from 'types/file.interface';

interface FileDownloadButtonProps {
  file: IFile;
  profileSlug: string;
}

export const FileDownloadButton = ({ file, profileSlug }: FileDownloadButtonProps) => {
  const fileDownloadUrl = `/profiles/${profileSlug}/files/${file.id}/download`;
  const extraAxiosConfig: Partial<AxiosRequestConfig> = { responseType: 'blob' };
  const [res, status, download] = useAsync(fileDownloadUrl, 'GET', false, extraAxiosConfig);

  useEffect(() => {
    if (status === 'success' && res) {
      ReactGA.event({ category: 'File', label: profileSlug, action: 'Downloaded' });
      fileDownload(res, file.filename);
    }
  }, [status, res, file.filename, profileSlug]);

  return (
    <IconButton disabled={status === 'pending'} onClick={() => download()}>
      {status === 'pending' ? <CircularProgress size="24px" /> : <Download />}
    </IconButton>
  );
};
