import { FormatBold, FormatItalic, FormatListBulleted, FormatListNumbered, FormatQuote, FormatUnderlined, LinkOutlined, Title } from '@mui/icons-material';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import isUrl from 'is-url';
import { PropsWithChildren, SyntheticEvent, useCallback, useMemo } from 'react';
import { createEditor, Editor, Element as SlateElement, Node, Node as SlateNode, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, RenderElementProps, RenderLeafProps, Slate, useSlate, withReact } from 'slate-react';

export const editorIsEmpty = (val: SlateNode[]) => {
  return val.length === 1 && SlateNode.string(val[0]).trim().length === 0;
};

const EditableWrapper = (props: PropsWithChildren<{ sx?: SxProps<Theme> }>) => {
  const sxExtended = props.sx || {};

  return (
    <Box
      sx={{
        padding: 1,
        '& a': {
          color: 'primary.main',
          textDecoration: 'underline',
        },
        '& ul, & ol': {
          my: 2,
          pl: 4,
        },
        '& blockquote': {
          my: 0.5,
          mx: 0,
          padding: '0.4rem 0 0.4rem 2rem',
          backgroundImage: `url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="%23adadad"><path d="M0 0h24v24H0z" fill="none" /><path d="M6 17h3l2-4V7H5v6h3zm8 0h3l2-4V7h-6v6h3z"/></svg>')`,
          backgroundRepeat: 'no-repeat',
        },
        ...sxExtended,
      }}
    >
      {props.children}
    </Box>
  );
};

type EditorProps = {
  value: Node[];
  onChange: (v: Node[]) => void;
  onBlur: () => void;
  boxStyle: SxProps<Theme>;
  inErrorState?: boolean;
};

export const SlateEditor = ({ value, onChange, onBlur, boxStyle, inErrorState = false }: EditorProps) => {
  const theme = useTheme();
  const editor = useMemo(() => withLinks(withHistory(withReact(createEditor()))), []);
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);

  return (
    <Box
      sx={{
        border: `1px solid ${inErrorState ? theme.palette.error.main : theme.palette.secondary.light}`,
        borderRadius: 0.5,
        ':focus-within': { borderColor: grey[500] },
      }}
    >
      <Slate editor={editor} value={value} onChange={onChange}>
        <Box sx={{ display: 'flex', padding: 1, borderBottom: `solid 1px ${grey[300]}`, backgroundColor: grey[100], lineHeight: 0 }}>
          <BlockIcon format="heading-three" icon={<Title />} />
          <MarkIcon format="bold" icon={<FormatBold />} />
          <MarkIcon format="italic" icon={<FormatItalic />} />
          <MarkIcon format="underline" icon={<FormatUnderlined />} />
          <LinkIcon format="link" icon={<LinkOutlined />} />
          <BlockIcon format="block-quote" icon={<FormatQuote />} />
          <BlockIcon format="numbered-list" icon={<FormatListNumbered />} />
          <BlockIcon format="bulleted-list" icon={<FormatListBulleted />} />
        </Box>
        <EditableWrapper sx={boxStyle}>
          <Editable onBlur={onBlur} renderElement={renderElement} renderLeaf={renderLeaf} placeholder="Enter text…" spellCheck />
        </EditableWrapper>
      </Slate>
    </Box>
  );
};

const toggleBlock = (editor: ReactEditor, format: string) => {
  const LIST_TYPES = ['numbered-list', 'bulleted-list'];

  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes((!Editor.isEditor(n) && SlateElement.isElement(n) && String(n.type)).toString()),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const isBlockActive = (editor: ReactEditor, format: string) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const toggleMark = (editor: ReactEditor, format: string) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: ReactEditor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] : false;
};

const withLinks = (editor: ReactEditor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: SlateElement) => (element.type === 'link' ? true : isInline(element));

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor: ReactEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor: ReactEditor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === 'link' });
  return !!link;
};

const unwrapLink = (editor: ReactEditor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === 'link' });
};

const wrapLink = (editor: ReactEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: 'link',
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const Element = ({ attributes, children, element }: RenderElementProps) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      const url = element.url as string;
      return (
        <a {...attributes} href={url} title={url}>
          {children}
        </a>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }: RenderLeafProps) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Icon = ({ active, ...props }: PropsWithChildren<{ active: boolean; onMouseDown: (event: SyntheticEvent) => void }>) => (
  <Box sx={{ mx: 1, backgroundColor: active ? '#adadad' : '', cursor: 'pointer', p: 0.2 }} {...props} />
);

type IconProps = {
  format: string;
  icon: JSX.Element;
};

const BlockIcon = ({ format, icon }: IconProps) => {
  const editor = useSlate();
  return (
    <Icon
      active={isBlockActive(editor, format)}
      onMouseDown={(event: SyntheticEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </Icon>
  );
};

const MarkIcon = ({ format, icon }: IconProps) => {
  const editor = useSlate();
  return (
    <Icon
      active={isMarkActive(editor, format)}
      onMouseDown={(event: SyntheticEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </Icon>
  );
};

const LinkIcon = ({ icon }: IconProps) => {
  const editor = useSlate();
  return (
    <Icon
      active={isLinkActive(editor)}
      onMouseDown={(event: SyntheticEvent) => {
        event.preventDefault();
        const url = window.prompt('Enter the URL of the link:');
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      {icon}
    </Icon>
  );
};
