import LocationOnIcon from '@mui/icons-material/LocationOn';
import { FormControl } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import { Point } from 'geojson';
import { useAsync } from 'hooks/use-async';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getConfig } from 'utils/config';

/**
 * Adapted from MUI docs
 * @see https://mui.com/material-ui/react-autocomplete/#google-maps-place
 */

const { googleMapsApiKey } = getConfig();

type PlaceType = google.maps.places.AutocompletePrediction;

export interface AddressAutocompleteProps {
  onChange: (address: { googlePlaceId: string | null; addressLine1: string | null; latLng: Point | null }) => void;
  initialPlace: string | null;
  types?: string | null;
}

export interface autoCompleteService {
  current: null | google.maps.places.AutocompleteService;
}

interface PlaceResult {
  result: google.maps.places.PlaceResult;
}

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService: autoCompleteService = { current: null };

export const AddressAutocomplete = ({ onChange, initialPlace, types }: AddressAutocompleteProps) => {
  const initialPlaceType: PlaceType | null = initialPlace
    ? {
        description: initialPlace,
        matched_substrings: [],
        place_id: '',
        structured_formatting: {
          main_text: initialPlace,
          main_text_matched_substrings: [],
          secondary_text: '',
        },
        terms: [],
        types: [],
      }
    : null;
  const [value, setValue] = useState<PlaceType | null>(initialPlaceType);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [googlePlaceId, setGooglePlaceId] = useState<string | undefined>('');
  const loaded = useRef(false);
  const placeURL = `/place-details/${googlePlaceId}`;
  const [placeDetailsResponse, , fetchPlaceDetails] = useAsync<PlaceResult>(placeURL, 'GET', false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`, document.querySelector('head'), 'google-maps');
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (placeDetailsResponse) {
      const googlePlaceId = value?.place_id || null;
      const addressLine1 = value?.structured_formatting.main_text || null;
      // These are actually numbers despite their function typings
      const latitude = placeDetailsResponse.result.geometry?.location?.lat as unknown as number;
      const longitude = placeDetailsResponse.result.geometry?.location?.lng as unknown as number;
      const latLng = latitude && longitude ? { type: 'Point' as const, coordinates: [longitude, latitude] } : null;
      onChange({ googlePlaceId, addressLine1, latLng });
    }
  }, [placeDetailsResponse, value, onChange]);

  useEffect(() => {
    if (googlePlaceId) {
      fetchPlaceDetails();
    }
  }, [googlePlaceId, fetchPlaceDetails]);

  const fetchPredictions = useMemo(
    () =>
      debounce((request, callback) => {
        if (types) {
          request.types = [types];
        }
        autocompleteService.current!.getPlacePredictions(request, callback);
      }, 400),
    [types],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchPredictions({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: any[] | readonly PlaceType[] | ((prevState: readonly PlaceType[]) => readonly PlaceType[]) = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetchPredictions]);

  return (
    <Autocomplete
      id="google-places-autocomplete"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      sx={{ maxWidth: '42rem', paddingTop: '1rem' }}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No locations"
      onChange={(event, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        setGooglePlaceId(newValue?.place_id);
        // Handle deleting of address
        if (newValue === null) {
          onChange({ googlePlaceId: null, addressLine1: null, latLng: null });
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormControl fullWidth required>
          <TextField {...params} size="small" aria-labelledby="address-lookup-input-label" fullWidth />
        </FormControl>
      )}
      renderOption={(props, option) => {
        const matches = option?.structured_formatting?.main_text_matched_substrings || [];

        const parts = parse(
          option?.structured_formatting?.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part) => (
                  <Box key={part.text} component="span" sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
