import { Divider, FormControl, FormLabel, MenuItem, Select, Skeleton, Stack, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { Country } from 'types/country.interface';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

export interface FeesSetupSectionProps {
  control: Control<EditableProfileFields>;
  errors: FieldErrors<EditableProfileFields>;
}

export const FeesSetupSection = ({ control, errors }: FeesSetupSectionProps) => {
  const [countries, countriesStatus] = useAsync<Country[]>(`/countries`);

  const selectableCurrencyCountries = countries?.filter((c) => ['USA', 'NZL', 'AUS', 'GBR', 'CAN'].includes(c.iso_3166_3));

  return (
    <>
      <Divider sx={{ my: 2 }}>Fee setup</Divider>
      {/* TODO: ER-3363 Add standard fees */}
      {/* <Typography variant="h6" component="h3">
        Add standard fees
      </Typography>
      <Alert
        severity="info"
        color="info"
        sx={{
          '& .MuiAlert-icon': {
            color: theme.palette.secondary.dark,
          },
        }}
      >
        <AlertTitle>Standard fees are added to program tuition costs, which then result in an accurate cost of each program</AlertTitle>
        <Typography>Please enter the standard fees for each program type your school offers, so they are available when entering program tuition fees</Typography>
      </Alert> */}

      <Stack direction="row">
        <Controller
          name="currency"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl fullWidth size="small">
              <FormLabel required id="currency-selector-input-label">
                Fee currency
              </FormLabel>
              {countriesStatus !== 'success' && <Skeleton variant="rectangular" animation="wave" sx={{ width: 300, height: 40 }} />}
              {countriesStatus === 'success' && (
                <Select
                  size="small"
                  value={value ?? ''}
                  error={Boolean(errors.currency)}
                  onChange={(event) => onChange(event.target.value)}
                  sx={{ width: 300 }}
                >
                  {selectableCurrencyCountries?.map((country, i) => (
                    <MenuItem key={i} value={country.currency_code} sx={{ textTransform: 'capitalize' }}>
                      {country.currency} ({country.currency_code})
                    </MenuItem>
                  ))}
                </Select>
              )}
              {errors.currency ? <Typography color="error">{errors.currency.message}</Typography> : <br />}
            </FormControl>
          )}
        />
        {/* TODO: ER-3363 Set up fees dropdown Menu here too */}
      </Stack>

      {/* <Alert
        severity="warning"
        color="warning"
        sx={{
          '& .MuiAlert-icon': {
            color: theme.palette.warning.dark,
          },
        }}
      >
        <AlertTitle>Standard fees have not been set up</AlertTitle>
        <Typography>Please set up standard fees associated with different program types</Typography>
        TODO: Setup fees dropdown Menu
      </Alert> */}
    </>
  );
};
