import { Control, FieldErrors } from 'react-hook-form';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

import { FeesSetupSection } from './FeeSetupSection';
import { StudyProgramsSection } from './StudyProgramsSection';

interface ProgramsFeesEditTabProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const ProgramsFeesEditTab = ({ control, errors }: ProgramsFeesEditTabProps) => {
  return (
    <>
      <FeesSetupSection control={control} errors={errors} />
      <StudyProgramsSection control={control} errors={errors} />
    </>
  );
};
