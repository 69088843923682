import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';

export interface ImageWithSkeletonProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  width?: string | number | undefined;
  height?: string | number | undefined;
}
export const ImageWithSkeleton = (props: ImageWithSkeletonProps) => {
  const { src, alt, width, height } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [, setImage] = useState({});
  const [isImageError, setIsImageError] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = () => setIsLoading(false);
    image.onerror = () => setIsImageError(true);
    image.src = src!;
    setImage(image);
  }, [src]);

  return (
    <>
      {isLoading && <Skeleton variant="rectangular" animation={isImageError ? false : 'wave'} width={width} height={height} />}
      {!isLoading && <img src={src} alt={alt} {...props} />}
    </>
  );
};
