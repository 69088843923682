import { Buffer } from 'buffer';

import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { initLogRocket } from 'utils/logRocket';
import { initSentry } from 'utils/sentry';

import { App } from './app/App';
import { getConfig } from './utils/config';

const { environment, mapboxToken } = getConfig();

/**
 * Workaround for this issue in production builds
 * @see https://github.com/mapbox/mapbox-gl-js/issues/10173
 */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = mapboxToken;

// TODO: Check whether we still need this after migrating to Auth0
// CRA v5+ no longer bundles node polyfills
// @ts-ignore
window.Buffer = Buffer;

if (environment !== 'local') {
  initLogRocket();
  initSentry();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
