import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';
import { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { loginConfig } from './login-config';

interface RequireAuthForRouteProps {
  children: ReactElement;
}

export const RequireAuthForRoute = ({ children }: RequireAuthForRouteProps): ReactElement => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    const redirectPath = `${window.location.pathname}${window.location.search}`;
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setHasToken(true);
        }
      } catch {
        loginWithRedirect(loginConfig(redirectPath));
      }
    };

    if (isAuthenticated) {
      fetchToken();
    } else {
      loginWithRedirect(loginConfig(redirectPath));
    }
  }, [getAccessTokenSilently, loginWithRedirect, isAuthenticated]);

  if (isLoading || !hasToken) {
    return <Loader />;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};
