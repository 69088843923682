import { Chip } from '@mui/material';
import { useFilterDrawerState } from 'app/marketing/ProviderList/FilterDrawerStateContext';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';

import { FilterParams } from '../filtering/filter-params.interface';

// Multi-filters for which if any elements of a group are present, must only be counted once.
const multiFilterParamGroups: (keyof FilterParams)[][] = [
  ['pricingProgramPeriod', 'pricingMinUsd', 'pricingMaxUsd'],
  ['pricingOverrideRegion', 'pricingOverrideCostType'],
  ['country', 'state', 'city'],
  ['subjects', 'hasAllSubjects'],
  ['advancedSubjects', 'hasAllAdvancedSubjects'],
  ['sports', 'hasAllSports'],
];
const multiFilterParamGroupsSet = new Set<keyof FilterParams>(multiFilterParamGroups.flat());

export const FilterCountChip = () => {
  const {
    state: { filters },
    updateFilters,
  } = useFilters();

  const { isOpen: isFilterDrawerOpen, setIsOpen: setIsFilterDrawerOpen } = useFilterDrawerState();

  // Filters composed of multiple params must be presented as a single logical filter to the user in the count
  // First count the filters composed of a single param
  const activeFilterParams = Object.keys(filters) as (keyof FilterParams)[];
  const activeSingleFilterCount = activeFilterParams.filter((p: keyof FilterParams) => !multiFilterParamGroupsSet.has(p)).length;
  // Then count the filters composed by multiple parameters as per the param sets above
  const isMultiFilterActive = (mf: (keyof FilterParams)[]) => mf.some((p) => activeFilterParams.includes(p));
  const activeMultiFilterCount = multiFilterParamGroups.reduce((acc, mf) => acc + Number(isMultiFilterActive(mf)), 0);
  // Add the count of single-param filters to the count of multi-param filters
  const activeTotalFilterCount = activeSingleFilterCount + activeMultiFilterCount;

  if (activeTotalFilterCount === 0) {
    return <></>;
  }

  const appliedFiltersLabel = `${activeTotalFilterCount} filter${activeTotalFilterCount > 1 ? 's' : ''}`;

  return <Chip label={appliedFiltersLabel} clickable onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)} onDelete={() => updateFilters({})} />;
};
