import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, IconButton, Modal, Stack, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getConfig } from 'utils/config';

import { VideoUrlParser } from './video-url-parser';

type videoUrlsFields = {
  urls: { url: string }[];
};

interface AddVideosModalProps {
  isOpen: boolean;
  onClose: (newFiles: string[]) => void;
}

export const AddVideosModal = ({ isOpen, onClose }: AddVideosModalProps) => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { apiUrl } = getConfig();
  const {
    reset: formReset,
    control: formControl,
    handleSubmit,
    watch: formWatch,
    formState: { errors },
  } = useForm<videoUrlsFields>({ mode: 'onTouched', defaultValues: { urls: [{ url: '' }] } });
  const { fields, append, remove } = useFieldArray({
    control: formControl,
    name: 'urls',
  });

  const validateUrl = async (url: string): Promise<boolean> => {
    if (!url || !VideoUrlParser.isValid(url)) {
      return false;
    }
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(`${apiUrl}/api/videos/validate-url`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: VideoUrlParser.transform(url) }),
      });
      const text = await response.text();
      return text === 'true';
    } catch {
      return false;
    }
  };

  const watchFieldArray = formWatch('urls');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const onSubmit = handleSubmit((data: videoUrlsFields) => {
    onClose(data.urls.map((d) => d.url));
    formReset();
  });

  const discardAndClose = () => {
    onClose([]);
    formReset();
  };

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Modal open={isOpen} aria-labelledby="add-videos-modal-title" aria-describedby="add-videos-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <form onSubmit={onSubmit}>
            <Stack flex={1}>
              <Stack direction="row" alignItems="center">
                <Typography id="add-videos-modal-title" variant="h6" component="h2">
                  Add videos to gallery
                </Typography>
                <Box flex={1} />
                <Tooltip title="Discard">
                  <span>
                    <IconButton aria-label="discard" onClick={discardAndClose}>
                      <CloseIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
              <Box minHeight={0} overflow="auto" flex={1}>
                <Typography id="add-videos-modal-description" sx={{ mb: 3, mt: 2 }} color="secondary" variant="body2">
                  Please add any videos you wish to showcase your institution. These should be URL links to public videos already uploaded to Youtube or Vimeo.
                  Due to country restrictions, some videos may still not be available in certain locations.
                </Typography>
                <Typography variant="caption">Video URLs</Typography>
                <Stack direction="column" spacing={1} mb={1}>
                  {controlledFields.map((field, index) => {
                    return (
                      <Stack direction="row" key={index}>
                        <Stack direction="column" width="100%">
                          <Controller
                            name={`urls.${index}.url` as const}
                            control={formControl}
                            rules={{
                              validate: validateUrl,
                              required: 'URL is required.',
                            }}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                fullWidth
                                placeholder="e.g. https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                                variant="outlined"
                                size="small"
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                          {errors?.urls?.[index] && <Typography color="error">This video link is invalid</Typography>}
                        </Stack>
                        <Tooltip title="Delete">
                          <span>
                            <IconButton color="secondary" aria-label="delete" onClick={() => remove(index)}>
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Stack>
                    );
                  })}
                </Stack>
                <Button color="secondary" variant="outlined" sx={{ mr: 3 }} onClick={() => append({ url: '' })} endIcon={<AddIcon />}>
                  Add another video
                </Button>
              </Box>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Stack direction="row" spacing={1}>
                  <Button fullWidth color="secondary" variant="outlined" onClick={discardAndClose}>
                    Cancel
                  </Button>
                  <LoadingButton fullWidth variant="contained" onClick={onSubmit} color="primary" endIcon={<SaveIcon />}>
                    Save
                  </LoadingButton>
                </Stack>
              </FormControl>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
};
