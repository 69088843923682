import { Box, Button, Menu, MenuItem } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MouseEvent, useState } from 'react';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { ProfileData } from 'types/profile.interface';
import { FileRoles } from 'types/file-roles.enum';
import { Language } from 'types/language.interface';
import { useAsync } from 'hooks/use-async';

export const PdfDownloadButton = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const [languages] = useAsync<Language[]>('/languages');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const files = profile?.files || [];
  const pdfs = files.filter((file) => file.role === FileRoles.FILE_ROLE_PROFILE_PDF);

  if (pdfs.length < 1) {
    return <></>;
  }

  const languageHasPdf = (language: Language) => pdfs.some((pdf) => pdf.filename.includes(`_${language.ietfTag}_`));
  const displayLanguages = languages?.filter(languageHasPdf) || [];

  const selectLanguage = (language: Language) => {
    const selectedPdf = pdfs.find((pdf) => pdf.filename.includes(`_${language.ietfTag}_`));
    window.open(selectedPdf?.fileUrl);
    handleClose();
  };

  return (
    <Box bgcolor="white" borderRadius="6px">
      <Button
        startIcon={<FileDownloadIcon />}
        endIcon={<ExpandMoreIcon />}
        color="inherit"
        variant="outlined"
        id="profile-pdf-download-button"
        aria-controls={open ? 'profile-pdf-download-language-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        PDF
      </Button>
      <Menu
        id="profile-pdf-download-language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'profile-pdf-download-button',
        }}
      >
        {displayLanguages.map((language) => (
          <MenuItem onClick={() => selectLanguage(language)}>{language.name}</MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
