import CheckIcon from '@mui/icons-material/Check';
import { Autocomplete, Box, Card, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

import { FilterFlag } from '../filter-flag.type';
import { FilterParams } from '../filter-params.interface';

export type SubjectsTuple = Pick<FilterParams, 'subjects' | 'hasAllSubjects'>;

interface SubjectsFilterProps {
  value: SubjectsTuple;
  onChange: (newValue: SubjectsTuple) => void;
  onDiscard: () => void;
}

export const SubjectsFilter = ({ value, onChange, onDiscard }: SubjectsFilterProps) => {
  const { subjects, hasAllSubjects } = value;
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 400);

  // Params for fetching subject options
  const fetchSubjectOptionsParams = new URLSearchParams({ search: debouncedSearchTerm });
  const fetchSubjectsOptionsUrl = `/profiles/filter-options/subjects?${fetchSubjectOptionsParams}`;

  // Setup subject options
  const [subjectNames] = useAsync<string[]>(fetchSubjectsOptionsUrl);
  const [lastOptions, setLastOptions] = useState<string[]>([]);
  useEffect(() => {
    if (subjectNames?.length > 0) setLastOptions(subjectNames);
  }, [subjectNames]);

  // Don't hide options while subsequent options requests are in flight and subjectNames is null
  const subjectOptions = useMemo(() => subjectNames || lastOptions, [subjectNames, lastOptions]);

  // Bold the typed partial substring without breaking case or squashing spaces between element boundaries
  const renderPartialBoldOption = (props: HTMLAttributes<HTMLLIElement>, option: string, { selected }: { selected: boolean }) => {
    const i = option.toLowerCase().indexOf(debouncedSearchTerm);
    const len = debouncedSearchTerm.length;
    const glyphs = [...option]
      .map((char) => (char === ' ' ? <>&nbsp;</> : char))
      .map((glyph: ReactNode, pos) => (pos >= i && pos < i + len ? <strong>{glyph}</strong> : <>{glyph}</>));
    return (
      <li {...props}>
        <Stack width="100%" direction="row">
          <Box>{glyphs}</Box>
          <Box flex={1} />
          {selected && <CheckIcon color="success" />}
        </Stack>
      </li>
    );
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Subjects" onDiscard={onDiscard} />
      <Stack direction="row">
        <Select
          sx={{ height: '56px', width: '107px' }}
          value={hasAllSubjects || 'false'}
          onChange={(event) => onChange({ subjects, hasAllSubjects: event.target.value as FilterFlag })}
        >
          <MenuItem value="false">Has any</MenuItem>
          <MenuItem value="true">Has all</MenuItem>
        </Select>
        <Autocomplete
          id="subjects-filter-autocomplete"
          options={subjectOptions}
          multiple
          autoComplete
          filterOptions={(x) => x}
          disableCloseOnSelect
          value={subjects}
          onChange={(event, newValue) => onChange({ hasAllSubjects, subjects: newValue })}
          onInputChange={(event, newSearchTerms) => setSearchTerm(newSearchTerms)}
          renderInput={(params) => <TextField {...params} label="Search for subject" />}
          renderOption={renderPartialBoldOption}
          sx={{ flex: 1, mb: 1, ml: 1 }}
        />
      </Stack>
    </Card>
  );
};
