import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { AsyncStatus } from 'hooks/use-async';
import { useIsIframed } from 'hooks/use-is-iframed';
import { createContext, ReactNode, useContext, useMemo, useReducer } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { ProfileData } from 'types/profile.interface';
import { getConfig } from 'utils/config';

type ProfileAction =
  | { type: 'start update'; updates: Partial<ProfileData> }
  | { type: 'finish update'; profile: ProfileData; isSharedView: boolean }
  | { type: 'fail update'; error: any; isSharedView: boolean }
  | { type: 'start read' }
  | { type: 'finish read'; profile: ProfileData; isSharedView: boolean }
  | { type: 'fail read'; error: any; isSharedView: boolean };
type ProfileDispatch = (action: ProfileAction) => void;
type ProfileState = {
  status: AsyncStatus;
  profile: ProfileData | null;
  isSharedView: boolean;
};

const ProfileContext = createContext<{ state: ProfileState; dispatch: ProfileDispatch } | undefined>(undefined);

const profileReducer = (state: ProfileState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case 'start read':
    case 'start update':
      return { ...state, profile: null, status: 'pending' };
    case 'finish read':
    case 'finish update':
      return { status: 'success', profile: action.profile, isSharedView: action.isSharedView };
    case 'fail read':
    case 'fail update':
      console.error(action.error);
      return { status: 'error', profile: null, isSharedView: action.isSharedView };
  }
};

interface ProfileEditProviderProps {
  children: ReactNode;
  slug: string;
}

export const ProfileProvider = ({ slug, children }: ProfileEditProviderProps) => {
  const isIframed = useIsIframed();
  const isSharedView = Boolean(useMatch('/marketing/profiles/:profileId/shared-view/p/:region/u/:userId/vp/:viewPricing'));
  const [state, dispatch] = useReducer(profileReducer, { status: 'idle', profile: null, isSharedView });
  const { userId, region } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const profilePath = !isSharedView ? slug : `${slug}/shared/p/${region}/u/${userId}`; // "slug" is an id uuid when in shared view
  useMemo(() => {
    if (!isSharedView && !isIframed) {
      getAccessTokenSilently().then((token) => readProfile(token, dispatch, profilePath));
    } else {
      readProfile(null, dispatch, profilePath);
    }
  }, [isSharedView, isIframed, getAccessTokenSilently, profilePath]);

  return <ProfileContext.Provider value={{ state, dispatch }}>{children}</ProfileContext.Provider>;
};

export const useProfile = () => {
  const context = useContext(ProfileContext);

  if (context === undefined) {
    throw new Error('useProfile must be used within a ProfileProvider');
  }

  return context;
};

export const readProfile = async (token: string | null, dispatch: ProfileDispatch, profilePath: string) => {
  dispatch({ type: 'start read' });
  const isSharedView = profilePath.includes('/shared/');
  const { apiUrl } = getConfig();
  try {
    const response = await axios({ url: `${apiUrl}/api/profiles/${profilePath}`, headers: { Authorization: token ? `Bearer ${token}` : null } });
    dispatch({
      type: 'finish read',
      profile: response.data as ProfileData,
      isSharedView,
    });
  } catch (error) {
    dispatch({ type: 'fail read', error, isSharedView });
  }
};

export const updateProfile = async (token: string, dispatch: ProfileDispatch, profile: ProfileData, updates: Partial<ProfileData>) => {
  dispatch({ type: 'start update', updates });
  // Profiles cannot be updated from shared view
  const isSharedView = false;
  const { apiUrl } = getConfig();
  try {
    const response = await axios(`${apiUrl}/api/profiles/${profile.slug}`, {
      method: 'PUT',
      data: updates,
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({
      type: 'finish update',
      profile: response.data as ProfileData,
      isSharedView,
    });
  } catch (error) {
    dispatch({ type: 'fail update', error, isSharedView });
  }
};
