import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AsyncStatus } from 'hooks/use-async';
import { PaginatedResponseEnvelope } from 'types/paginated-response-envelope.interface';
import { ProfileSummary } from 'types/profile.interface';

import { useFilteredProfilesPage } from './use-filtered-profiles-page';

type ProfileSummariesContextData = {
  lastTotal: number;
  status: AsyncStatus;
  profileSummariesResult: PaginatedResponseEnvelope<ProfileSummary> | null;
};

const ProfileSummariesContext = createContext<ProfileSummariesContextData | undefined>(undefined);

interface ProfileSummariesProviderProps {
  children: ReactNode;
}

export const ProfileSummariesProvider = ({ children }: ProfileSummariesProviderProps) => {
  const [lastTotal, setLastTotal] = useState<number>(0);

  // Load the actual profiles we'll show with user-defined filtering and pagination
  const [getFilteredProfilesResult, , getFilteredProfilesStatus] = useFilteredProfilesPage();

  useEffect(() => {
    if (getFilteredProfilesStatus === 'success') setLastTotal(getFilteredProfilesResult!.total || 0);
  }, [getFilteredProfilesStatus, getFilteredProfilesResult]);

  return (
    <ProfileSummariesContext.Provider
      value={{
        status: getFilteredProfilesStatus,
        profileSummariesResult: getFilteredProfilesResult,
        lastTotal,
      }}
    >
      {children}
    </ProfileSummariesContext.Provider>
  );
};

export const useProfileSummaries = () => {
  const context = useContext(ProfileSummariesContext);

  if (context === undefined) {
    throw new Error('useProfileSummaries must be used within a ProfileSummariesProvider');
  }

  return context;
};
