import { mdiSortAlphabeticalAscending } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Popover, Radio, RadioGroup, Select, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Permissions } from 'types/permissions.enum';
import { canUser } from 'utils/userRoles';
import { AuthUser } from 'types/user.interface';
import { useAuth0 } from '@auth0/auth0-react';
import { useFilters } from 'app/marketing/ProviderList/filtering/FiltersContext';

export const ProfileSortButton = () => {
  const {
    state: { pagination },
    updatePagination,
  } = useFilters();
  const [sortPopoverAnchorEl, setSortPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isSortPopoverOpen = Boolean(sortPopoverAnchorEl);
  const sortPopoverId = isSortPopoverOpen ? 'sort-popover' : undefined;

  const { user } = useAuth0<AuthUser>();
  const showPricingSortOption = canUser(Permissions.VIEW_PRICING, user);

  return (
    <>
      <Button
        endIcon={<Icon path={mdiSortAlphabeticalAscending} size={1} />}
        color={isSortPopoverOpen ? 'inherit' : 'secondary'}
        variant={isSortPopoverOpen ? 'contained' : 'outlined'}
        sx={{
          color: isSortPopoverOpen ? 'primary.dark' : 'secondary',
          backgroundColor: isSortPopoverOpen ? 'primary.light' : 'secondary',
          '&:hover': {
            backgroundColor: isSortPopoverOpen ? 'primary.light' : 'inherit',
          },
        }}
        aria-describedby={sortPopoverId}
        onClick={(event: MouseEvent<HTMLButtonElement>) => setSortPopoverAnchorEl(event.currentTarget)}
        data-intercom-target="sort button"
      >
        Sort
      </Button>
      <Popover
        id={sortPopoverId}
        open={isSortPopoverOpen}
        anchorEl={sortPopoverAnchorEl}
        onClose={() => setSortPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2} width="352px" sx={{ background: 'rgba(255, 255, 255, 1)', height: '100%' }}>
          <Typography variant="h6" fontWeight="600">
            Sorting settings
          </Typography>
          <FormControl sx={{ my: 3 }} fullWidth>
            <InputLabel id="sort-by-field-label">Sort by</InputLabel>
            <Select
              size="small"
              labelId="sort-by-field-label"
              value={pagination.sortBy}
              label="Sort by"
              onChange={(event) => updatePagination({ ...pagination, sortBy: event.target.value })}
            >
              <MenuItem value={'updatedAt'}>Last updated</MenuItem>
              {showPricingSortOption && <MenuItem value={'basePriceUsd'}>Program Price</MenuItem>}
              <MenuItem value={'name'}>School name</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel id="sort-order-group-label">Order</FormLabel>
            <RadioGroup
              row
              aria-labelledby="sort-order-group-label"
              defaultValue="ASC"
              name="sort-order-group"
              value={pagination.sortDirection}
              onChange={(event) => updatePagination({ ...pagination, sortDirection: event.target.value as 'ASC' | 'DESC' })}
            >
              <FormControlLabel value="ASC" control={<Radio />} label="Ascending" />
              <FormControlLabel value="DESC" control={<Radio />} label="Descending" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
};
