import { mdiFilter } from '@mdi/js';
import { Button, SwipeableDrawer } from '@mui/material';
import Icon from '@mdi/react';
import { FiltersSelectorPanel } from 'app/marketing/ProviderList/filtering/FiltersSelectorPanel';
import { useFilterDrawerState } from 'app/marketing/ProviderList/FilterDrawerStateContext';

interface ProfileFilterButtonProps {
  headerHeight: number;
}

export const ProfileFilterButton = ({ headerHeight }: ProfileFilterButtonProps) => {
  const { isOpen, setIsOpen } = useFilterDrawerState();

  const filterDrawerWidth = 640;

  const toggleFilterDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        endIcon={<Icon path={mdiFilter} size={1} />}
        color={isOpen ? 'inherit' : 'secondary'}
        variant={isOpen ? 'contained' : 'outlined'}
        sx={{
          color: isOpen ? 'primary.dark' : 'secondary',
          backgroundColor: isOpen ? 'primary.light' : 'secondary',
          '&:hover': {
            backgroundColor: isOpen ? 'primary.light' : 'inherit',
          },
        }}
        onClick={toggleFilterDrawer}
        data-intercom-target="filters button"
      >
        Filters
      </Button>
      <SwipeableDrawer
        disableEnforceFocus
        anchor="right"
        open={isOpen}
        onClose={toggleFilterDrawer}
        onOpen={toggleFilterDrawer}
        keepMounted
        sx={{
          marginLeft: 'auto',
          width: filterDrawerWidth,
          top: headerHeight,
          '& .MuiBackdrop-root': {
            display: 'none',
          },
          '& .MuiDrawer-paper': {
            borderRadius: 0,
            width: filterDrawerWidth,
            position: 'absolute',
          },
        }}
        disableSwipeToOpen={typeof navigator !== 'undefined' && /iPad|iPhone|iPod|Windows/.test(navigator.userAgent)}
      >
        <FiltersSelectorPanel onClose={() => setIsOpen(false)} />
      </SwipeableDrawer>
    </>
  );
};
