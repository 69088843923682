import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import { Avatar, Box, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { IFile } from 'types/file.interface';

export interface PhotoPreviewAvatarProps {
  photo: IFile;
}

export const PhotoPreviewAvatar = ({ photo }: PhotoPreviewAvatarProps) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const popoverAnchorEl = useRef(null);

  const popoverId = `photo-preview-${photo.id}`;

  return (
    <>
      <span ref={popoverAnchorEl} aria-owns={popoverId} aria-haspopup="true" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
          <PhotoOutlinedIcon sx={{ color: 'primary.dark' }} />
        </Avatar>
      </span>
      <Popover
        id={popoverId}
        open={isHovered}
        anchorEl={popoverAnchorEl.current}
        sx={{ pointerEvents: 'none' }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Box p={2} sx={{ p: 1, pointerEvents: 'auto' }}>
          <img src={photo.fileUrl} alt="An upload preview" style={{ maxHeight: '50vw', maxWidth: '50vw' }} />
        </Box>
      </Popover>
    </>
  );
};
