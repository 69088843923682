import { ModeEditOutlined } from '@mui/icons-material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import LocalMoviesOutlinedIcon from '@mui/icons-material/LocalMoviesOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useAsync } from 'hooks/use-async';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';
import { fileSize } from 'utils';

import { AddVideosModal } from './AddVideosModal';
import { DeleteButton } from './DeleteButton';
import { DownloadButton } from './DownloadButton';
import { EditImageModal } from './EditImageModal';
import { PhotoPreviewAvatar } from './PhotoPreviewAvatar';
import { UploadPhotosModal } from './UploadPhotosModal';
import { VideoUrlParser } from './video-url-parser';

export interface GallerySectionProps {
  profile: ProfileData;
}

export const GallerySection = ({ profile }: GallerySectionProps) => {
  const { profileSlug } = useParams();
  const profileUrl = `/profiles/${profileSlug}`;
  const [saveResult, saveStatus, saveProfile] = useAsync<ProfileData>(profileUrl, 'PUT', false);

  // Should the videoUrls come down from the profile prop or be fetched by the gallery in a self-contained manner?
  // Currently videoUrls are passed down synchronously via props, and the files load in afterwards async.
  const [videoUrls, setVideoUrls] = useState<string[]>(profile.videoUrls);
  const [editPhoto, setEditPhoto] = useState<IFile | null>(null);

  const fileRole = FileRoles.FILE_ROLE_SCHOOL_PHOTOS;
  const fetchFilesUrl = `/profiles/${profileSlug}/files?role=${fileRole}`;
  const [files, getFilesStatus, refetchFiles] = useAsync<IFile[]>(fetchFilesUrl);

  const [photos, setPhotos] = useState<IFile[]>([]);

  // Preserve the lists of photos and videos between re-fetches triggered by adding or removing files, and keep them updated
  useEffect(() => {
    if (getFilesStatus === 'success') setPhotos(files);
  }, [getFilesStatus, files]);
  useEffect(() => {
    if (saveStatus === 'success') setVideoUrls(saveResult.videoUrls);
  }, [saveStatus, saveResult]);

  const [isAddVideoModalOpen, setIsAddVideoModalOpen] = useState<boolean>(false);
  const closeAddVideoModal = (newVideoUrls: string[]) => {
    setIsAddVideoModalOpen(false);
    if (newVideoUrls.length === 0) return;
    // Should we transform these on read rather than write to reduce dissonance when users see a different URL than they entered?
    const transformedNewVideoUrls = newVideoUrls.map((videoUrl: string) => VideoUrlParser.transform(videoUrl));
    saveProfile({ videoUrls: videoUrls.concat(transformedNewVideoUrls) });
    setVideoUrls((p) => p.concat(transformedNewVideoUrls));
  };
  const removeVideo = (index: number) => () => {
    const newVideoUrls = [...videoUrls.slice(0, index), ...videoUrls.slice(index + 1)];
    saveProfile({ videoUrls: newVideoUrls });
    setVideoUrls(newVideoUrls);
  };

  const [isEditImageModalOpen, setIsPhotoEditModalOpen] = useState<boolean>(false);

  const closeEditImageModal = () => {
    setIsPhotoEditModalOpen(false);
    setEditPhoto(null);
    // Give time for any in-flight requests to finish
    setTimeout(() => refetchFiles(), 1000);
  };

  const videoSource = (url: string): string => (url.toLowerCase().includes('vimeo') ? 'Vimeo' : 'Youtube');

  const [isPhotoUploadModalOpen, setIsPhotoUploadModalOpen] = useState<boolean>(false);
  const closePhotoUploadModal = (newPhotos: IFile[]) => {
    setIsPhotoUploadModalOpen(false);
    if (newPhotos.length === 0) return;
    setPhotos((p) => p.concat(newPhotos));
    // Give time for any in-flight requests to finish
    setTimeout(() => refetchFiles(), 1000);
  };
  const removePhoto = (photo: IFile) => () => {
    const index = photos.findIndex((p) => p === photo);
    setPhotos((p) => [...p.slice(0, index), ...p.slice(index + 1)]);
  };

  return (
    <section id="gallery-section">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        Gallery
      </Typography>
      <TableContainer component={Card}>
        <Stack direction="row" p={2} spacing={1}>
          <Typography fontWeight="500" sx={{ alignSelf: 'center' }}>
            Files uploaded
          </Typography>
          <Box flex={1} />
          <Tooltip title="Refresh list">
            <span>
              <IconButton aria-label="refresh list" disabled={getFilesStatus === 'pending'} onClick={() => refetchFiles()} sx={{ mr: 1 }}>
                {getFilesStatus === 'pending' ? <CircularProgress size="24px" /> : <RefreshOutlinedIcon />}
              </IconButton>
            </span>
          </Tooltip>
          <Button variant="outlined" color="secondary" onClick={() => setIsAddVideoModalOpen(true)} endIcon={<VideocamOutlinedIcon />}>
            Add video links
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setIsPhotoUploadModalOpen(true)} endIcon={<CloudUploadOutlinedIcon />}>
            Upload images
          </Button>
        </Stack>
        <Divider />
        <Table sx={{ minWidth: 650 }} aria-label="gallery table">
          <TableHead>
            <TableRow sx={{ backgroundColor: 'background.default' }}>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  File name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  File size
                </Typography>
              </TableCell>
              <TableCell>
                <Typography color="secondary" fontWeight="500">
                  Last updated
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography color="secondary" fontWeight="500">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(videoUrls?.length || 0) + (photos?.length || 0) === 0 && (
              <TableRow>
                <TableCell>
                  <Stack direction="row" alignItems="center">
                    <Button variant="text">Upload your first photo</Button>
                    <Typography>or</Typography>
                    <Button variant="text">Add a video</Button>
                  </Stack>
                </TableCell>
              </TableRow>
            )}
            {videoUrls.map((url, index) => (
              <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Stack direction="row">
                    <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
                      <LocalMoviesOutlinedIcon sx={{ color: 'primary.dark' }} />
                    </Avatar>
                    <Stack direction="column">
                      <Typography fontWeight="500">{url}</Typography>
                      <Typography color="secondary">{videoSource(url)} video</Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell />
                <TableCell />
                <TableCell align="right">
                  <Stack direction="row" justifyContent="flex-end">
                    <Tooltip title="Open in new tab">
                      <span>
                        <IconButton aria-label="open in new tab" onClick={() => window.open(url, '_blank')}>
                          <OpenInNewIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <span>
                        <IconButton aria-label="delete" disabled={saveStatus === 'pending'} onClick={removeVideo(index)}>
                          {saveStatus === 'pending' ? <CircularProgress size="24px" /> : <DeleteOutlinedIcon />}
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
            {photos.map((photo) => (
              <TableRow key={photo.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Stack direction="row">
                    <PhotoPreviewAvatar photo={photo} />
                    <Stack direction="column">
                      <Typography fontWeight="500">{photo.filename}</Typography>
                      <Typography color="secondary">{photo.filename.split('.').pop()?.toUpperCase()} file</Typography>
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography>{photo.size ? fileSize(photo.size) : '-'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{moment(photo.createdAt).format('MMM D, yyyy')}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Stack direction="row" justifyContent="flex-end">
                    <DownloadButton file={photo} />
                    <Tooltip title="Edit">
                      <span>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            setEditPhoto(photo);
                            setIsPhotoEditModalOpen(true);
                          }}
                        >
                          <ModeEditOutlined />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <DeleteButton file={photo} onDelete={removePhoto(photo)} />
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditImageModal isOpen={isEditImageModalOpen} onClose={closeEditImageModal} photo={editPhoto} />
      <AddVideosModal isOpen={isAddVideoModalOpen} onClose={closeAddVideoModal} />
      <UploadPhotosModal isOpen={isPhotoUploadModalOpen} onClose={closePhotoUploadModal} />
    </section>
  );
};
