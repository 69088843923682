import { FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';

export const markerVisibilityZoom = 10;

export const addMapSource = (map: mapboxgl.Map, profileCoords: FeatureCollection<Geometry, GeoJsonProperties>) => {
  map.addSource('points', {
    type: 'geojson',
    data: profileCoords,
    cluster: true,
    clusterProperties: {},
    clusterMaxZoom: markerVisibilityZoom, // Max zoom to cluster points on
    clusterRadius: 100, // Radius of each cluster when clustering points (defaults to 50)
  });
};
