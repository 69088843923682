import { Paper, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { ProfileData } from 'types/profile.interface';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { FeatureViewData } from './FeatureViewData.type';
import { FeaturesListView } from './FeaturesListView';
import { FeaturesTabbedView } from './FeaturesTabbedView';

export const FeaturesSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const isPdfFormat = useIsPdfFormat();

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  // Note that a category key may not be defined if there's no features of that type
  const featureViewData = useMemo(
    () =>
      profile.profileFeatures.reduce((acc, curr) => {
        if (!acc[curr.feature.category]) acc[curr.feature.category] = [];
        acc[curr.feature.category]!.push(curr.feature);
        return acc;
      }, {} as FeatureViewData),
    [profile.profileFeatures],
  );

  return (
    <section>
      <Paper sx={{ mb: '10px', p: '20px' }}>
        <Typography variant="h5" fontSize="24px" fontWeight={700} color={titleColor}>
          School Features
        </Typography>
        {isPdfFormat && <FeaturesListView featureViewData={featureViewData} />}
        {!isPdfFormat && <FeaturesTabbedView featureViewData={featureViewData} />}
      </Paper>
    </section>
  );
};
