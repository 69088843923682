import CloseIcon from '@mui/icons-material/Close';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, CircularProgress, FormControl, FormLabel, IconButton, Modal, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { Adornment, AdornmentTextField } from 'components/AdornmentTextField';
import { CharactersRemaining } from 'components/CharactersRemaining';
import { ImageWithSkeleton } from 'components/ImageWithSkeleton';
import { useAsync } from 'hooks/use-async';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';

interface EditImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  photo: IFile | null;
}

const FILENAME_CHAR_LIMIT = 100;

export const EditImageModal = ({ isOpen, onClose, photo }: EditImageModalProps) => {
  const { profileSlug } = useParams();
  const [fileExtension, setFileExtension] = useState(photo?.filename.split('.').pop());
  const [filename, setFilename] = useState(photo?.filename.replace(/\.[^/.]+$/, ''));
  const editPhotoUrl = `/profiles/${profileSlug}/files/${photo?.id}`;
  const [, editPhotoStatus, editPhoto] = useAsync(editPhotoUrl, 'PUT', false);
  const [error, setError] = useState<string | null>(null);
  const theme = useTheme();

  useEffect(() => {
    setFilename(photo?.filename.replace(/\.[^/.]+$/, ''));
    setFileExtension(photo?.filename.split('.').pop());
  }, [photo]);

  useEffect(() => {
    let containsValidChars = false;
    if (typeof filename === 'string') {
      // Only allow alphanumeric, underscore, hyphen or space characters
      containsValidChars = /^[a-zA-Z0-9_\- ]+$/.test(filename);
    }
    if (containsValidChars) {
      setError(null);
    } else {
      setError('Name can only contain alphanumeric, underscores, hyphens and spaces.');
      return;
    }
  }, [filename]);

  const onDone = async () => {
    await editPhoto({ filename: `${filename?.trim()}.${fileExtension}` });
    onClose();
  };

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)' }} />}
      <Modal open={isOpen} aria-labelledby="edit-image-modal-title" aria-describedby="edit-image-modal-description" onClose={() => onClose()}>
        <Box sx={theme.mixins.modalStyle}>
          <Stack flex={1}>
            <Stack direction="row" alignItems="center">
              <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
                <PhotoOutlinedIcon sx={{ color: 'primary.dark' }} />
              </Avatar>
              <Box flex={1} />
              <Tooltip title="Discard">
                <span>
                  <IconButton aria-label="discard" onClick={() => onClose()}>
                    {editPhotoStatus === 'pending' ? <CircularProgress size="24px" /> : <CloseIcon color="secondary" />}
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Typography id="edit-image-modal-title" variant="h6" component="h2" my={2}>
              Edit image name
            </Typography>
            <Box mb={2} height={320}>
              <ImageWithSkeleton src={photo?.fileUrl} alt="banner" style={{ objectFit: 'cover', height: 'inherit' }} width="100%" height="100%" />
            </Box>
            <Box minHeight={0} flex={1}>
              <FormControl fullWidth>
                <FormLabel id="edit-image-name-label">Name</FormLabel>
                <AdornmentTextField
                  required
                  fullWidth
                  aria-labelledby="edit-image-name-label"
                  value={filename}
                  size="small"
                  onChange={(e) => setFilename(e.target.value)}
                  InputProps={{
                    endAdornment: <Adornment maxRange={`.${fileExtension}`} showSlash={false} />,
                  }}
                  inputProps={{
                    maxLength: FILENAME_CHAR_LIMIT,
                  }}
                  error={Boolean(error)}
                />
                <CharactersRemaining limit={FILENAME_CHAR_LIMIT} value={filename || ''} />
                {error ? <Typography color="error">{error}</Typography> : <br />}
              </FormControl>
            </Box>
            <FormControl fullWidth>
              <Stack direction="row" spacing={1}>
                <Button fullWidth variant="outlined" onClick={() => onClose()} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  loading={editPhotoStatus === 'pending'}
                  disabled={Boolean(error)}
                  onClick={onDone}
                  color="primary"
                  endIcon={<TaskAltIcon />}
                >
                  Save
                </LoadingButton>
              </Stack>
            </FormControl>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
