import { Box, Button } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useState } from 'react';
import { canUserEditMarketingProfile } from 'utils/userRoles';
import { useProfile } from 'app/marketing/profiles/ProfileContext';
import { ProfileData } from 'types/profile.interface';
import { useAuth0 } from '@auth0/auth0-react';
import { AuthUser } from 'types/user.interface';

import { EnquireDialog } from './EnquireDialog';

export const InquireButton = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData } };

  const { user } = useAuth0<AuthUser>();

  const [openEnquireDialog, setOpenEnquireDialog] = useState(false);

  return (
    <Box bgcolor="white" borderRadius="6px">
      <Button
        startIcon={<MailOutlineIcon />}
        color="inherit"
        variant="outlined"
        // TODO: Find out if we can improve this UX by disabling or hiding the button when clicking it isn't allowed
        onClick={() => (canUserEditMarketingProfile(user) ? null : setOpenEnquireDialog(true))}
      >
        Inquire
      </Button>
      {openEnquireDialog && profile.slug && <EnquireDialog open onClose={() => setOpenEnquireDialog(false)} />}
    </Box>
  );
};
