import { Typography } from '@mui/material';

interface CharactersRemainingProps {
  limit: number;
  value: string;
}

export const CharactersRemaining = ({ limit, value }: CharactersRemainingProps) => {
  const length = value?.length || 0;
  const charactersLeft = limit - length;

  let color = 'secondary';
  let text = `${limit - length} characters remaining`;

  if (charactersLeft < 10 && charactersLeft >= 0) {
    color = 'warning.dark';
  }
  if (charactersLeft < 0) {
    color = 'error';
    text = `${length - limit} characters over the limit`;
  }

  return (
    <Typography variant="caption" textAlign="right" color={color} sx={{ display: 'inline-block', width: '100%' }}>
      {text}
    </Typography>
  );
};
