import { CloudUploadOutlined } from '@mui/icons-material';
import { Alert, AlertTitle, Avatar, Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IFile } from 'types/file.interface';

type FileUploadProps = {
  maxFiles: number;
  disabled?: boolean;
  mimeTypes?: string[];
  onUploadStart: (newFiles: File[]) => void;
};

export const FileUploadDropzone = ({ maxFiles, disabled = false, mimeTypes, onUploadStart }: FileUploadProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length === 0) return;

      onUploadStart(acceptedFiles);
    },
    [onUploadStart],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    onDrop,
    maxFiles,
    disabled,
    accept: mimeTypes || '',
    maxSize: 26214400,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    const f = file as IFile & File;
    return (
      <span key={f.path}>
        {errors.map((e) => (
          <Alert severity="error" key={e.code}>
            <AlertTitle>{e.message}</AlertTitle>
            {f.path} ({f.size} bytes)
          </Alert>
        ))}
      </span>
    );
  });

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          border: `solid 1px ${grey[300]}`,
          p: 4,
          mb: 1,
          bgcolor: grey[50],
          color: grey[500],
          cursor: 'pointer',
        }}
      >
        <input {...getInputProps()} />
        <Avatar sx={{ backgroundColor: 'secondary.light', mb: 1 }}>
          <CloudUploadOutlined sx={{ color: 'secondary.dark' }} />
        </Avatar>
        {!isDragActive && (
          <>
            <Typography color="primary" fontWeight="500" paragraph={false}>
              Click to upload
            </Typography>
            <Typography color="secondary" paragraph={false}>
              or drag and drop
            </Typography>
            <Typography variant="body2" color="secondary">
              {/* actually 25MB */}
              (max filesize 20MB)
            </Typography>
          </>
        )}
        {isDragReject && <Typography color="error">This file type is not allowed</Typography>}
      </Box>
      {fileRejectionItems}
    </>
  );
};
