import { Box, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import { ReactNode } from 'react';

import { HighlightAvatar, schoolHighlightIcons } from './HighlightAvatar';

interface IHighlightAvatarSelectorProps {
  value?: string | null;
  onChange?: (event: SelectChangeEvent, child: ReactNode) => void;
  labelId?: string;
  color?: string;
  sx?: SxProps<Theme>;
}

export const HighlightAvatarSelector = ({ value, onChange, color, sx, labelId }: IHighlightAvatarSelectorProps) => {
  return (
    <Select sx={sx} onChange={onChange} value={value || 'highlight'} placeholder="Category" size="small" aria-labelledby={labelId}>
      {Array.from(schoolHighlightIcons.keys()).map((highlight, index) => (
        <MenuItem key={index} value={highlight}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <HighlightAvatar color={color || 'inherit'} height={40} width={40} key={highlight} icon={highlight} />
            <Box sx={{ marginLeft: '10px' }}>{schoolHighlightIcons.get(highlight)!.description}</Box>
          </Box>
        </MenuItem>
      ))}
    </Select>
  );
};
