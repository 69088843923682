import { useAuth0 } from '@auth0/auth0-react';
import { Box, Stack } from '@mui/material';
import { branding } from 'app/branding';
import { Header } from 'components/Header';
import { Loader } from 'components/Loader';
import { useIntercom } from 'hooks/analytics/use-intercom';
import { useLogRocket } from 'hooks/analytics/use-logrocket';
import { usePageTracking } from 'hooks/analytics/use-page-tracking';
import { useSentry } from 'hooks/analytics/use-sentry';
import { useIsIframed } from 'hooks/use-is-iframed';
import { Outlet, useMatch } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { theme } from 'app/theme';
import { useIsPdfFormat } from 'hooks/use-is-pdf-format';

import { PrimaryMarketModal } from './PrimaryMarketModal';

export const Layout = () => {
  usePageTracking();
  useSentry();
  useLogRocket();
  useIntercom();

  const isIframed = useIsIframed();
  const isPdfFormat = useIsPdfFormat();
  const { user, isLoading: isAuthLoading } = useAuth0<AuthUser>();

  const showPrimaryMarketModal = Boolean(user?.userData.roles.includes('agent') && !user?.userData.primaryMarketCountryId);

  // TODO: Not do route-specific CSS here
  const isMarketplace = useMatch('/marketing');
  const isProfileEdit = useMatch('/marketing/profiles/:profileSlug/edit');
  const isProfileEditTab = useMatch('/marketing/profiles/:profileSlug/edit/:editTab');
  const appBarHeight = branding.headerHeight;
  const wrapperHeight = isPdfFormat ? 'auto' : '100vh';
  let outletHeight: number = 0;
  if (isMarketplace) {
    outletHeight = isIframed ? 0 : appBarHeight;
  } else if (isProfileEdit || isProfileEditTab) {
    outletHeight = appBarHeight;
  }

  // While it makes more sense to render it here, <Footer> must be rendered by each outlet for now instead.
  // This is to accommodate displaying the filter drawer in ProviderList below both the main header here
  // and the search bar element also inside ProviderList using a dynamic height here since it is dependent
  // on whether it is iframed or not.

  // Also note that PDF view does not render the footer and it's important for rendering pages past the first
  // into print view that the wrapper does not truncate into hidden overflow after 100vh.

  return (
    <Stack direction="column" minHeight={wrapperHeight} height={wrapperHeight} overflow={isMarketplace ? 'hidden' : 'auto'}>
      {!isIframed && <Header />}
      <Box
        display="table-row-group"
        flex={1}
        mt={isIframed ? 0 : appBarHeight}
        height={outletHeight ? `calc(100% - ${theme.spacing(outletHeight)})` : 'inherit'}
      >
        {isAuthLoading && !isIframed ? <Loader /> : <Outlet />}
        {showPrimaryMarketModal && <PrimaryMarketModal />}
      </Box>
    </Stack>
  );
};
