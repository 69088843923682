import { Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Stack, Typography } from '@mui/material';
import { Adornment, AdornmentTextField } from 'components/AdornmentTextField';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { stripNonNumerical } from 'utils/stripNonNumerical';
import { EditableProfileFields } from 'app/marketing/profiles/Edit/get-editable-profile-fields';

interface AcademicDetailsProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const AcademicDetailsSection = ({ errors, control }: AcademicDetailsProps) => {
  return (
    <section id="academic-details-section">
      <Typography variant="body1" sx={{ py: 2, fontWeight: 500 }}>
        Academic details
      </Typography>
      <Stack divider={<Divider flexItem />}>
        <Controller
          name="averageSatScore"
          control={control}
          rules={{
            min: { value: 400, message: 'Average SAT score score must be higher than 400' },
            max: { value: 1600, message: 'Average SAT score score cannot be higher than 1600' },
          }}
          render={({ field: { value, onChange } }) => (
            <FormControl size="small">
              <FormLabel id="averageSatScore-score-input-label" sx={{ mb: 1 }}>
                Average SAT score
              </FormLabel>
              <AdornmentTextField
                aria-labelledby="averageSatScore-score-input-label"
                id="averageSatScore-score-input"
                value={value ?? ''}
                onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                size="small"
                error={Boolean(errors.averageSatScore)}
                InputProps={{
                  endAdornment: <Adornment maxRange="1600" />,
                }}
              />
              {errors.averageSatScore ? <Typography color="error">{errors.averageSatScore.message}</Typography> : <br />}
            </FormControl>
          )}
        />
        <Controller
          name="englishLangSupport"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth size="small" sx={{ my: 1 }}>
              <FormControlLabel
                control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                label="English as second / other spoken language support offered"
              />
            </FormControl>
          )}
        />
        <Controller
          name="graduateStudentAccepted"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth size="small" sx={{ my: 1 }}>
              <FormControlLabel control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />} label="Graduate students accepted" />
            </FormControl>
          )}
        />
        <Controller
          name="usOneYearDiploma"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth size="small" sx={{ my: 1 }}>
              <FormControlLabel control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />} label="US one year diploma offered" />
            </FormControl>
          )}
        />
      </Stack>
    </section>
  );
};
