import { Card, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { FilterHeader } from 'app/marketing/ProviderList/filtering/FilterHeader';

export type DayOrBoarding = ProfileData['dayOrBoarding'];

interface DayOrBoardingFilterProps {
  value: DayOrBoarding | undefined;
  onChange: (newValue: DayOrBoarding) => void;
  onDiscard: () => void;
}

export const DayOrBoardingFilter = ({ value, onChange, onDiscard }: DayOrBoardingFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <FilterHeader title="Day or Boarding" onDiscard={onDiscard} />
      <FormControl fullWidth>
        <InputLabel id="day-or-boarding-filter-select-label">Select school type</InputLabel>
        <Select
          labelId="day-or-boarding-filter-select-label"
          id="day-or-boarding-filter-select"
          label="Select school type"
          value={value}
          onChange={(event) => onChange(event.target.value as DayOrBoarding)}
        >
          <MenuItem value="day">Day school only</MenuItem>
          <MenuItem value="boarding">Boarding school only</MenuItem>
          <MenuItem value="dayAndBoarding">Day &amp; boarding school</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
