import { useWindowSize } from 'hooks/use-window-size';
import { useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { IFile } from 'types/file.interface';
import { getConfig } from 'utils/config';
import { getOptimisedImageUrl, ImageEditOptions } from 'utils/getOptimisedImageUrl';

interface ImageVideoCarouselProps {
  videoUrls: string[];
  imageFiles: IFile[];
}
interface ImageItem {
  original: string;
  thumbnail: string;
}
interface VideoItem {
  original: string;
  thumbnail: string;
  embedUrl: string;
}

export const ImageVideoCarousel = ({ videoUrls, imageFiles }: ImageVideoCarouselProps) => {
  const [windowWidth, windowHeight] = useWindowSize();
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const { marketplaceUrl } = getConfig();

  const renderVideo = (embedUrl: string) => {
    const vId = embedUrl.substring(embedUrl.lastIndexOf('/') + 1);
    return (
      <div style={{ margin: isFullScreen ? 'inherit' : '25px 0' }}>
        {(embedUrl.includes('youtube') && (
          <iframe
            key={vId}
            style={{ border: '0px', display: 'block', margin: '0 auto' }}
            width={isFullScreen ? windowWidth : 640}
            height={isFullScreen ? windowHeight : 360}
            src={embedUrl}
            title={`YouTube video player ${vId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )) ||
          (embedUrl.includes('vimeo') && (
            <iframe
              key={vId}
              style={{ border: '0px', display: 'block', margin: '0 auto' }}
              title={`Vimeo embed player ${vId}`}
              src={embedUrl}
              width={isFullScreen ? windowWidth : 640}
              height={isFullScreen ? windowHeight : 360}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          )) || <></>}
      </div>
    );
  };
  const imageEditOptions: ImageEditOptions = {
    resize: { width: 1200, fit: 'cover' },
  };

  const items: (ImageItem | VideoItem)[] = [
    ...videoUrls.map((embedUrl) => {
      return {
        original: `${marketplaceUrl}/video-placeholder.png`,
        thumbnail: `${marketplaceUrl}/video-placeholder.png`,
        renderItem: () => renderVideo(embedUrl),
      };
    }),
    ...imageFiles.map((f) => {
      return { original: getOptimisedImageUrl(f.fileUrl || f.path, imageEditOptions), thumbnail: getOptimisedImageUrl(f.fileUrl || f.path, imageEditOptions) };
    }),
  ];

  return <ImageGallery items={items} onScreenChange={(fullscreen: boolean) => setIsFullScreen(fullscreen)}></ImageGallery>;
};
