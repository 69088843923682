import { useState, useEffect } from 'react';

import { AsyncStatus } from './use-async';

export type UsePromiseState<T> = [T | null, Error | unknown | null, AsyncStatus];

export function usePromiseState<T>(promiseOrFunction: Promise<T> | Function, defaultValue: T): UsePromiseState<T> {
  const [value, setValue] = useState<T | null>(defaultValue);
  const [error, setError] = useState<unknown | null>(null);
  const [status, setStatus] = useState<AsyncStatus>('pending');

  useEffect(() => {
    const promise = typeof promiseOrFunction === 'function' ? promiseOrFunction() : promiseOrFunction;
    if (!promise) return;

    let isSubscribed = true;

    promise
      .then((newValue: T) => {
        if (isSubscribed) {
          setValue(newValue);
          setError(null);
          setStatus('success');
        }
      })
      .catch((error: unknown) => {
        if (isSubscribed) {
          setValue(null);
          setError(error);
          setStatus('error');
        }
      });

    return () => {
      isSubscribed = false;
    };
  }, [promiseOrFunction, defaultValue]);

  return [value, error, status];
}
