import { useIsIframed } from 'hooks/use-is-iframed';
import { ReactElement } from 'react';

import { RequireAuthForRoute } from './RequireAuthForRoute';

interface RequireAuthUnlessIframedForRouteProps {
  children: ReactElement;
}

export const RequireAuthUnlessIframedForRoute = ({ children }: RequireAuthUnlessIframedForRouteProps): ReactElement => {
  const isIframed = useIsIframed();

  if (isIframed) return children;

  return <RequireAuthForRoute>{children}</RequireAuthForRoute>;
};
