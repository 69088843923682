import mapboxgl from 'mapbox-gl';

export const mapSetup = (mapContainerEl: HTMLDivElement | undefined, setMap: (newMap: mapboxgl.Map | null) => void) => {
  if (!mapContainerEl) return;
  const map = new mapboxgl.Map({
    container: mapContainerEl!,
    style: 'mapbox://styles/mapbox/streets-v12',
    dragRotate: false,
    pitchWithRotate: false,
    touchZoomRotate: false,
    // TODO: Set an initial zoom or rezoom on bounding box of currently loaded profileCoords?
  });
  map.setProjection('mercator');
  map.on('load', () => setMap(map));
  map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'top-right');
  map.addControl(new mapboxgl.ScaleControl());
};
